/* search_resultsDetail.jsx */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Layout from '../../components/site/layout';
import sampleimg from '../../assets/img/thumb_sample.png'; // 썸네일샘플 이미지
import { Link } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import axios from 'axios';

import config from '../../config';
const apiUrl = config.apiUrl;

function SearchResultsDetail() {

    // 문의하기 모달
    const [visible, setVisible] = useState(false);

    // 문의하기 모달 열기
    const openContactModal = (rowData) => {
        setVisible(true);
    };

    const { id } = useParams(); // URL 매개변수에서 ID 가져오기

    const [serviceData, setServiceData] = useState({
        fileurl: "",
        title: "",
        description: "",
        intro: "",
    });

    // 서비스 콘텐츠 정보 가져오기
    const getServiceInfo = async () => {

        try {
            const response = await axios.get(apiUrl+'/service/'+id);
            var res = response.data;
            //console.log(res);
            if(res.success){
                var resData = res.data;
                setServiceData({
                    ...serviceData,
                    fileurl: resData.fileurl,
                    title: resData.title,
                    description: resData.description,
                    intro: resData.intro
                });
            }
        
        } catch (error) {
           console.error('Error:', error);
        }
    };

    useEffect(() => {
        
        getServiceInfo();
        
    }, [id]);


    return (
        <div>
            <Layout>
                {/* 컨텐츠영역 */}
                <div className="content searchDetail pt50">
                    <div className="container">
                        <div className="topBox">
                            <Link to={`/search_results?keyword=${serviceData.title}`} aria-label={serviceData.title}>이전</Link>
                            <Button label="문의하기" className="contactbtn" onClick={() => openContactModal()}  severity="secondary" raised aria-label="문의하기 버튼"/>
                        </div>
                        <div className="ser_topbox">
                            <div className="thumbnail">
                                <img src={serviceData.fileurl} alt={serviceData.title} />
                            </div>
                            <div className="txtBox">
                                <h2>{serviceData.title}</h2>
                                <p>{serviceData.description}</p>
                            </div>
                        </div>

                        <div className="ser_bottombox" dangerouslySetInnerHTML={{ __html: serviceData.intro }}></div>
                    </div>
                </div>

                 {/* 문의하기 모달 */}
                <Dialog
                className='contact-modal'
                draggable={false}
                role="dialog"
                header="문의하기"
                visible={visible}
                style={{ width: '30vw' }}
                breakpoints={{ '1100px': '75vw', '960px': '80vw' }}
                onHide={() => setVisible(false)}
                focusOnShow={true} // 다이얼로그가 열렸을 때 포커스가 이동하도록 함
                modal={true} // 모달 다이얼로그로 설정하여 다이얼로그 외부를 클릭했을 때 닫히지 않도록 함
                aria-modal="true" // 스크린 리더가 모달 상태를 인식할 수 있도록 함
            >
                    <div className='contact-info'>
                        <p>
                            <span>문의 메일:</span> <a href="mailto:contact@heartyworks.com"
                                aria-label="문의 메일: contact@heartyworks.com">contact@heartyworks.com</a>
                        </p>
                        <p>
                            <span>전화:</span> <a href="tel:+82269251203" aria-label="전화번호: 02-6925-1203">02-6925-1203</a>
                        </p>
                    </div>

              
            </Dialog>
            </Layout>
        </div>
    );
}

export default SearchResultsDetail;