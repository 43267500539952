
/* search_results.jsx */
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Layout from '../../components/site/layout';

import sampleimg from '../../assets/img/thumb_sample.png'; // 썸네일샘플 이미지
import { Link } from 'react-router-dom';

import axios from 'axios';

import config from '../../config';
const apiUrl = config.apiUrl;

function SearchResults() {

    const location = useLocation();

    const [serviceData, setServiceData] = useState([]);

    const params = new URLSearchParams(location.search);
    var keyword = "";
    if(params.size > 0){
        keyword = params.get('keyword');
    }
    
    // 콘텐츠 리스트 가져오기
    const getContents = async () => {

        try {
            const response = await axios.get(apiUrl+'/service?searchtext='+keyword+'&sort=seq,desc');
            var res = response.data;
            //console.log(res);
            var dataArr = [];
            if(res.success){
                var resData = response.data.data;
                //console.log(resData);
                if(resData){
                    for(var i = 0; i < resData.length; i++){
                        var info = resData[i];
                        info.source = info.fileurl;
                        info.alt = info.title;
                        dataArr.push(info);
                    }
                }
            }

            setServiceData(dataArr);
        
        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
    
        getContents();
        
    }, []);

    useEffect(() => {
    
        getContents();
        
    }, [keyword]);

    return (
        <div>
            <Layout>
                {/* 컨텐츠영역 */}
                <div className="content">
                    <div className="searchpgBox">
                        {/* 리스트 */}
                        <section className="searchpg">
                            <div className="container">
                                {keyword ?
                                    <h2 className="listtitle">"{keyword}"에 대한 검색 결과</h2>
                                    :
                                    <h2 className="listtitle">전체 서비스 컨텐츠</h2>
                                }

                                <div className="listBox">
                                    {serviceData.map((item, index) => (
                                        <Link to={`/search_resultsDetail/${item.serviceid}`} aria-label={item.title} key={index}>
                                            <div className="thumnail">
                                                <img src={item.source} alt={item.title} />
                                            </div>
                                            <div className="listconts">
                                                <p className="tit">{item.title}</p>
                                                <p className="txt">{item.description}</p>
                                            </div>
                                        </Link>
                                    ))}
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </Layout>
        </div>
    );
}

export default SearchResults;