// lms/Layout 컴포넌트

import React, { useState, useRef} from 'react';
import Header from './header';
import Footer from './footer';
import '../../../assets/css/lms.css';

const Layout = ({ children, title, pagename }) => {
    const [hasSubMenu, setHasSubMenu] = useState(false);

    return (
        <div className="lms-layout">
            <Header title={title} pagename={pagename} setHasSubMenu={setHasSubMenu} />
            <main 
                className={`contentBox ${hasSubMenu ? 'no-submenu' : ''}`} 
                tabIndex="-1" // 포커스 가능하게 하기 위해 tabIndex 설정
            >
                {children} 
            </main>
            <Footer  />
        </div>
    );
};

export default Layout;
