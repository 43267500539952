/* /lms/learner/ClassResi.jsx */
import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { ConfirmPopup } from 'primereact/confirmpopup';
import { Paginator } from 'primereact/paginator';
import { classNames } from 'primereact/utils';
import { Dialog } from 'primereact/dialog';
import { Link } from 'react-router-dom';
//import Dropdown from '../../../components/dropdownValue'; // 커스텀 접근성 드롭다운 (value값 포함)
//import sampleimg1 from '../../../assets/img/t_sample.png'; // 강사선택 > 강사프로필 샘플 이미지
//import sampleimg2 from '../../../assets/img/t_sample2.png'; // 강사프로필 샘플 이미지
import Layout from '../../../components/lms/layout/layout';
import Calendar from '../../../components/lms/calendar'; 
import PDFViewer from '../../../components/lms/modal/PDFViewer'; 

import axios from 'axios';

import config from '../../../config';
const apiUrl = config.apiUrl;

var dataList = [];

// 타이틀
export function useTitle(initialTitle) {
    const [title, setTitle] = useState(initialTitle);
    const updateTitle = () => {
        const htmlTitle = document.querySelector("title");
        htmlTitle.innerText = title;
    };

    useEffect(updateTitle, [title]);
    return setTitle;
};


const ClassResiSchedule = () => {

    useTitle("수업 예약");

    const accessToken = useSelector(state => state.auth.accessToken);
    const navigate = useNavigate();

    const toast = useRef(null); //결과 메세지 창

    //브러드크럼
    const items = [ { label: '수업예약' }, { label: '수업 예약하기', className: 'nowpage' }, ];
    const home = { icon: 'pi pi-home', url: '/learner/dashboard' }

    // 수업 정보
    const [myClassInfo, setMyClassInfo] = useState({
        availableroundno: "",
        startdate: "",
        enddate: "",
    });

    // 소속 정보
    const [affiliationid, setAffiliationid] = useState('');

    // 강사 선택
    const [tutorlist, setTutorlist] = useState([]);
    const [tutorid, setTutorid] = useState('');
    const [tutorInfo, setTutorInfo] = useState({
        profile: "",
        intro: "",
    });
    const onTutorChange = (tutor) => {
        setTutorid(tutor.tutorid);
        setTutorInfo({
            ...tutorInfo,
            profile: tutor.fileurl,
            intro: tutor.intro,
        });
    };

    const [selectedClass, setSelectedClass] = useState({});
    const [totalCnt, setTotalCnt] = useState(0);
    const [tableData, setTableData] = useState([...dataList]);
    const [row, setRowData] = useState(5);

    var today = new Date();
    var nowYear = today.getFullYear();
    var nowMonth = (today.getMonth() + 1).toString().padStart(2, '0');

    //달력 데이터
    const calendarRef = useRef(null); 
    const [scheduleData, setScheduleData] = useState([]);
    const [year, setYear] = useState(nowYear);
    const [month, setMonth] = useState(nowMonth);

    useEffect(() => {

        // 페이지 진입 시 장바구니 비우기
        deleteScheduleCart();

        getMyClass();
        
    }, []);

    // 강사 선택 시 수업 리스트 가져오기
    useEffect(() => {

        if(tutorid != ""){
            getClassSchedule();
        }

    }, [tutorid]);

    // 월 변경 시 수업 리스트 가져오기
    useEffect(() => {

        if(tutorid != "" && month != ""){
            getClassSchedule();
        }

    }, [month]);

    // 수강생 정보 가져오기
    const getTuteeInfo = async () => {

        try {
            const response = await axios.get(apiUrl+'/tutee/info/me', {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            if(res.success){
                var resData = response.data.data;
                //console.log(resData);
                if(resData){
                    setAffiliationid(resData.affiliationid);
                    
                    getTutors();
                    getSelectedList();
                }

            }
        
        } catch (error) {
           console.error('Error:', error);
        }
    };

    // 나의 수업 정보 가져오기
    const getMyClass = async () => {
        try {
            const response = await axios.get(apiUrl+'/tutee/myclass', {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            if(res.success){
                var resData = res.data;
                //console.log(resData);
                if(resData){
                    var availableroundno = resData.affiliation_availableno;
                    var startdate = resData.startdate.split("T")[0];
                    var enddate = resData.enddate.split("T")[0];

                    setMyClassInfo({
                        ...myClassInfo,
                        availableroundno: availableroundno,
                        startdate: startdate,
                        enddate: enddate,
                    })
                }
            }

        } catch (error) {
            console.error('Error:', error);
        }
    };

    // 강사 리스트 가져오기
    const getTutors = async () => {
        try {
            const response = await axios.get(apiUrl+`/tutor?searchtext=&status=normal&page=&rows=&sort=name,asc&affiliations=${affiliationid}`, {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            if(res.success){
                var resData = response.data.data;
                //console.log(resData);
                if(resData){
                    var dataArr = [];
                    for(var i = 0; i < resData.length; i++){
                        var info = {
                            label: resData[i].name,
                            name: resData[i].name,
                            tutorid: resData[i].tutorid,
                            value: resData[i].tutorid,
                            fileurl: resData[i].fileurl,
                            intro: resData[i].intro
                        }

                        dataArr.push(info);
                    }
                    setTutorlist(dataArr);
                }
            }
        
        } catch (error) {
           console.error('Error:', error);
        }
    };

    // 수업 리스트 가져오기
    const getClassSchedule = async () => {
        try {
            const response = await axios.get(apiUrl+`/tutoring/ClassSchedule?tutorid=${tutorid}&year=${year}&month=${month}`, {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            if(res.success){
                var resData = response.data.data;
                //console.log(resData);
                if(resData){
                    var dataArr = [];
                    for(var i = 0; i < resData.length; i++){
                        var info = resData[i];
                        var starttxt = info.starttxt;
                        var starttxtArr = starttxt.split(":");
                        var startampm = "오전";
                        var starthour = Number(starttxtArr[0]);
                        if(starthour == 12){
                            startampm = "오후";
                        }else if(starthour > 12 && starthour < 24){
                            starthour = starthour - 12;
                            startampm = "오후";
                        }else if(starthour == 0 || starthour == 24){
                            startampm = "오전";
                            starthour = 12;
                        }
                        starthour = starthour.toString().padStart(2, '0');
                        info.starttxtStr = startampm + " " + starthour + ":" + starttxtArr[1];

                        info.title = info.starttxtStr;
                        info.id = info.scheduleid;
                        info.start = info.datefulltxt;
                        
                        dataArr.push(info);
                    }

                    setScheduleData(dataArr);
                }
            }
        
        } catch (error) {
           console.error('Error:', error);
        }
    };

    // 달력 이벤트
    const [showModal, setShowModal] = useState(false); // 모달 열림/닫힘 상태 관리
    const [classDate, setClassDate] = useState('');
    const [classTime, setClassTime] = useState('');
    const [tutorname, setTutorname] = useState('');
    const [curriculumid, setCurriculumid] = useState("");
    const [bookid, setBookid] = useState("");
    const [curriculumList, setCurriculumList] = useState([]);
    const [bookList, setBookList] = useState([]);
    const [currentRound, setCurrentRound] = useState('');
    
    // 달력 이벤트 클릭
    const handleClick = async (eventInfo) => {
        var scheduleid = eventInfo.event.id;
        var matchItem = scheduleData.find(item => item.scheduleid == scheduleid);
        //console.log(matchItem);

        setSelectedClass(matchItem);
        setClassDate(matchItem.datefulltxt); // 클릭한 이벤트 데이터 저장
        setClassTime(eventInfo.event.title); // 클릭한 이벤트 데이터 저장
        setTutorname(matchItem.tutorname); // 클릭한 이벤트 데이터 저장
        setCurriculumid("");
        setBookid("");
        getCurrentRoundInfo();

        // 커리큘럼 리스트 가져오기
        try {
            const response = await axios.get(apiUrl+'/curriculum?lectureid=' + matchItem.lectureid, {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            if(res.success){
                var resData = res.data;
                var dataArr = [];
                if(resData){
                    for(var i = 0; i < resData.length; i++){
                        var info = resData[i];
                        info.label = info.title;
                        info.value = info.curriculumid;

                        dataArr.push(info);
                    }
                }
                setCurriculumList(dataArr);
                setBookList([]);
                setShowModal(true);
            }
        } catch (error) {
           console.error('Error:', error);
        }
        
    };

    // 현재 회차 정보 가져오기
    const getCurrentRoundInfo = async () => {
        try {
            const response = await axios.get(apiUrl+'/tutoring/getCurrentRoundInfo', {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            if(res.success){
                var resData = res.data;
                //console.log(resData);
                if(resData){
                    var current = Number(resData.usecount) + 1 + totalCnt;
                    setCurrentRound(current + "/" + resData.availableno);
                }
            }

        } catch (error) {
            console.error('Error:', error);
        }
    };

    // 교재 리스트 가져오기
    const getBookList = async () => {
        try {
            const response = await axios.get(apiUrl+'/book/list?curriculumid=' + curriculumid, {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            if(res.success){
                var resData = res.data;
                var dataArr = [];
                if(resData){
                    for(var i = 0; i < resData.length; i++){
                        var info = resData[i];
                        info.label = info.title;
                        info.value = info.bookid;

                        dataArr.push(info);
                    }
                }
                setBookList(dataArr);
            }
        } catch (error) {
           console.error('Error:', error);
        }
    };

    useEffect(() => {
        if(curriculumid != ""){
            getBookList();
        }

    }, [curriculumid]);
    
    // 달력 년/월 변경 시
    const handleDateChange = (newYear, newMonth) => {
        setYear(newYear);
        setMonth(newMonth);
    };

    // 수업 선택
    const addClass = async () => {

        if(curriculumid == ""){
            alert("커리큘럼을 선택해주세요.");
            return false;
        }
        if(bookid == ""){
            alert("교재를 선택해주세요.");
            return false;
        }

        var params = {
            scheduleid: selectedClass.scheduleid,
            tutoringid: selectedClass.tutoringid,
            curriculumid: curriculumid,
            bookid: bookid,
        };
        //console.log(params);

        try {
            const response = await axios.post(apiUrl+'/tutoring/ScheduleCart', params, {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            if(res.success){
                alert("수업 선택되었습니다.");
                handleModalHide();
                getSelectedList();
            }else{
                alert(res.msg);
                handleModalHide();
            }
        
        } catch (error) {
           console.error('Error:', error);
        }
    };

    // 선택된 수업 리스트 가져오기
    const getSelectedList = async () => {

        try {
            const response = await axios.get(apiUrl+'/tutoring/ScheduleCart', {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            var dataArr = [];
            if(res.success){
                var resData = res.data.carts;
                //console.log(resData);
                if(resData){
                    for(var i = 0; i < resData.length; i++){
                        var info = resData[i];
                        var starttxt = info.starttxt;
                        var starttxtArr = starttxt.split(":");
                        var startampm = "오전";
                        var starthour = Number(starttxtArr[0]);
                        if(starthour == 12){
                            startampm = "오후";
                        }else if(starthour > 12 && starthour < 24){
                            starthour = starthour - 12;
                            startampm = "오후";
                        }else if(starthour == 0 || starthour == 24){
                            startampm = "오전";
                            starthour = 12;
                        }
                        starthour = starthour.toString().padStart(2, '0');
                        info.starttxtStr = startampm + " " + starthour + ":" + starttxtArr[1];

                        var endtxt = info.endtxt;
                        var endtxtArr = endtxt.split(":");
                        var endampm = "오전";
                        var endhour = Number(endtxtArr[0]);
                        if(endhour == 12){
                            endampm = "오후";
                        }else if(endhour > 12 && endhour < 24){
                            endhour = endhour - 12;
                            endampm = "오후";
                        }else if(endhour == 0 || endhour == 24){
                            endampm = "오전";
                            endhour = 12;
                        }
                        endhour = endhour.toString().padStart(2, '0');
                        info.endtxtStr = endampm + " " + endhour + ":" + endtxtArr[1];

                        info.roundtxt = info.roundnum + "/" + info.availableno;
                        dataArr.push(info);
                    }
                }
                setTotalCnt(res.total);
            }else{
                setTotalCnt(0);
            }
        
            dataList = dataArr;

            setTableData(dataList);

        } catch (error) {
           console.error('Error:', error);
        }
    };

    // '삭제' 컬럼 body 템플릿
    const deleteTemplate = (rowData) => {
        return (
            <>
                <ConfirmPopup />
                <Button onClick={() => delbtn(rowData)} label="삭제" severity="info" rounded outlined aria-label={`수업 삭제 버튼: ${rowData.roundtxt}`}/>
            </>
        );
    };

    // 개별 삭제 클릭시
    const delbtn = (data) => {
        confirmDialog({
            message: (
                <div role="alert">
                    해당 일정은 {data.datefulltxt}({data.weektxt}) {data.starttxtStr} ~ {data.endtxtStr}입니다.<br/> 해당 수업 일정을 삭제 하시겠습니까?
                </div>
            ),
            header: false,
            defaultFocus: 'accept',
            acceptLabel: '예',
            rejectLabel: '아니오',
            accept: () => delaccept(data),
            reject: delreject,
        });
    };

    // 개별 삭제 - 확인
    const delaccept = async (data) => {
        try {
            const response = await axios.delete(apiUrl+`/tutoring/ScheduleCart?scheduleid=${data.scheduleid}&tutoringid=${data.tutoringid}`, {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            if(res.success){
                toast.current.show({ severity: 'success',  detail: '삭제가 완료되었습니다.', life: 2000 });//완료메세지
                getSelectedList();
            }

        } catch (error) {
            console.error('Error:', error);
        }
    };

    // 개별 삭제 - 취소
    const delreject = () => {};

    // 장바구니 비우기
    const deleteScheduleCart = async () => {
        try {
            const response = await axios.delete(apiUrl+'/tutoring/ScheduleCart', {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            if(res.success){
                getTuteeInfo();
                getSelectedList();
            }

        } catch (error) {
            console.error('Error:', error);
        }
    };

    // 선택 목록 전체 취소
    const cancelClass = () => {
        deleteScheduleCart();
    };

    // 수업 예약하기
    const bookClass = async () => {
        if(tableData.length == 0){
            alert("수업을 선택해주세요.");
            return false;
        }
        try {
            const response = await axios.put(apiUrl+'/tutoring/booking', {}, {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            if(res.success){
                toast.current.show({ severity: 'success',  detail: '예약 완료되었습니다.', life: 2000 });//완료메세지
                navigate("/classResiList");
            }else{
                alert(`${res.msg}\n${res.data.duplicates}`);
            }

        } catch (error) {
            console.error('Error:', error);
        }
    };

    // '교재' 컬럼 body 템플릿
    const bookTemplate = (rowData) => {
        return (
            <button type='button' onClick={bookconfirm} className='bookBtn' aria-label={`${rowData.booktitle}교재 미리보기 버튼`}>{rowData.booktitle}</button>
        );
    };

    // 교재 클릭시 나오는 안내alert
    const bookconfirm = () => {
        confirmDialog({
            message: (
                <div className="flex flex-column align-items-center w-full gap-3 surface-border" role="alert">
                    <i className="pi pi-exclamation-circle text-5xl text-primary-500"></i>
                    <span className='mb-3'>수업에서 제공하는 강의 자료는 수강생만 학습목적으로 이용할 수 있습니다. 저작자의 허락없이 무단 복제 및 배포 시 민/형사상 책임을 질 수 있습니다. 동의하십니까?</span>
                </div>
            ),
            header: '주의사항',
            defaultFocus: 'accept',
            acceptLabel: '확인',
            rejectLabel: '취소',
            accept: bookaccept,
            reject: bookreject,
        });
    };

    const [showPDFViewer, setShowPDFViewer] = useState(false); // PDF 뷰어 모달 상태 관리
    const [pdfUrl, setPdfUrl] = useState('');

    const bookaccept = () => {
        // 확인 버튼을 눌렀을 때 
        setPdfUrl('/lusoft_introduce.pdf'); // 테스트pdf
        setShowPDFViewer(true); // PDF 뷰어 모달 열기
    };

    const bookreject = () => {
        // 취소 버튼을 눌렀을 때
    };

    const handleClosePDFViewer = () => {
        setShowPDFViewer(false); // PDF 뷰어 모달 닫기
    };
    
    
    // 수업 선택 모달 닫기
    const handleModalHide = () => {
        setShowModal(false);
    };

    // 페이징 접근성 변경 템플릿
    const template1 = {
        layout: 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink',
        FirstPageLink: (options) => {
            return (
                <button
                    type="button"
                    ref={el => pageRefs.current[0] = el}
                    className={classNames(options.className, 'border-round')}
                    onClick={options.onClick}
                    disabled={options.disabled}
                    aria-label="처음으로"
                    tabIndex={options.disabled ? -1 : 0}
                >
                    <span className="p-3">
                        <i className="pi pi-angle-double-left"></i>
                    </span>
                </button>
            );
        },
        PrevPageLink: (options) => {
            return (
                <button
                    type="button"
                    ref={el => pageRefs.current[1] = el}
                    className={classNames(options.className, 'border-round')}
                    onClick={options.onClick}
                    disabled={options.disabled}
                    aria-label="이전"
                    tabIndex={options.disabled ? -1 : 0}
                >
                    <span className="p-3">
                        <i className="pi pi-angle-left"></i>
                    </span>
                </button>
            );
        },
        NextPageLink: (options) => {
            return (
                <button
                    type="button"
                    ref={el => pageRefs.current[pageRefs.current.length - 2] = el}
                    className={classNames(options.className, 'border-round')}
                    onClick={options.onClick}
                    disabled={options.disabled}
                    aria-label="다음"
                    tabIndex={options.disabled ? -1 : 0}
                >
                    <span className="p-3">
                        <i className="pi pi-angle-right"></i>
                    </span>
                </button>
            );
        },
        LastPageLink: (options) => {
            return (
                <button
                    type="button"
                    ref={el => pageRefs.current[pageRefs.current.length - 1] = el}
                    className={classNames(options.className, 'border-round')}
                    onClick={options.onClick}
                    disabled={options.disabled}
                    aria-label="끝으로"
                    tabIndex={options.disabled ? -1 : 0}
                >
                    <span className="p-3">
                        <i className="pi pi-angle-double-right"></i>
                    </span>
                </button>
            );
        },
        PageLinks: (options) => {
            const index = 2 + options.page;
            return (
                <button
                    type="button"
                   ref={el => {
                        pageRefs.current[index] = el;
                    }}
                    className={options.className}
                    onClick={options.onClick}
                    aria-label={options.page + 1}
                >
                    {options.page + 1}
                </button>
            );
        },
    };

    // 페이징 키보드 이벤트
    const pageRefs = useRef([]);
    pageRefs.current = Array(2 + Math.ceil(totalCnt / row)).fill(null);
    

    const handleKeyDown = (event) => {
        const visibleRefs = pageRefs.current.filter(ref => ref !== null);
        const currentFocusIndex = visibleRefs.findIndex(ref => ref === document.activeElement);
        
        if (currentFocusIndex === -1) return; // If no current element, do nothing

        if (event.key === 'ArrowDown') {
            event.preventDefault();
            let nextIndex = (currentFocusIndex + 1) % visibleRefs.length;
            visibleRefs[nextIndex].focus();
        } else if (event.key === 'ArrowUp') {
            event.preventDefault();
            let prevIndex = (currentFocusIndex - 1 + visibleRefs.length) % visibleRefs.length;
            visibleRefs[prevIndex].focus();
        }
    };


    const touchStartX = useRef(0);
    const touchEndX = useRef(0);
    const handleTouchStart = (event) => {
        touchStartX.current = event.touches[0].clientX;
    };

    const handleTouchEnd = (event) => {
        touchEndX.current = event.changedTouches[0].clientX;
        const swipeThreshold = 50; // Minimum swipe distance in pixels
        const visibleRefs = pageRefs.current.filter(ref => ref !== null);
        const currentFocusIndex = visibleRefs.findIndex(ref => ref === document.activeElement);

        if (currentFocusIndex === -1) return; // If no current element, do nothing

        if (touchStartX.current - touchEndX.current > swipeThreshold) {
            // Swipe left (Move focus to the next button)
            const nextIndex = (currentFocusIndex + 1) % visibleRefs.length;
            visibleRefs[nextIndex].focus();
        } else if (touchEndX.current - touchStartX.current > swipeThreshold) {
            // Swipe right (Move focus to the previous button)
            const prevIndex = (currentFocusIndex - 1 + visibleRefs.length) % visibleRefs.length;
            visibleRefs[prevIndex].focus();
        }
    };



    // 모바일 페이징
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(3); // 페이지당 3개씩 보여줌

    const onPageChange = (event) => {
        setFirst(event.first);
        setRows(event.rows);
    };

    return (
        <div>
            <Layout title="수업 예약하기" pagename="수업 예약하기">
                <div className="contain learnermain">

                    <div className="flex  ">
                        <div className="breadcrumb">
                            <BreadCrumb model={items} home={home} role="navigation" aria-label="브레드크럼 내비게이션" className="flex-3">
                            {items.map((item, index) => (
                            <span key={index} className={item.className} aria-current={index===items.length - 1 ? 'page' :
                                null}>
                                <Link to={item.url}>
                                {item.label}
                                </Link>
                            </span>
                            ))}
                            </BreadCrumb>
                        </div>
                        
                        <div className="teacherListBox">
                            {tutorlist.map((item, index) => (
                                <button type="button" className={item.tutorid == tutorid ? "on" : ''} key={index} onClick={() => onTutorChange(item)}>
                                    <img src={item.fileurl} alt={item.name} />
                                    <span className="t_name">{item.name}</span>
                                </button>
                            ))}
                        </div>
                    </div>

                    <div className="flex flex-wrap gap-3 mb-3">
                        <div className='Side' role='region' aria-label='나의 수업 및 교재 정보'>
                            <div>
                                <h3>나의 수업 정보</h3>
                                <div className="infoBox">
                                    <div className="mb-5">
                                        <ul>
                                            <li>☑️ 수업 예약 가능 횟수 : {myClassInfo.availableroundno}회</li>
                                            <li>☑️ 나의 수업 유효 기간 : {myClassInfo.startdate}~{myClassInfo.enddate}</li>
                                        </ul>
                                    </div>
                                    <div>
                                        <ul>
                                            <li className="pb-2">📌 예약 관련 주의사항</li>
                                            <li>1. 첫 수업 시 예약한 강사와 수업이 고정됩니다.</li>
                                            <li>2. 특수한 상황을 제외하고 강사 변경은 불가합니다.</li>
                                            <li>3. 가장 예약할 수 있는 시간이 많은 강사를 선택해보세요!</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <h3>Textbook Selection Notice</h3>
                                <div className="infoBox">
                                    <div className="mb-5">
                                        <ul>
                                            <li className="pb-2">Choose your learning level</li>
                                            <li>☑️ Standard: Grades 1 through 6</li>
                                            <li>☑️ Talk in Daily Life: Grades 3 and 4</li>
                                            <li>☑️ Freetalk: Grades 2, 3, and 5</li>
                                            <li>☑️ Business: Grade 6</li>
                                            <li>☑️ Idiom: Grade 6</li>
                                        </ul>
                                    </div>
                                    <div>
                                        <ul>
                                            <li className="pb-2">📌 Introduction to Textbook Features</li>
                                            <li>Standard: Targets a wide range of learners from beginners to advanced levels, systematically enhancing language skills from basic grammar to vocabulary and sentence structure.</li>
                                            <li>Talk in Daily Life: Focuses on expressions and situational usage necessary for everyday conversations, aiding learners to converse naturally in real-life situations.</li>
                                            <li>Freetalk: Provides opportunities for free discussion and expression of opinions on various topics, enhancing learners' communication skills.</li>
                                            <li>Business: Focuses on learning Korean expressions and sentences used in business environments, reinforcing vocabulary and expressions necessary for business-related situations.</li>
                                            <li>Idiom: Enriches learners' language abilities by teaching Korean idiomatic expressions, enabling more natural usage of Korean language.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                          
                        <div className='center' role="region"  aria-label="수업 정보">
                            <h3 aria-label="수업 선택">
                                수업 선택
                                {/* <Dropdown options={tutorlist} label="강사" placeholder="강사 선택" aria-label="강사 선택" onChange={onTutorChange}/>  */}
                            </h3>
                            <Calendar
                                calendarRef={calendarRef}
                                events={scheduleData}
                                eventClick={handleClick}
                                onDateChange={handleDateChange}
                                selectable={false}
                                aria-label="수업 일정"
                            />
                        </div>

                        <div className="Right" role="region" aria-label="강사 정보">
                            <h3 aria-label="강사 프로필 ">강사 프로필</h3>
                            {tutorInfo.profile != "" ? (
                                <>
                                    <div className="teacherImg">
                                        <img src={tutorInfo.profile} alt="강사 사진" />
                                    </div>
                                    <p className="editor" dangerouslySetInnerHTML={{ __html: tutorInfo.intro }}></p>
                                </>
                            ) : null}
                        </div>
                    </div>
                        
                    <div className="selecttabele" role="region" aria-label="선택목록">
                        <div className="classReservation" style={{ width: '100%', padding:'20px' }}>
                            <h3>선택목록</h3>
                            <div className="tableBox">
                                {/* pc */}
                                <DataTable
                                    className="listpc"
                                    value={tableData}
                                    emptyMessage="데이터가 없습니다" // No results found 메시지 커스터마이즈
                                    rows={5}
                                    tableStyle={{ minWidth: '50rem' }}
                                    aria-label="선택목록 테이블"
                                >
                                    <Column field="datefulltxt" header="날짜" bodyStyle={{ textAlign: 'center' }}></Column>
                                    <Column field="starttxtStr" header="시간" bodyStyle={{ textAlign: 'center' }}></Column>
                                    <Column field="tutorname" header="강사" bodyStyle={{ textAlign: 'center' }}></Column>
                                    {/*<Column field="booktitle" header="교재" body={bookTemplate} bodyStyle={{ textAlign: 'center' }}></Column>*/}
                                    <Column field="booktitle" header="교재" bodyStyle={{ textAlign: 'center' }}></Column>
                                    <Column field="roundtxt" header="차시" bodyStyle={{ textAlign: 'center' }}></Column>
                                    <Column field="delete" header="삭제" body={deleteTemplate} bodyStyle={{ textAlign: 'center' }}></Column>
                                </DataTable>

                                {/* mob */}

                                <div className="listmob">
                                    {/* <ul className="table">
                                        {tableData.slice(first, first + rows).map((rowData, index) => (
                                        <li className="flex" key={index}>
                                            <div>
                                                <div className="top flex-column align-items-baseline">
                                                    <b className='order'>{rowData.roundtxt}</b>
                                                    <button type='button' onClick={bookconfirm} className='bookBtn book'
                                                        aria-label={`${rowData.booktitle} 교재 미리보기 버튼`}>
                                                        {rowData.booktitle}
                                                    </button>
                                                </div>
                                                <div className="bottom">
                                                    {rowData.datefulltxt} {rowData.starttxtStr} {rowData.tutorname}
                                                </div>
                                            </div>
                                            <div className="flex flex-row justify-content-end btns">
                                                <button className="p-button p-button-outlined p-button-info"
                                                    onClick={()=> delbtn(rowData)}
                                                    aria-label="수업 삭제 버튼"
                                                    >
                                                    삭제
                                                </button>
                                            </div>
                                        </li>
                                        ))}
                                    </ul> */}

                                    <table className="table" role="table">
                                        <thead className="screen-reader-only">
                                            <tr role="row">
                                                <th role="columnheader">차시</th>
                                                <th role="columnheader">시간</th>
                                                <th role="columnheader">강사</th>
                                                <th role="columnheader">교재</th>
                                                <th role="columnheader">삭제</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tableData.slice(first, first + rows).map((rowData, index) => (
                                                <tr key={index} >
                                                    <td className="mb-2"><b className="order">{rowData.roundtxt}</b></td>
                                                    <td>{rowData.datefulltxt} {rowData.starttxtStr}</td>
                                                    <td className="mt-2 mb-2">
                                                        <button type='button' onClick={bookconfirm} className='bookBtn book'
                                                        aria-label={`${rowData.booktitle} 교재 미리보기 버튼`}>
                                                        {rowData.booktitle}
                                                        </button>
                                                    </td>
                                                    
                                                    <td>{rowData.tutorname}</td>
                                                    
                                                    <td className="flex flex-row justify-content-end btns"> 
                                                        <button className="p-button p-button-outlined p-button-info"
                                                            onClick={()=> delbtn(rowData)}
                                                            aria-label="수업 삭제 버튼"
                                                        >
                                                            삭제
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                     </table>
                                </div>
                                
                                <Paginator
                                    role="navigation"
                                    aria-label="페이지 탐색"
                                    first={first}
                                    rows={row}
                                    totalRecords={totalCnt}
                                    onPageChange={onPageChange}
                                    template={template1}
                                    tabIndex="0"
                                    onKeyDown={handleKeyDown}
                                    onTouchStart={handleTouchStart}
                                    onTouchEnd={handleTouchEnd}
                                />
                                

                            </div>
                        </div>

                        <div className="flex flex-wrap justify-content-center gap-3 mt-5 p-3">
                            <Button label="예약하기" onClick={bookClass} severity="previewbtn" aria-label="예약하기 버튼" />
                            <Button label="취소" onClick={cancelClass} severity="cancbtn" aria-label="취소 버튼" />
                        </div>
                    </div>
                </div>
            </Layout>

            <Toast ref={toast} role="alert"/>

            {/* 교재 alert */}
            <ConfirmDialog style={{ width: '30vw' }} breakpoints={{ '1100px': '75vw', '960px': '80vw' }} className='bookDia' draggable={false}  />
            
            {/* PDF 뷰어 모달 */}
            <PDFViewer showModal={showPDFViewer} onCloseModal={handleClosePDFViewer} pdfUrl={pdfUrl} />
            
            {/* 달력 이벤트 모달 */}
            <Dialog
                style={{ width: '30vw' }}
                draggable={false}
                breakpoints={{ '1100px': '75vw', '960px': '80vw' }}
                header="수업 선택"
                visible={showModal}
                onHide={handleModalHide}
                focusOnShow={true} // 다이얼로그가 열렸을 때 포커스가 이동하도록 함
                modal={true} // 모달 다이얼로그로 설정하여 다이얼로그 외부를 클릭했을 때 닫히지 않도록 함
                aria-modal="true" // 스크린 리더가 모달 상태를 인식할 수 있도록 함
                role="dialog" // 모달 대화 상자 역할을 설정하여 스크린 리더가 인식할 수 있도록 함
                aria-labelledby="pr_id_10_header" // 대화 상자 제목에 해당하는 요소의 ID를 명시함
            >
                <div>
                    <form onSubmit={(e)=> e.preventDefault()} className="pt-4" >
                        <div className="grid p-fluid formgrid">
                            <fieldset className="col-12 mb-4">
                                <div >
                                    <div className="font-bold block mb-2"><legend>시간 및 날짜</legend></div>
                                    <p className="block text-700 m-0"><span>{classDate} {classTime}</span></p>
                                </div>            
                            </fieldset>
                            
                            <fieldset className="col-12 mb-4">
                                <div>
                                    <div className="font-bold block mb-2"><legend>강사</legend></div>
                                    <p className="block text-700 m-0"><span>{tutorname}</span></p>
                                </div>
                            </fieldset>

                            <fieldset className="col-12 field">
                                <div>
                                    <label htmlFor="curriculumid" className="font-bold block mb-2"><legend>커리큘럼</legend></label>
                                    <select name="curriculumid" id="curriculumid" className="customSel" onChange={(e) => setCurriculumid(e.target.value)} aria-label="커리큘럼 선택">
                                        <option value="">선택</option>
                                        {curriculumList.map((item, index) => (
                                            <option key={index} value={item.value}>{item.label}</option>
                                        ))}
                                    </select>
                                </div>
                            </fieldset>
                            <fieldset className="col-12 mb-4 field">
                                <div>
                                    <label htmlFor="book" className="font-bold block mb-2"><legend>교재</legend></label>
                                    <select name="bookid" id="bookid" className="customSel" onChange={(e) => setBookid(e.target.value)} aria-label="교재 선택">
                                        <option value="">선택</option>
                                        {bookList.map((item, index) => (
                                            <option key={index} value={item.value}>{item.label}</option>
                                        ))}
                                    </select>
                                </div>
                            </fieldset>
                            <fieldset className="col-12 mb-4 field">
                                <div className="col-12">
                                    <div className="font-bold block mb-2">차시</div>
                                    <p className="block text-700 m-0"><span>{currentRound}</span></p>
                                </div>
                            </fieldset>
                        </div>
                        <div className="flex gap-2 align-content-center justify-content-center mt-5 ">
                            <Button type="button" label="등록" onClick={addClass} severity="previewbtn" aria-label="수업 선택 저장 버튼"/>
                            <Button type="button" label="취소" onClick={handleModalHide} severity="cancbtn" aria-label="수업 선택 취소 버튼" />
                        </div>
                    </form>
                </div>
            </Dialog>
        </div>
    );
    
};
export default ClassResiSchedule;