import React, { useState, useRef, useEffect  } from "react";
import { useSelector } from 'react-redux';
import '../../assets/css/account.css';
import { InputText } from "primereact/inputtext";
import { Message } from 'primereact/message';
//import Dropdown from '../../../components/dropdownValue'; // 커스텀 접근성 드롭다운 (value값 포함)

import axios from 'axios';

import config from '../../config';
const apiUrl = config.apiUrl;

// 타이틀
export function useTitle(initialTitle) {
    const [title, setTitle] = useState(initialTitle);
    const updateTitle = () => {
        const htmlTitle = document.querySelector("title");
        htmlTitle.innerText = title;
    };

    useEffect(updateTitle, [title]);
    return setTitle;
};

function FindPw() {

    useTitle("비밀번호 찾기");

    const accessToken = useSelector(state => state.auth.accessToken);

    const [userid, setUserid] = useState('');
    const [selectedDomain, setSelectedDomain] = useState('');
    const [value, setValue] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const inputRef = useRef(null); 

    const handleDomainChange = (event) => {
        setSelectedDomain(event.target.value)
        setErrorMessage('') // 초기화
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        var isValid = true;
        if (!userid.trim()) {
            setErrorMessage('아이디를 입력해주세요.');
            isValid = false;
            return;
        }
        if (!selectedDomain) {
            setErrorMessage('이메일을 선택해주세요.');
            isValid = false;
            return;
        }
        if (selectedDomain === 'self' && !value.trim()) {
            setErrorMessage('이메일을 입력해주세요.');
            inputRef.current.focus(); // 입력 필드에 포커스 설정
            isValid = false;
            return;
        }
        if (selectedDomain === 'self' && !value.includes('.')) {
            setErrorMessage('이메일을 정확히 입력해주세요.');
            inputRef.current.focus(); // 입력 필드에 포커스 설정
            isValid = false;
            return;
        }

        if(isValid){
            sendResetEmail();
        }

    };

    // 비밀번호 변경 이메일 발송
    const sendResetEmail = async () => {

        var email = userid + '@' + (selectedDomain === 'self' ? value : selectedDomain);

        try {
            const response = await axios.post(apiUrl+'/auth/sendPasswordResetEmail/'+email, {}, {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            if(res.success){
                setSuccessMessage('해당 메일로 비밀번호 변경 메일이 발송되었습니다.');
            }else{
                alert(res.msg);
            }
        
        } catch (error) {
           console.error('Error:', error);
        }
    };

    return (
        <div className="accountBox password">
            <div className="card mb-0 h-auto">
                <h4 className="text-center mb-7">비밀번호 찾기</h4>
                <form onSubmit={handleSubmit}>
                    <div className="flex align-items-center gap-2">
                        <span className="idinput">
                            <InputText id="userid" placeholder="ID" value={userid} onChange={(e) => setUserid(e.target.value)} aria-label="아이디 입력 필드" />
                        </span>
                        <span>@</span>
                        {selectedDomain === 'self' && (
                            <InputText  ref={inputRef} value={value} onChange={(e) => setValue(e.target.value)} className="w-5 selfinput" aria-label="직접 입력 필드"  />
                        )}
                        <select name="selectedDomain" id="selectedDomain" className="customSel" onChange={handleDomainChange} aria-label="이메일 도메인 선택">
                            <option value="">도메인 선택</option>
                            <option value="naver.com">naver.com</option>
                            <option value="gmail.com">gmail.com</option>
                            <option value="daum.net">daum.net</option>
                            <option value="nate.com">nate.com</option>
                            <option value="self">직접입력</option>
                        </select>
                        {/* <Dropdown options={domainOptions} label="이메일" value={selectedDomain} onChange={handleDomainChange} placeholder="이메일 선택"/> */}
                    </div>
                    {errorMessage && <Message severity="error" text={errorMessage} aria-live="polite"  icon={false}/>}
                    <div className="text-center btns">
                        <button type="submit" className="logbtn" aria-label={'비밀번호 변경하기 버튼'}>비밀번호 변경하기</button>
                        {successMessage && <Message severity="success" text={successMessage} aria-live="polite"/>}
                    </div>
                </form>
            </div>
        </div>
    );
}

export default FindPw;
