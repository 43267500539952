import React, { useState, useRef, useEffect  } from 'react';
import { useSelector } from 'react-redux';

import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from "primereact/inputtextarea";
import { Paginator } from 'primereact/paginator';
import { classNames } from 'primereact/utils';
import { Link } from 'react-router-dom';
//import Dropdown from '../../../components/dropdownValue'; // 커스텀 접근성 드롭다운 (value값 포함)
import ReactToPrint from "react-to-print";
import { useReactToPrint } from 'react-to-print';


//import sampleimg from '../../../assets/img/thumb_sample.png'; // 샘플 이미지
import profile_imgDefult from '../../../assets/img/profile_imgDefult.png'; // 프로필 기본 이미지
import Layout from '../../../components/lms/layout/layout';
import SurveyDialog from '../../../components/lms/modal/SurveyDialog'; //수업만족도 설문 안내 모달
import PDFViewer from '../../../components/lms/modal/PDFViewer'; // 교재뷰어
import ConfirmationContent  from '../../../components/lms/ConfirmationContent ';//수강확인서

import axios from 'axios';

import config from '../../../config';
const apiUrl = config.apiUrl;

var dataList = [];
var apiCnt = 0;

// 타이틀
export function useTitle(initialTitle) {
    const [title, setTitle] = useState(initialTitle);
    const updateTitle = () => {
        const htmlTitle = document.querySelector("title");
        htmlTitle.innerText = title;
    };

    useEffect(updateTitle, [title]);
    return setTitle;
};


const AttendanceStatus = () => {

    useTitle("출석현황");

    //브러드크럼
    const items = [{ label: '출석현황', className: 'nowpage' }, ];
    const home = { icon: 'pi pi-home', url: '/dashboard' }

    const accessToken = useSelector(state => state.auth.accessToken);

    const [totalCnt, setTotalCnt] = useState(0);
    const activePage = useRef(1);
    const [row, setRowData] = useState(5);
    const [tableData, setTableData] = useState([...dataList]);
    const [formData, setFormData] = useState({
        round: '',
        searchtext: ''
    });

    const [profileData, setProfileData] = useState({
        file: '',
        originfile: '',
        tuteeid: 'me',
    });

    const [roundlist, setRoundlist] = useState([]);

    const [attendanceInfo, setAttendanceInfo] = useState({
        join_count: 0,
        absent_count: 0,
        wait_count: 0,
        startdate: '',
        enddate: '',
    });

    const [tuteeInfo, setTuteeInfo] = useState({
        name: '',
        affiliationname: '',
        lecturetype: '',
        tutorname: '',
        attendance_rate: 0,
        affiliation_attendance_rate: 0,
    });

    const [scheduleid, setScheduleid] = useState(0);
    const [researchid, setResearchid] = useState(""); // 만족도조사 설문 id

    //프로필 이미지 업로드
    const [profileImgSrc, setProfileImgSrc] = useState(null);
    const fileInputRef = useRef(null);
    const handleClick = () => {
        fileInputRef.current.click();
    };

    // 수강확인서 발급 버튼
    const [courseConfirm, setCourseConfirm] = useState(false);
    const [confirmData, setConfirmData] = useState({
        tuteename: "",
        lecturetype: "",
        startdate: "",
        enddate: "",
        today: "",
    });

    useEffect(() => {

        updateAttendace();

        getTuteeInfo();

        getResearchInfo();

    }, []);

    useEffect(() => {

        if(formData.round !== ''){
            checkCourseConfirm();
            getMyClassSchedule();
        }

    }, [formData.round]);

    useEffect(() => {

        if(profileData.file){
            saveProfile();
        }

    }, [profileData.file]);

    useEffect(() => {

        // 만족도 조사 팝업
        if(researchid !== ""){
            setDialogVisible(true);
        }

    }, [researchid]);

    // 결석 처리
    const updateAttendace = async () => {

        var params = {
            attendance: '결석'
        }
        try {
            const response = await axios.put(apiUrl+'/tutoring/attendance/0', params, {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            if(res.success){
                getMyClassSchedule();
            }

        } catch (error) {
            console.error('Error:', error);
        }
    };

    // 수업 예약 리스트 가져오기
    const getMyClassSchedule = async () => {

        try {
            const response = await axios.get(apiUrl+`/tutoring/MyClassSchedule?searchtext=${formData.searchtext}&round=${formData.round}&page=${activePage.current}&rows=${row}&sort=startdate,asc`, {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            var dataArr = [];
            if(res.success){
                var resData = response.data.data.schdules;
                //console.log(resData);
                if(resData){
                    
                    apiCnt++;

                    var roundno = response.data.data.affiliation_availableno;
                    var join_count = response.data.data.join_count;
                    var absent_count = response.data.data.absent_count;
                    var wait_count = response.data.data.wait_count;
                    var startdate = response.data.data.startdate;
                    var enddate = response.data.data.enddate;
                    setAttendanceInfo({
                        ...attendanceInfo,
                        join_count: join_count,
                        absent_count: absent_count,
                        wait_count: wait_count,
                        startdate: startdate,
                        enddate: enddate,
                    });

                    for(var i = 0; i < resData.length; i++){
                        var info = resData[i];
                        var starttxt = info.starttxt;
                        var starttxtArr = starttxt.split(":");
                        var startampm = "오전";
                        var starthour = Number(starttxtArr[0]);
                        if(starthour > 12){
                            starthour = starthour - 12;
                            startampm = "오후";
                        }
                        starthour = starthour.toString().padStart(2, '0');
                        info.starttxtStr = startampm + " " + starthour + ":" + starttxtArr[1];
                        
                        var endtxt = info.endtxt;
                        var endtxtArr = endtxt.split(":");
                        var endampm = "오전";
                        var endhour = Number(endtxtArr[0]);
                        if(endhour > 12){
                            endhour = endhour - 12;
                            endampm = "오후";
                        }
                        endhour = endhour.toString().padStart(2, '0');
                        info.endtxtStr = endampm + " " + endhour + ":" + endtxtArr[1];
                        info.roundtxt = info.num + "/" + roundno;

                        // 수업시작 24시간 전인지 확인
                        var now = new Date();
                        now.setDate(now.getDate() + 1);
                        var yesterday = new Date(now);
                        var yesterdayTime = yesterday.getTime();

                        var start = new Date(info.datefulltxt + " " + info.starttxt);
                        var startTime = start.getTime();

                        if(yesterdayTime < startTime){
                            info.isdeletable = true;
                        }else{
                            info.isdeletable = false;
                        }
                        
                        dataArr.push(info);
                    }
                }

                setTotalCnt(res.total);
            }else{
                setTotalCnt(0);
            }

            dataList = dataArr;

            setTableData(dataList);

        } catch (error) {
            console.error('Error:', error);
        }
    };

    // 출석률 가져오기
    const getTuteeInfo = async () => {

        try {
            const response = await axios.get(apiUrl+'/tutee/info/me', {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            if(res.success){
                var resData = response.data.data;
                //console.log(resData);
                if(resData){
                    setProfileImgSrc(resData.fileurl);
                    setTuteeInfo({
                        ...tuteeInfo,
                        name: resData.name,
                        affiliationname: resData.affiliationname,
                        lecturetype: resData.lecturetype,
                        tutorname: resData.tutorname,
                        attendance_rate: resData.attendance_rate,
                        affiliation_attendance_rate: resData.affiliation_attendance_rate,
                    });

                    var roundno = resData.roundno;
                    var roundArr = [];
                    for(var i = roundno; i > 0; i--){
                        roundArr.push({
                            label: i + '차',
                            value: i
                        });
                    }

                    setRoundlist(roundArr)
                    setFormData({
                        ...formData,
                        round: roundno
                    });                     
                }

            }
        
        } catch (error) {
           console.error('Error:', error);
        }
    };

    // 만족도조사 설문 정보 가져오기
    const getResearchInfo = async () => {
        try {
            const response = await axios.get(apiUrl+'/research/checkParticipate',{
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            if(res.success){
                setResearchid(res.data.researchid);
            }

        } catch (error) {
            console.error('Error:', error);
        }
    };

    // 수강확인서 존재여부 확인
    const checkCourseConfirm = async () => {
        try {
            const response = await axios.get(apiUrl+'/courseconfirm/exists/me?roundno='+formData.round,{
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            if(res.success){
                setCourseConfirm(res.data.isexists);
                if(res.data.isexists){
                    getCourseConfirm();
                }
            }

        } catch (error) {
            console.error('Error:', error);
        }
    };

    // 수강확인서 정보
    const getCourseConfirm = async () => {
        try {
            const response = await axios.get(apiUrl+'/courseconfirm/info/me?roundno='+formData.round,{
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            if(res.success){
                var resData = res.data;
                if(resData){
                    
                    var today = getFormattedDate();
                    var startdate = resData.startdate.split("T")[0];
                    var enddate = resData.enddate.split("T")[0];

                    setConfirmData({
                        ...confirmData,
                        tuteename: resData.tutorname,
                        lecturetype: resData.lecturetype,
                        startdate: startdate,
                        enddate: enddate,
                        today: today,
                    });
                }
            }

        } catch (error) {
            console.error('Error:', error);
        }
    };

    // 오늘 날짜 가져오기
    const getFormattedDate = () => {
        const date = new Date();
        const year = date.getFullYear();
        const month = (date.getMonth() + 1);
        const day = date.getDate();
    
        return `${year}년 ${month}월 ${day}일`;
    }

    // 프로필 이미지 선택됐을 때
    const handleFileChange = async (e) => {

        var fileInfo = e.target.files[0];
        if (fileInfo) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setProfileImgSrc(reader.result);
            };
            reader.readAsDataURL(fileInfo);
        }
        fileInfo.filename = fileInfo.name;

        var fileData = new FormData();
        fileData.append('attachment', fileInfo);
        fileData.append('folder', 'tutee');

        //console.log(fileData);
        
        try {
            const response = await axios.post(apiUrl+'/file/single', fileData);
            var res = response.data;
            //console.log(res);
            if(res.success){
                setProfileData({
                    ...profileData,
                    file: res.data.key,
                    originfile: fileInfo.filename
                });
            }

        } catch (error) {
            console.error('Error:', error);
        }
    };

    // 프로필 이미지 저장
    const saveProfile = async () => {
        const params = {
            file: profileData.file,
            originfile: profileData.originfile,
            tuteeid: profileData.tuteeid
        };

        try {
            const response = await axios.put(apiUrl+'/tutee/file', params,{
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            if(res.success){
                alert("프로필 이미지가 변경되었습니다.");
            }

        } catch (error) {
            console.error('Error:', error);
        }
    };

    /*
    // '교재' 컬럼 body 템플릿
    const bookTemplate = (rowData) => {
        return (
                <button type='button' onClick={bookconfirm} className='bookBtn' aria-label={`${rowData.textbook}교재 미리보기 버튼`}>{rowData.textbook}</button>
            );
    };

    // 교재 클릭시 나오는 안내alert
    const bookconfirm = () => {
        confirmDialog({
             message: (
                <div className="flex flex-column align-items-center w-full gap-3  surface-border" role="alert">
                    <i className="pi pi-exclamation-circle text-5xl text-primary-500"></i>
                    <span className='mb-3'>수업에서 제공하는 강의 자료는 수강생만 학습목적으로 이용할 수 있습니다. 저작자의 허락없이 무단 복제 및 배포 시 민/형사상 책임을 질 수 있습니다. 동의하십니까?</span>
                </div>
            ),
            header: '주의사항',
            defaultFocus: 'accept',
            acceptLabel: '확인',
            rejectLabel: '취소' ,
            accept,
            reject
        });
    };

    const [showPDFViewer, setShowPDFViewer] = useState(false); // PDF 뷰어 모달 상태 관리
    const [pdfUrl, setPdfUrl] = useState('');

    const accept = () => {
        // 확인 버튼을 눌렀을 때 
        setPdfUrl('/lusoft_introduce.pdf'); // 테스트pdf
        setShowPDFViewer(true); // PDF 뷰어 모달 열기
    };

    const reject = () => {
        // 취소 버튼을 눌렀을 때
    };

    const handleClosePDFViewer = () => {
        setShowPDFViewer(false); // PDF 뷰어 모달 닫기
    };
    
    */

    // '학생 출석 여부' 컬럼 body 템플릿
    const attendanceTemplate = (rowData) => {
        let color, fontWeight;
        switch (rowData.attendance) {
            case '출석':
                color = 'green';
                fontWeight = '600';
                break;
            case '결석':
                color = 'gray';
                break;
            default:
                color = 'black';
        }

        return <span style={{ color: color, fontWeight: fontWeight }}>{rowData.attendance}</span>;
    };

    // '사유' 컬럼 body 템플릿
    const reasonTemplate = (rowData) => {
        if (rowData.attendance === '결석') {
            if(rowData.reason === null){
                return (
                    <Button onClick={() => openReasonModal(rowData)} aria-label="결석 사유 버튼">미작성</Button>
                );
            }else{
                return (
                    <Button type="button" aria-label="결석 사유 작성 완료">완료</Button>
                );
            }
        } else {
            return null; // 결석이 아닌 경우 사유 버튼을 표시하지 않음
        }
    };
    
    // 사유 입력 팝업
    const [visible, setVisible] = useState(false);

    // 사유 textarea
    const [reason, setReason] = useState('');

    // 결석 사유 모달 열기
    const openReasonModal = (rowData) => {
        setScheduleid(rowData.scheduleid);
        setVisible(true);
    };

    // 결석 사유 저장
    const saveReason = async () => {
        if(reason === ''){
            alert('사유를 입력해주세요.');
            return;
        }
        var params = {
            reason: reason
        };

        try {
            const response = await axios.put(apiUrl+`/tutoring/schedule/reason/${scheduleid}`, params, {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            if(res.success){
                alert("사유 작성 완료되었습니다.");
                setReason('');
                setVisible(false);
                getMyClassSchedule();
            }
        }catch (error) {
            console.error('Error:', error);
         }
    };
    
    //수업 만족도 설문 안내 모달
    const [dialogVisible, setDialogVisible] = useState(false); 

    const handleHideDialog = () => {
        setDialogVisible(false);
    };

    // 프린터
    const componentRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const handlePrintClick = async () => {
        handlePrint();
    };
    
    // 검색
    function submitSearch(){
        activePage.current = 1;
        getMyClassSchedule();
    };

    // 검색어 입력 시
    const handleChange = (e) => {
        const { name, value } = e.target;
        
        setFormData({
            ...formData,
            [name]: value
        });
    };

    // 수강 회차 변경 시
    const onRoundChange = (value) => {
        setFormData({
            ...formData,
            round: Number(value)
        });
    };

    // 검색 기능
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            submitSearch();
        }
    };

    // 페이지 변경 시
    const onPageChange = (event) => {
        activePage.current = event.page + 1;
        getMyClassSchedule();
    };

    // 페이징 접근성 변경 템플릿
    const template1 = {
        layout: 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink',
        FirstPageLink: (options) => {
            return (
                <button
                    type="button"
                    ref={el => pageRefs.current[0] = el}
                    className={classNames(options.className, 'border-round')}
                    onClick={options.onClick}
                    disabled={options.disabled}
                    aria-label="처음으로"
                    tabIndex={options.disabled ? -1 : 0}
                >
                    <span className="p-3">
                        <i className="pi pi-angle-double-left"></i>
                    </span>
                </button>
            );
        },
        PrevPageLink: (options) => {
            return (
                <button
                    type="button"
                    ref={el => pageRefs.current[1] = el}
                    className={classNames(options.className, 'border-round')}
                    onClick={options.onClick}
                    disabled={options.disabled}
                    aria-label="이전"
                    tabIndex={options.disabled ? -1 : 0}
                >
                    <span className="p-3">
                        <i className="pi pi-angle-left"></i>
                    </span>
                </button>
            );
        },
        NextPageLink: (options) => {
            return (
                <button
                    type="button"
                    ref={el => pageRefs.current[pageRefs.current.length - 2] = el}
                    className={classNames(options.className, 'border-round')}
                    onClick={options.onClick}
                    disabled={options.disabled}
                    aria-label="다음"
                    tabIndex={options.disabled ? -1 : 0}
                >
                    <span className="p-3">
                        <i className="pi pi-angle-right"></i>
                    </span>
                </button>
            );
        },
        LastPageLink: (options) => {
            return (
                <button
                    type="button"
                    ref={el => pageRefs.current[pageRefs.current.length - 1] = el}
                    className={classNames(options.className, 'border-round')}
                    onClick={options.onClick}
                    disabled={options.disabled}
                    aria-label="끝으로"
                    tabIndex={options.disabled ? -1 : 0}
                >
                    <span className="p-3">
                        <i className="pi pi-angle-double-right"></i>
                    </span>
                </button>
            );
        },
        PageLinks: (options) => {
            const index = 2 + options.page;
            return (
                <button
                    type="button"
                   ref={el => {
                        pageRefs.current[index] = el;
                    }}
                    className={options.className}
                    onClick={options.onClick}
                    aria-label={options.page + 1}
                >
                    {options.page + 1}
                </button>
            );
        },
    };

    // 페이징 키보드 이벤트
    const pageRefs = useRef([]);
    pageRefs.current = Array(2 + Math.ceil(totalCnt / row)).fill(null);
    

    const handleKeyDown = (event) => {
        const visibleRefs = pageRefs.current.filter(ref => ref !== null);
        const currentFocusIndex = visibleRefs.findIndex(ref => ref === document.activeElement);
        
        if (currentFocusIndex === -1) return; // If no current element, do nothing

        if (event.key === 'ArrowDown') {
            event.preventDefault();
            let nextIndex = (currentFocusIndex + 1) % visibleRefs.length;
            visibleRefs[nextIndex].focus();
        } else if (event.key === 'ArrowUp') {
            event.preventDefault();
            let prevIndex = (currentFocusIndex - 1 + visibleRefs.length) % visibleRefs.length;
            visibleRefs[prevIndex].focus();
        }
    };


    const touchStartX = useRef(0);
    const touchEndX = useRef(0);
    const handleTouchStart = (event) => {
        touchStartX.current = event.touches[0].clientX;
    };

    const handleTouchEnd = (event) => {
        touchEndX.current = event.changedTouches[0].clientX;
        const swipeThreshold = 50; // Minimum swipe distance in pixels
        const visibleRefs = pageRefs.current.filter(ref => ref !== null);
        const currentFocusIndex = visibleRefs.findIndex(ref => ref === document.activeElement);

        if (currentFocusIndex === -1) return; // If no current element, do nothing

        if (touchStartX.current - touchEndX.current > swipeThreshold) {
            // Swipe left (Move focus to the next button)
            const nextIndex = (currentFocusIndex + 1) % visibleRefs.length;
            visibleRefs[nextIndex].focus();
        } else if (touchEndX.current - touchStartX.current > swipeThreshold) {
            // Swipe right (Move focus to the previous button)
            const prevIndex = (currentFocusIndex - 1 + visibleRefs.length) % visibleRefs.length;
            visibleRefs[prevIndex].focus();
        }
    };


    return (
         <div>
            <Layout title="출석현황" pagename="출석현황">
                <div className="contain attendanceStatus">
                    <BreadCrumb model={items} home={home} role="navigation" aria-label="브레드크럼 내비게이션">
                        {items.map((item, index) => (
                        <span key={index} className={item.className} aria-current={index===items.length - 1 ? 'page' :
                            null}>
                            <Link to={item.url}>
                            {item.label}
                            </Link>
                        </span>
                        ))}
                    </BreadCrumb>

                    <div className="flex flex-wrap gap-3">
                
                            <h3 className="hidden">프로필</h3>
                            <div className='profileBox'>
                                <div className='profile'>
                                    <div className='imgbox'>
                                        <div className='img'>
                                            <img src={ profileImgSrc || profile_imgDefult } alt="프로필 사진" />
                                        </div>
                                        <input ref={fileInputRef} type="file" accept="image/*" className='hidden'
                                            onChange={handleFileChange} aria-label="프로필 이미지 업로드" />
                                        <button className='uploadbtn' type='button' aria-label='이미지 업로드' onClick={handleClick} tabIndex="0"><i className='pi pi-upload'></i></button>
                            
                                    </div>
                                    <div className="info" role="region" aria-label={tuteeInfo.name+`정보`}>
                                        <b>{tuteeInfo.name}</b>
                                        <p>소속그룹: {tuteeInfo.affiliationname}</p>
                                        <p>수업: {tuteeInfo.lecturetype}</p>
                                        {/*<p>담당 강사: {tuteeInfo.tutorname}</p>*/}
                                    </div>
                                    <div className='ttendance_rate' role="region" aria-label="출석률 정보">
                                        <div className='mb-2'>
                                            <p>나의 평균 출석률</p>
                                            <b>{tuteeInfo.attendance_rate}%</b>
                                        </div>
                                        <div>
                                            <p>소속 그룹 평균 출석률</p>
                                            <b>{tuteeInfo.affiliation_attendance_rate}%</b>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    

                        <div className='contBox pt-5'>
                            <h3 className="hidden">출석 현황 리스트</h3>
                            <div className='filterBox'>
                                <div>
                                    <label htmlFor="classRound" className=" block mb-2">수강회차</label>
                                    <select name="round" id="classRound" className="customSel" onChange={(e) => onRoundChange(e.target.value)} aria-label="수강회차 선택">
                                        {roundlist.map((option, index) => (
                                            <option key={index} value={option.value}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div>
                                    <label htmlFor="search" className="block mb-2">검색</label>
                                    <InputText id="search" 
                                        placeholder="검색어를 입력해주세요" 
                                        aria-label='검색어 입력' 
                                        name="searchtext" 
                                        value={formData.searchtext} 
                                        onChange={handleChange} 
                                        onKeyPress={handleKeyPress} 
                                        className="border"/>
                                    <Button type="button" onClick={submitSearch} label="검색" className="searchbtn ml-2" aria-label={`${formData.searchtext}검색하기`}/>
                                </div>
                            </div>

                            { courseConfirm && (
                                <div className='mt-3'>
                                    <ReactToPrint
                                        trigger={() => (
                                            <Button label="수강확인서 발급" severity="danger" outlined aria-label="수강확인서 발급 하기" onClick={() => handlePrintClick}/>
                                        )}
                                        content={() => componentRef.current}
                                        pageStyle="@page { size: A4; ratio:60%; }"
                                    />
                                </div>
                            )}

                            <div className="tableBox" tabIndex="1">
                                <div className="showtxt" role="region" aria-label="수강 및 출결 정보">
                                    <p>수강기간 : {attendanceInfo.startdate} - {attendanceInfo.enddate}</p>
                                    <p>출석 <b>{attendanceInfo.join_count}</b>회 / 결석 <b>{attendanceInfo.absent_count}</b>회, 예약대기 <b>{attendanceInfo.wait_count}</b>회</p>
                                </div>
                                
                                {/* pc */}
                                <DataTable
                                    className="listpc"
                                    value={tableData}
                                    emptyMessage="데이터가 없습니다" // No results found 메시지 커스터마이즈
                                    rows={5}
                                    tableStyle={{ minWidth: '50rem' }}
                                    aria-label="출석현황 목록"
                                 >
                                    <Column field="num" header="No" bodyStyle={{ textAlign: 'center' }} ></Column>
                                    <Column field="datefulltxt" header="강의날짜" bodyStyle={{ textAlign: 'center' }} ></Column>
                                    <Column field="weektxt" header="요일" bodyStyle={{ textAlign: 'center' }} ></Column>
                                    <Column field="tutorname" header="강사" bodyStyle={{ textAlign: 'center' }} ></Column>
                                    <Column field="starttxt" header="시작시간" bodyStyle={{ textAlign: 'center' }} ></Column>
                                    <Column field="endtxt" header="종료시간" bodyStyle={{ textAlign: 'center' }} ></Column>
                                    <Column field="booktitle" header="교재" bodyStyle={{ textAlign: 'center' }} ></Column>
                                    <Column field="roundtxt" header="차시" bodyStyle={{ textAlign: 'center' }} ></Column>
                                    <Column field="attendance" header="출석여부" body={attendanceTemplate} bodyStyle={{ textAlign: 'center' }} ></Column>
                                    <Column field="reason" header="사유" body={reasonTemplate} bodyStyle={{ textAlign: 'center' }}></Column>
                                </DataTable>

                                {/* mob */}
                                <div className="listmob">
                                    
                                    <table className="table" role="table">
                                        <thead className="screen-reader-only">
                                            <tr>
                                                <th scope="col">번호</th>
                                                <th scope="col">강의날짜</th>
                                                <th scope="col">강의 시작시간</th>
                                                <th scope="col">강의 종료시간</th>
                                                <th scope="col">강사</th>
                                                <th scope="col">교재</th>
                                                <th scope="col">출석여부</th>
                                                <th scope="col">결석사유</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tableData.map((rowData, index) => (
                                                <tr key={index}>
                                                    <td className='mb-2'>
                                                         <b className="order">{rowData.num}</b>
                                                    </td>
                                                    <td>
                                                          {`${rowData.datefulltxt}(${rowData.weektxt})`}
                                                    </td>
                                                     <td className='inline-block'>
                                                        {`${rowData.starttxt}`}&nbsp;
                                                    </td>
                                                    <td className='inline-block'>
                                                        &nbsp;{`${rowData.endtxt}`}
                                                    </td>
                                                    <td>
                                                        {rowData.tutorname}
                                                    </td>
                                                   
                                                    <td className='mt-2'>
                                                        <button type="button" className="bookBtn book"
                                                            aria-label={`${rowData.textbook} 교재`}>
                                                            {rowData.textbook}
                                                        </button>
                                                    </td>
                                                   <td className="flex align-items-center btns">
                                                        <span tabIndex="0" className={rowData.attendance==='출석'
                                                            ? 'attendance' : 'text-muted' }>
                                                            {rowData.attendance}
                                                        </span>
                                                    </td>
                                                <td>
            
                                                    <div className="mt-3">
                                                        {rowData.attendance === '결석' && (
                                                        <>
                                                            <span>결석사유</span>&nbsp;
                                                            <span role="button" className="attendance" onClick={()=>
                                                                setVisible(true)}
                                                                >
                                                                {rowData.write}
                                                                {rowData.reason === null && (
                                                                <i className="pi pi-pencil ml-1"
                                                                    aria-label="사유 작성하기 버튼"></i>
                                                                )}
                                                            </span>
                                                        </>
                                                        )}
                                                    </div>
                                                </td>
                                                
                                            </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>

                                <Paginator
                                role="navigation"
                                aria-label="페이지 탐색"
                                first={(activePage.current - 1) * row}
                                rows={row}
                                totalRecords={totalCnt}
                                onPageChange={onPageChange}
                                template={template1}
                                tabIndex="0"
                                onKeyDown={handleKeyDown}
                                onTouchStart={handleTouchStart}
                                onTouchEnd={handleTouchEnd}
                                />
                                
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>

            {/* 교재 alert */}
            <ConfirmDialog draggable={false} style={{ width: '30vw' }} breakpoints={{ '1100px': '75vw', '960px': '80vw' }} className='bookDia' />
            
            {/* 사유 입력 모달 */}
            <Dialog
                className='reasonModal'
                draggable={false}
                role="dialog"
                header="결석 사유"
                visible={visible}
                style={{ width: '30vw' }}
                breakpoints={{ '1100px': '75vw', '960px': '80vw' }}
                onHide={() => setVisible(false)}
                focusOnShow={true} // 다이얼로그가 열렸을 때 포커스가 이동하도록 함
                modal={true} // 모달 다이얼로그로 설정하여 다이얼로그 외부를 클릭했을 때 닫히지 않도록 함
                aria-modal="true" // 스크린 리더가 모달 상태를 인식할 수 있도록 함
            >
                <div className='mt-3 mb-2 textbox'>
                     <label htmlFor="reason" className="p-d-block mb-1 hidden">결석 사유를 입력하세요:</label>
                    <InputTextarea id='reason' value={reason} onChange={(e)=> setReason(e.target.value)} rows={5} cols={30}
                        placeholder='텍스트를 입력하세요.'/>
                </div>

                <div className="flex align-items-center gap-2 justify-content-center">
                    <Button label="등록" severity="previewbtn" onClick={() => saveReason()} aria-label="사유 등록 버튼"/>
                    <Button label="취소" severity="cancbtn" onClick={() => setVisible(false)} aria-label="사유 등록 취소 버튼"/>
                </div>
            </Dialog>

            {/* 수업 만족도 설문 안내 모달 */}
            <SurveyDialog draggable={false} visible={dialogVisible} onHide={handleHideDialog} researchid={researchid} />

            {/* 프린트할 컴포넌트 */}
            <div style={{ display: 'none' }}>
                <ConfirmationContent ref={componentRef} data={confirmData} />
            </div>
        </div>
    );
}

export default AttendanceStatus;