/* /lms/learner/bookListDetail.jsx */
import React, { useState, useEffect, useRef } from "react";
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { SelectButton } from 'primereact/selectbutton';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Checkbox } from 'primereact/checkbox';
import { Paginator } from 'primereact/paginator';
import { classNames } from 'primereact/utils';

import Layout from '../../../components/lms/layout/layout';
import { Link } from "react-router-dom";

import axios from 'axios';

import config from '../../../config';
const apiUrl = config.apiUrl;

var dataList = [];

// 타이틀
export function useTitle(initialTitle) {
    const [title, setTitle] = useState(initialTitle);
    const updateTitle = () => {
        const htmlTitle = document.querySelector("title");
        htmlTitle.innerText = title;
    };

    useEffect(updateTitle, [title]);
    return setTitle;
};

const BookListDetail = () => {

    useTitle("교재 선택");

    //브러드크럼
    const items = [ { label: '교재', url: '/BookList'  }, { label: '교재선택', className: 'nowpage' }, ];
    const home = { icon: 'pi pi-home', url: '/learner/dashboard' }

    const accessToken = useSelector(state => state.auth.accessToken);

    const { id } = useParams(); // URL 매개변수에서 ID 가져오기

    const [totalCnt, setTotalCnt] = useState(0);
    const activePage = useRef(1);
    const [row, setRowData] = useState(5);
    const [tableData, setTableData] = useState([...dataList]);

    const [formData, setFormData] = useState({
        searchtext: '',
    });

    // 교재타입
    const options = ['한국어교재', '외국어교재'];
    const [value, setValue] = useState(options[0]);

    const [selectedItems, setSelectedItems] = useState([]);

    // 교재 리스트 가져오기
    const getBooks = async () => {
        var curriculumid = id;
        var searchtext = formData.searchtext;

        try {
            const response = await axios.get(apiUrl+'/book/tutorbooklist?searchtext='+searchtext+'&lectureid=&curriculumid='+curriculumid+'&page='+activePage.current+'&rows='+row+'&sort=&exectype=', {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            var dataArr = [];
            if(res.success){
                var resData = response.data.data;
                //console.log(resData);
                if(resData){
                    for(var i = 0; i < resData.length; i++){
                        var info = resData[i];
                        info.selected = false;
                        dataArr.push(info);
                    }
                }

                setTotalCnt(res.total);
            }else{
                setTotalCnt(0);
            }
            dataList = dataArr;
            
            setTableData(dataList);
        
        } catch (error) {
           console.error('Error:', error);
        }
    };

    // rows 변경 시
    useEffect(() => {
        if (activePage.current === 1) {
            getBooks();
        }else{
            activePage.current = 1;
            getBooks();
        }
    }, [row]);

    // 검색어 입력 시
    const handleChange = (e) => {
        const { name, value } = e.target;
        
        setFormData({
            ...formData,
            [name]: value
        });
    };

    // 검색 기능
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            submitSearch();
          }
    };

    // 검색
    function submitSearch(){
        activePage.current = 1;
        getBooks();
    };

    // 페이지 변경 시
    const onPageChange = (event) => {
        activePage.current = event.page + 1;
        getBooks();
    };

    // 체크박스 
    const handleCheckboxChange = (rowData) => {
        const updatedBooks = tableData.map(book => {
            if (book === rowData) {
                return { ...book, selected: !book.selected };
            }
            return book;
        });
        setTableData(updatedBooks);
    };

    // 전체 항목 다운로드
    const downloadAll = () => {
        /*
        for(var i = 0; i < tableData.length; i++){
            var book = tableData[i];
            downloadBook(book.fileurl, book.originfile);
        }
        */
        downloadSequentially();
    };

    // 비동기로 파일을 순차적으로 다운로드하는 함수
    const downloadSequentially = async () => {
        for (let i = 0; i < tableData.length; i++) {
            const book = tableData[i];
            await new Promise((resolve) => {
                downloadBook(book.fileurl, book.originfile);
                setTimeout(resolve, 1000); // 각 다운로드 사이에 지연을 추가하여 브라우저가 처리할 시간을 줍니다.
            });
        }
    };

    // 선택한 항목 다운로드
    const downloadSelected = () => {
        const selectedCount = tableData.filter(book => book.selected).length;
        var selectedBooks = tableData.filter(book => book.selected);

        if(selectedCount == 0){
            alert("다운로드 받을 항목을 선택해주세요.");
            return false;
        }else if(selectedCount == 1){
            var selectedBook = selectedBooks[0];
            downloadBook(selectedBook.fileurl, selectedBook.originfile);
        }else{
            // 여러개 파일 다운로드
            for(var i = 0; i < selectedBooks.length; i++){
                var book = selectedBooks[i];
                downloadBook(book.fileurl, book.originfile);
            }
        }
    };

    // 교재 다운로드
    const downloadBook = async (fileurl, originfile) => {
        try {
            const response = await fetch(fileurl);
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
    
            const a = document.createElement('a');
            a.href = url;
            a.download = originfile; // 원하는 파일 이름을 지정
            document.body.appendChild(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url); // 메모리 해제
        } catch (error) {
            console.error('Download failed:', error);
        }
    };

    // 페이징 접근성 변경 템플릿
    const template1 = {
        layout: 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink',
        FirstPageLink: (options) => {
            return (
                <button
                    type="button"
                    ref={el => pageRefs.current[0] = el}
                    className={classNames(options.className, 'border-round')}
                    onClick={options.onClick}
                    disabled={options.disabled}
                    aria-label="처음으로"
                    tabIndex={options.disabled ? -1 : 0}
                >
                    <span className="p-3">
                        <i className="pi pi-angle-double-left"></i>
                    </span>
                </button>
            );
        },
        PrevPageLink: (options) => {
            return (
                <button
                    type="button"
                    ref={el => pageRefs.current[1] = el}
                    className={classNames(options.className, 'border-round')}
                    onClick={options.onClick}
                    disabled={options.disabled}
                    aria-label="이전"
                    tabIndex={options.disabled ? -1 : 0}
                >
                    <span className="p-3">
                        <i className="pi pi-angle-left"></i>
                    </span>
                </button>
            );
        },
        NextPageLink: (options) => {
            return (
                <button
                    type="button"
                    ref={el => pageRefs.current[pageRefs.current.length - 2] = el}
                    className={classNames(options.className, 'border-round')}
                    onClick={options.onClick}
                    disabled={options.disabled}
                    aria-label="다음"
                    tabIndex={options.disabled ? -1 : 0}
                >
                    <span className="p-3">
                        <i className="pi pi-angle-right"></i>
                    </span>
                </button>
            );
        },
        LastPageLink: (options) => {
            return (
                <button
                    type="button"
                    ref={el => pageRefs.current[pageRefs.current.length - 1] = el}
                    className={classNames(options.className, 'border-round')}
                    onClick={options.onClick}
                    disabled={options.disabled}
                    aria-label="끝으로"
                    tabIndex={options.disabled ? -1 : 0}
                >
                    <span className="p-3">
                        <i className="pi pi-angle-double-right"></i>
                    </span>
                </button>
            );
        },
        PageLinks: (options) => {
            const index = 2 + options.page;
            return (
                <button
                    type="button"
                   ref={el => {
                        pageRefs.current[index] = el;
                    }}
                    className={options.className}
                    onClick={options.onClick}
                    aria-label={options.page + 1}
                >
                    {options.page + 1}
                </button>
            );
        },
    };

    // 페이징 키보드 이벤트
    const pageRefs = useRef([]);
    pageRefs.current = Array(2 + Math.ceil(totalCnt / row)).fill(null);
    

    const handleKeyDown = (event) => {
        const visibleRefs = pageRefs.current.filter(ref => ref !== null);
        const currentFocusIndex = visibleRefs.findIndex(ref => ref === document.activeElement);
        
        if (currentFocusIndex === -1) return; // If no current element, do nothing

        if (event.key === 'ArrowDown') {
            event.preventDefault();
            let nextIndex = (currentFocusIndex + 1) % visibleRefs.length;
            visibleRefs[nextIndex].focus();
        } else if (event.key === 'ArrowUp') {
            event.preventDefault();
            let prevIndex = (currentFocusIndex - 1 + visibleRefs.length) % visibleRefs.length;
            visibleRefs[prevIndex].focus();
        }
    };


    const touchStartX = useRef(0);
    const touchEndX = useRef(0);
    const handleTouchStart = (event) => {
        touchStartX.current = event.touches[0].clientX;
    };

    const handleTouchEnd = (event) => {
        touchEndX.current = event.changedTouches[0].clientX;
        const swipeThreshold = 50; // Minimum swipe distance in pixels
        const visibleRefs = pageRefs.current.filter(ref => ref !== null);
        const currentFocusIndex = visibleRefs.findIndex(ref => ref === document.activeElement);

        if (currentFocusIndex === -1) return; // If no current element, do nothing

        if (touchStartX.current - touchEndX.current > swipeThreshold) {
            // Swipe left (Move focus to the next button)
            const nextIndex = (currentFocusIndex + 1) % visibleRefs.length;
            visibleRefs[nextIndex].focus();
        } else if (touchEndX.current - touchStartX.current > swipeThreshold) {
            // Swipe right (Move focus to the previous button)
            const prevIndex = (currentFocusIndex - 1 + visibleRefs.length) % visibleRefs.length;
            visibleRefs[prevIndex].focus();
        }
    };




    return (
        <div>
            <Layout title="교재선택" pagename="교재선택">
                <div className="contain">

                    <BreadCrumb model={items} home={home} role="navigation" aria-label="브레드크럼 내비게이션">
                        {items.map((item, index) => (
                            <span key={index} className={item.className} aria-current={index===items.length - 1 ? 'page' :
                                null}>
                                <Link to={item.url}>
                                    {item.label}
                                </Link>
                            </span>
                        ))}
                    </BreadCrumb>

                    <div className="fullBox">
                        <h3 className="hidden">교재 상세</h3>
                        <div className='filterBox'>
                            {/* 
                            <div>
                                <label htmlFor="dating" className=" block mb-2">교재 타입</label>
                                <SelectButton value={value} onChange={(e)=> setValue(e.value)} options={options} aria-label="교재 타입 선택"/>
                            </div>
                            */}
                            <div>
                                <label htmlFor="search" className="block mb-2">검색</label>
                                <InputText id="search" 
                                    placeholder="검색어를 입력해주세요" 
                                    aria-label='검색어 입력' 
                                    name="searchtext" 
                                    value={formData.searchtext} 
                                    onChange={handleChange} 
                                    onKeyPress={handleKeyPress} 
                                    className="border"/>
                                <Button type="button" onClick={submitSearch} label="검색" className="searchbtn ml-2" aria-label={`${formData.searchtext}검색하기`}/>
                            </div>
                        </div>
                        <div className="flex flex-wrap gap-3 mt-5 justify-content-between">
                            <Link to="/BookList" className='p-button p-component p-button-secondary'aria-label="이전 페이지로 이동하기 버튼">
                                <i className='pi pi-arrow-left mr-2'></i>뒤로가기
                            </Link>
                            <div className="flex flex-wrap gap-3">
                                 <Button label="선택 항목 다운로드" onClick={downloadSelected} severity="downloadbtn" outlined aria-label="선택한 행만 다운로드 버튼"/>
                                <Button label="전체 항목 다운로드" onClick={downloadAll} severity="danger" outlined aria-label="모든 항목 다운로드 버튼" />
                            </div>
                        </div>
                      
                        <div className="tableBox">
                            {/* pc */}
                            <DataTable
                                className="listpc"
                                value={tableData}
                                emptyMessage="데이터가 없습니다" // No results found 메시지 커스터마이즈
                                rows={5}
                                tableStyle={{ minWidth: '50rem' }}
                                aria-label="교재 목록 테이블"
                            >
                                <Column body={(rowData)=> (
                                    <Checkbox inputId={`checkbox-${rowData.originfile}`} checked={rowData.selected}
                                        onChange={()=> handleCheckboxChange(rowData)} />
                                        )}
                                ></Column>
                                <Column field="lecturetype" header="구분" bodyStyle={{ textAlign: 'center' }} aria-label="구분">
                                </Column>
                                <Column field="originfile" header="교재" bodyStyle={{ textAlign: 'center' }} aria-label="교재">
                                </Column>
                            </DataTable>

                            {/* mob */}
                            <div className="listmob mt-5">
                                <table className="table" role="table">
                                    <thead className="screen-reader-only">
                                         <tr>
                                             <th scope="col">선택박스</th>
                                             <th scope="col">교재 구분</th>
                                             <th scope="col">교재명</th>
                                         </tr>
                                     </thead>
                                    <tbody>
                                        {tableData.map((rowData, index) => (
                                        <tr key={index}>
                                            <td>
                                                <div className="top flex-row align-items-center">
                                                    <input type="checkbox" id={`checkbox-${rowData.originfile}`}
                                                        checked={rowData.selected} onChange={()=>
                                                    handleCheckboxChange(rowData)}
                                                    aria-label={`${rowData.originfile} 선택`}
                                                    />
                                                    <b className='order'>{rowData.lecturetype}</b>
                                                    <span className="book">{rowData.originfile}</span>
                                                </div>
                                            </td>
                                        </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>


                            

                            <Paginator
                                role="navigation"
                                aria-label="페이지 탐색"
                                first={(activePage.current - 1) * row}
                                rows={row}
                                totalRecords={totalCnt}
                                onPageChange={onPageChange}
                                template={template1}
                                tabIndex="0"
                                onKeyDown={handleKeyDown}
                                onTouchStart={handleTouchStart}
                                onTouchEnd={handleTouchEnd}
                            />

                        </div>
                    </div>
                </div>
            </Layout>
        </div>
    );
    
};
export default BookListDetail;