import { Cookies } from 'react-cookie';
const cookies = new Cookies();

export const setCookie = (name, value) => {
    if(value){
        return cookies.set(name, value, { path: '/'}); 
    }else{
        return cookies.remove(name, { path: '/'});
    }
}

export const loginSuccess = (accessToken, usertype) => {
    setCookie('lmsweb_accessToken', accessToken);
    return {
        type: 'LOGIN_SUCCESS',
        accessToken,
        usertype
    };
};
  
export const logout = () => {
    //document.cookie = "lmswebtutor_accessToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"
    setCookie('lmsweb_accessToken');
    return {
        type: 'LOGOUT'
    };
};

export const setAuthenticated = (isAuthenticated) => {
    return {
        type: 'SET_AUTHENTICATED',
        isAuthenticated: isAuthenticated
    };
};
  