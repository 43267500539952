/* resetpw.jsx */
import React, { useState, useRef, useEffect } from "react";
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import '../../assets/css/account.css';
import { InputText } from "primereact/inputtext";
import { Message } from 'primereact/message';

import axios from 'axios';

import config from '../../config';
const apiUrl = config.apiUrl;

// 타이틀
export function useTitle(initialTitle) {
    const [title, setTitle] = useState(initialTitle);
    const updateTitle = () => {
        const htmlTitle = document.querySelector("title");
        htmlTitle.innerText = title;
    };

    useEffect(updateTitle, [title]);
    return setTitle;
};

function Resetpw() {

    useTitle("비밀번호 변경");

    const navigate = useNavigate();
    const accessToken = useSelector(state => state.auth.accessToken);
    const { resetPasswordtoken } = useParams(); // URL 매개변수에서 가져오기

    const [userid, setUserid] = useState(''); // 아이디 입력값
    const [newpassword, setNewpassword] = useState(''); // 새 비밀번호 입력값
    const [newpasswordcheck, setNewpasswordCheck] = useState(''); // 새 비밀번호 확인 입력값
    const [errorMessage, setErrorMessage] = useState(''); // 에러 메시지

    const useridInputRef = useRef(null); 
    const newpasswordInputRef = useRef(null); 
    const newpasswordChkInputRef = useRef(null); 

    const handleSubmit = (e) => { // 로그인 유효성
        e.preventDefault();
        var isValid = true;

        if (!userid.trim()) { // 아이디 필드가 비었을 때
            setErrorMessage('아이디를 입력해주세요.'); 
            useridInputRef.current.focus(); // 아이디 입력 필드에 focus
            isValid = false;
            return; 
        }
        if (!newpassword.trim()) { // 새 비밀번호 필드가 비었을 때
            setErrorMessage('새로운 비밀번호를 입력해주세요.'); 
            newpasswordInputRef.current.focus(); // 비밀번호 입력 필드에 focus
            isValid = false;
            return; 
        }
        var isPasswordValid = validatePassword();
        if(!isPasswordValid.isValid){
            setErrorMessage(isPasswordValid.message);
            isValid = false;
            return;
        }

        if (!newpasswordcheck.trim()) { // 새 비밀번호 필드가 비었을 때
            setErrorMessage('새로운 비밀번호를 한번 더 입력해주세요.'); 
            newpasswordChkInputRef.current.focus(); // 비밀번호 입력 필드에 focus
            isValid = false;
            return; 
        }
        if (newpassword !== newpasswordcheck) { // 비밀번호와 비밀번호 확인이 일치하지 않을 때
            setErrorMessage('비밀번호가 일치하지 않습니다.');
            newpasswordChkInputRef.current.focus(); // 비밀번호 확인 입력 필드에 focus
            isValid = false;
            return;
        }
        
        if(isValid){
            resetPassword();
        }
    }

    // 비밀번호 유효성 검사
    const validatePassword  = () => {
        // 정규 표현식 패턴: 대문자, 소문자, 숫자의 조합, 10~16자리
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{10,16}$/;
      
        if (regex.test(newpassword)) {
            return { isValid: true, message: "유효한 비밀번호입니다." };
        } else {
            return { isValid: false, message: "비밀번호는 알파벳 대문자, 소문자, 숫자를 포함하여 10~16자리여야 합니다." };
        }
    }

    // 비밀번호 변경
    const resetPassword = async () => {

        var params = {
            loginid: userid,
            password: newpassword,
        };
        //console.log(params);

        try {
            const response = await axios.post(apiUrl+'/auth/resetPassword/'+resetPasswordtoken, params, {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            if(res.success){
                alert("비밀번호 변경되었습니다. 다시 로그인 해주세요.");
                navigate("/login");
            }else{
                alert("비밀번호 변경 실패하였습니다. 다시 시도해주세요.");
                const timer = setTimeout(() => {
                    window.close();
                }, 3000);
        
                // 컴포넌트 언마운트 시 타이머 정리
                return () => clearTimeout(timer);
            }
        
        } catch (error) {
           console.error('Error:', error);
        }
    };

    return (
        
        <div className="accountBox resetpw">
            <div className="card mb-0 h-auto">
                <h4 className="text-center mb-5">비밀번호 변경</h4>
                <p className="tit">코리안앳유어도어를 이용해주셔서 감사합니다. <br /> 비밀번호를 다시 설정해주세요.</p>
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <span className="p-input-icon-left">
                            <i className="pi pi-user" />
                            <InputText
                                ref={useridInputRef}
                                id="username"
                                value={userid}
                                placeholder="아이디"
                                aria-label="아이디 입력 필드"
                                onChange={(e) => setUserid(e.target.value)} />
                        </span>

                        {/* 에러메세지 */}
                        {errorMessage === '아이디를 입력해주세요.' &&(
                            <Message severity="error" text={errorMessage} aria-live="polite" />
                        )}

                    </div>
                    <div className="mb-3">
                        <span className="p-input-icon-left">
                            <i className="pi pi-lock" />
                            <InputText
                                ref={newpasswordInputRef}
                                id="password"
                                type="password"
                                value={newpassword}
                                placeholder="새 비밀번호"
                                aria-label="새로운 비밀번호 입력 필드"
                                onChange={(e) => setNewpassword(e.target.value)} />
                        </span>
                        <small>*알파벳 대문자, 소문자, 숫자의 조합 10~16자리로 입력이 가능합니다. </small>

                        {/* 에러메세지 */}
                        {errorMessage === '새로운 비밀번호를 입력해주세요.' && (
                            <Message severity="error" text={errorMessage} aria-live="polite" />
                        )}

                        {/* 비밀번호 유효성 에러메세지 */}
                        {errorMessage === '비밀번호는 알파벳 대문자, 소문자, 숫자를 포함하여 10~16자리여야 합니다.' && (
                            <Message severity="error" text={errorMessage} aria-live="polite" />
                        )}
                    </div>

                    <div className="mb-3">
                        <span className="p-input-icon-left">
                            <i className="pi pi-lock" />
                            <InputText
                                ref={newpasswordChkInputRef}
                                id="passwordcheck"
                                type="password"
                                value={newpasswordcheck}
                                placeholder="새 비밀번호 확인"
                                aria-label="새로운 비밀번호 입력 필드"
                                onChange={(e) => setNewpasswordCheck(e.target.value)} />
                        </span>

                        {/* 에러메세지 */}
                        {errorMessage === '새로운 비밀번호를 한번 더 입력해주세요.' && (
                            <Message severity="error" text={errorMessage} aria-live="polite" />
                        )}

                        {/* 비밀번호 확인 에러메세지 */}
                        {errorMessage === '비밀번호가 일치하지 않습니다.' &&(
                            <Message severity="error" text={errorMessage} aria-live="polite" />
                        )}
                    </div>
                    
                    <div className="text-center btns">
                        <button type="submit" className="logbtn" aria-label={'비밀번호 변경하기 완료 버튼'}>변경하기</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Resetpw;
