import React from 'react';
import { Link } from 'react-router-dom';
import flogo from '../../../assets/img/footerlogo.png'; // 푸터로고 이미지
import { Accordion, AccordionTab } from 'primereact/accordion';

function footer() {
        return (
            <footer>
      <div className="container">

        {/* pc */}
        <div className='pc'>
          <div className="f_left">
            <div className="f_logo"><img src={flogo} alt="하티웍스로고" /></div>
            <div className="addr">
              <ul>
                <li>상호명 : (주)하티웍스 | 대표자명 : 김현진</li>
                <li>주소(법인) : 서울특별시 성동구 뚝섬로 1나길 5, 6층</li>
                <li>주소(사무국) : 서서울특별시 구로구 디지털로 300, 1232호</li>
                <li>사업자등록번호 : 513-86-01246 | 통신판매업신고번호 : 2019-서울성동-01542</li>
              </ul>
            </div>
          </div>
          <div className="f_right">
            <div>
              <p className="ftitle">COMPANY</p>
              <p className="txt">
                <Link to="https://www.heartyworks.com/" target="_blank">회사소개</Link>
              </p>
              <p className="txt">
                <Link to="https://blog.naver.com/koreanatyourdoor" target="_blank">블로그</Link>
              </p>
              <p className="txt">
                <Link to="https://www.notion.so/koreanatyourdoor/9777129ba4724581a8bb5fba62381419" target="_blank">언론홍보
                </Link>
              </p>
            </div>
            <div>
              <p className="ftitle">POLICIES</p>
              <p className="txt"><Link to="/terms">이용약관</Link></p>
              <p className="txt">개인정보처리방침</p>
            </div>
            <div>
              <p className="ftitle">FAQ</p>
              <p className="txt">02-6925-1203</p>
              <p className="">주중 9시~18시 <br /> (점심시간 12:30~13:30 / 주말 및 공휴일 제외)</p>
              <p className="">vanessa@heartyworks.com</p>
            </div>
          </div>
        </div>
        

        {/* mob */}
        <div className='mob'>
          <div className="f_logo"><img src={flogo} alt="하티웍스로고" /></div>
          <Accordion multiple activeIndex={[0]}>
            <AccordionTab header="COMPANY">
              <ul>
                <li>
                  <Link to="https://www.heartyworks.com/" target="_blank">회사소개</Link>
                </li>
                <li>
                  <Link to="https://blog.naver.com/koreanatyourdoor" target="_blank">블로그</Link>
                </li>
                <li>
                  <Link to="https://www.notion.so/koreanatyourdoor/9777129ba4724581a8bb5fba62381419" target="_blank">언론홍보
                  </Link>
                </li>
              </ul>
            </AccordionTab>
            <AccordionTab header="POLICIES">
              <ul>
                <li>
                  <Link to="/terms">이용약관</Link>
                </li>
                <li>
                  <Link>개인정보처리방침</Link>
                </li>
              </ul>
            </AccordionTab>
            <AccordionTab header="FAQ">
              <ul>
                <li>02-6925-1203</li>
                <li>주중 9시~18시 <br /> (점심시간 12:30~13:30 / 주말 및 공휴일 제외)</li>
                <li>vanessa@heartyworks.com</li>
              </ul>
            </AccordionTab>
          </Accordion>
          <div className="addr">
              <ul>
                <li>상호명 : (주)하티웍스 | 대표자명 : 김현진</li>
                <li>주소(법인) : 서울특별시 성동구 뚝섬로 1나길 5, 6층</li>
                <li>주소(사무국) : 서서울특별시 구로구 디지털로 300, 1232호</li>
                <li>사업자등록번호 : 513-86-01246 | 통신판매업신고번호 : 2019-서울성동-01542</li>
              </ul>
          </div>
        </div>

      </div>

    </footer>
        );

}

export default footer;