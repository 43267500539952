// site/Layout 컴포넌트

import React from 'react';
import Header from './header';
import Footer from '../../components/site/footer';
import '../../assets/css/site.css'

const Layout = ({ children, title, pagename }) => {
    return (
        <div className="site-layout">
            <Header title={title} pagename={pagename} />
            <main className='contentBox'>
                {children} 
            </main>
             <Footer  />
        </div>
    );
};

export default Layout;