import React, { useState, useEffect, useRef  } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";
import { Checkbox } from "primereact/checkbox";

import '../../../assets/css/survey.css';

import axios from 'axios';

import config from '../../../config';
const apiUrl = config.apiUrl;

// 타이틀
export function useTitle(initialTitle) {
    const [title, setTitle] = useState(initialTitle);
    const updateTitle = () => {
        const htmlTitle = document.querySelector("title");
        htmlTitle.innerText = title;
    };

    useEffect(updateTitle, [title]);
    return setTitle;
};

function Survey() {

    useTitle("설문조사");

    const accessToken = useSelector(state => state.auth.accessToken);
    const navigate = useNavigate();

    const [totalFieldset, setTotalFieldSet] = useState(1); // 총 필드셋의 수 (한 페이지당 field 5개씩)

    // 현재 fieldset 인덱스
    const [currentFieldset, setCurrentFieldset] = useState(0);

    // 각 필드의 Ref를 연결할 배열
    const fieldRefs = useRef([]);

    const prevButtonRef = useRef(null);
    const nextButtonRef = useRef(null);
    const submitButtonRef = useRef(null);

    const [id, setId] = useState(useParams()); // URL 매개변수에서 ID 가져오기

    const [formData, setFormData] = useState({
        subtitle: '',
        subcomment: '',
    });
    
    const [sections, setSections] = useState([]); 

    // 접근할 수 있는 페이지 배열
    const [accesiblePages, setAccesiblePages] = useState([]);

    const [scaleValue, setScaleValue] = useState(["1", "2", "3", "4", "5"]); //선형배율 값

    useEffect(() => {

        if(id != "" && id != null && id != undefined){
            getForm();
        }

    }, [id]);

    // 폼 데이터 가져오기
    const getForm = async () => {

        var researchid = id.id;

        try {
            const response = await axios.get(apiUrl+'/research/info/'+researchid, {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            if(res.success){
                var resData = res.data;
                //console.log(resData);
                if(resData){

                    var sectionList = resData.sections;
                    var pageOption = [];
                    if(sectionList.length > 0){
                        sectionList.forEach((_, index) => {
                            fieldRefs.current[index] = React.createRef();
                        });

                        for(var i = 0; i < sectionList.length; i++){
                            var questList = sectionList[i].quest;

                            for(var j = 0; j < questList.length; j++){
                                questList[j].num = j + 1;
                                questList[j].answer = '';
                                if(questList[j].optionobj === '복수'){
                                    questList[j].optionjson.optionValues = [];
                                }
                                questList[j].realIndex = j;
                                
                                // optionshuffle = true이면 optionjson.option 섞기
                                if(questList[j].optionshuffle){
                                    var optionList = questList[j].optionjson.option;
                                    var currentIndex = optionList.length, temporaryValue, randomIndex;
                                    while (0 !== currentIndex) {
                                        randomIndex = Math.floor(Math.random() * currentIndex);
                                        currentIndex -= 1;
                                        temporaryValue = optionList[currentIndex];
                                        optionList[currentIndex] = optionList[randomIndex];
                                        optionList[randomIndex] = temporaryValue;
                                    }
                                    questList[j].optionjson.option = optionList;
                                }
                            }

                            sectionList[i].quest = questList;
                            pageOption.push({
                                pageNo: i,
                                accesible: true
                            })
                        }
                    }
                    
                    //console.log(sectionList);
                    setSections(sectionList);
                    setTotalFieldSet(sectionList.length -1);
                    setAccesiblePages(pageOption);
                }
            }

        } catch (error) {
            console.error('Error:', error);
        }
    };

    // 입력값 변경 시
    const onInputChange = (e, index) => {
        const { value } = e.target;
        setSections(prevSections => {
            const updatedSections = [...prevSections];
            const question = updatedSections[currentFieldset].quest[index];
            updatedSections[currentFieldset].quest[index] = { ...question, answer: value };
            return updatedSections;
        });
    };

    // 객관식(단일) 라디오 변경 시
    const handleOptionChange = (e, index, optionValue) => {
        setSections(prevSections => {
            const updatedSections = [...prevSections];
            const question = updatedSections[currentFieldset].quest[index];
            updatedSections[currentFieldset].quest[index] = { ...question, answer: optionValue };
            return updatedSections;
        });
    };

    // 객관식(복수) 체크박스 변경 시
    const handleCheckboxChange = (e, index, optionValue) => {
        setSections(prevSections => {
            const updatedSections = [...prevSections];
            const question = updatedSections[currentFieldset].quest[index];
            const { optionValues } = question.optionjson;
            if(e.target.checked){
                question.optionjson.optionValues = [...optionValues, optionValue];
            }else{
                question.optionjson.optionValues = optionValues.filter(option => option !== optionValue);
            }
            updatedSections[currentFieldset].quest[index] = { ...question };
            return updatedSections;
        });
    };

    // 드롭다운 변경 시
    const handleSelectOption = (e, index, optionValue) => {
        setSections(prevSections => {
            const updatedSections = [...prevSections];
            const question = updatedSections[currentFieldset].quest[index];
            updatedSections[currentFieldset].quest[index] = { ...question, answer: optionValue };
            return updatedSections;
        });
    };

    // 선형배율 변경 시
    const handleLinearScaleChange = (e, index, optionValue) => {
        setSections(prevSections => {
            const updatedSections = [...prevSections];
            const question = updatedSections[currentFieldset].quest[index];
            updatedSections[currentFieldset].quest[index] = { ...question, answer: optionValue };
            return updatedSections;
        });
    };

    // 날짜 변경 시
    const handleDateChange = (e, index, dateValue) => {
        setSections(prevSections => {
            const updatedSections = [...prevSections];
            const question = updatedSections[currentFieldset].quest[index];
            updatedSections[currentFieldset].quest[index] = { ...question, answer: dateValue };
            return updatedSections;
        });
    };

    // 시간 변경 시
    const handleTimeChange = (e, index, timeValue) => {
        setSections(prevSections => {
            const updatedSections = [...prevSections];
            const question = updatedSections[currentFieldset].quest[index];
            updatedSections[currentFieldset].quest[index] = { ...question, answer: timeValue };
            return updatedSections;
        });
    };

    // 포커스 이동시
    const focusNextField = (index) => {
        // 필드가 마지막이면 '제출하기' 버튼에 포커스 이동
        if (index < sections[currentFieldset].quest.length - 1) {
            focusButton();
        }else{
            fieldRefs.current[index + 1].current.focus(); //다음필드에 focus
        }
    };

    // 버튼에 포커스
    const focusButton = () => {
        if (nextButtonRef.current) { //다음 버튼
            nextButtonRef.current.focus();
        } 
        else if (submitButtonRef.current) { // 제출하기 버튼
            submitButtonRef.current.focus();
        }
    };
    
    // keydown 접근성
    const handleKeyDown = (e, index, isLastField = false) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            if (isLastField) {
                // 현재 필드가 마지막 필드이면 제출 버튼에 포커스 이동
                focusButton();
            } else {
                // 아니면 다음 필드로 포커스 이동
                focusNextField(index);
            }
        }
    };

    // 다음 버튼 클릭 시
    const handleNextClick = () => {
        if (validateFields()) { // 필수입력 성공시
            var move_seq = checkMoveSection();
            if(move_seq === 999){
                resetAccesiblePages(currentFieldset, move_seq);
                setCurrentFieldset(current => current + 1);
            }else if(move_seq === -1){
                submitAnswer();
            }else{
                resetAccesiblePages(currentFieldset, move_seq);
                setCurrentFieldset(current => move_seq);
            }
        }else{
            alert("필수 입력값을 입력해주세요.");
            return false;
        }
    };

    // 이전 버튼 클릭 시
    const handlePrevClick = () => {

        var currentPage = currentFieldset;
        // currentPage에서 가장 근접한 접근 가능한 이전 페이지 찾기
        while(currentPage > 0){
            currentPage--;
            if(accesiblePages[currentPage].accesible){
                break;
            }   
        }

        setCurrentFieldset(current => currentPage); //이전페이지 이동
    };

    // 제출하기 버튼 클릭 시
    const handleSubmitClick = () => {
        if (validateFields()) { // 필수입력 성공시
            submitAnswer();
        }else{
            alert("필수 입력값을 입력해주세요.");
            return false;
        }
    };

    // 이동할 수 있는 페이지 다시 셋팅
    const resetAccesiblePages = (currentPage, move_seq) => {
        var newPages = [...accesiblePages];
        
        if(move_seq === 999){
            // currentPage이후 모든 페이지 접근 가능하게 설정
            for(var i = currentPage + 1; i < newPages.length; i++){
                newPages[i].accesible = true;
            }
        } else {
            if (currentPage + 1 == move_seq) {
                // currentPage와 move_seq가 인접한 페이지일 경우
                newPages[move_seq].accesible = true;
            } else {
                // currentPage와 move_seq 사이의 페이지는 접근 불가능하게 설정
                for (let j = currentPage + 1; j < move_seq; j++) {
                    (function (index) {
                        newPages[index].accesible = false;

                        // 해당 페이지의 모든 질문의 answer 초기화
                        setSections(prevSections => {
                            var updatedSections = [...prevSections];
                            var secQuestions = updatedSections[index].quest;
                            // secQuestions 에 있는 모든 quest의  answer 초기화
                            for (var k = 0; k < secQuestions.length; k++) {
                                secQuestions[k].answer = '';
                                if (secQuestions[k].optionobj === '복수') {
                                    secQuestions[k].optionjson.optionValues = [];
                                }
                            }
                            updatedSections[index].quest = secQuestions;
                            return updatedSections;
                        });
                    })(j);
                }
            }
        }

        setAccesiblePages(newPages);
    };

    // 필수입력 검사 함수
    const validateFields = () => {
        let isValid = false;

        isValid = sections[currentFieldset].quest.every(item => {
            if (item.required) {
                if (item.optionobj === '복수') {
                    return item.optionjson.optionValues.length > 0;
                } else {
                    return item.answer !== '';
                }
            }
            return true;
        });

        return isValid;
    };

    // 이동할 섹션 체크
    const checkMoveSection = () => {
        // movesection = true 인 경우 선택한 값에 따라 해당 섹션으로 이동
        for (const item of sections[currentFieldset].quest) {
            if (item.movesection && (item.optionobj === '객관식' || item.optionobj === '드롭다운')) {
                const selectedOption = item.optionjson.option.find(option => option.value === item.answer);
                if (selectedOption) {
                    return selectedOption.move_seq; // 해당 move_seq를 반환
                }
            }
        }

        return 999;
    };

    // 답변 제출
    const submitAnswer = async () => {
        var answers = [];
        sections.forEach((section, index) => {
            section.quest.forEach((item, idx) => {
                var answer = "";
                if(item.optionobj === '복수'){
                    answer = item.optionjson.optionValues.join(',');;
                }else{
                    answer = item.answer;
                }
                answers.push({
                    questionid: item.questionid,
                    answer: answer
                });
            });
        });

        var params = {
            question: answers
        }
        //console.log(params);

        var researchid = id.id;
        
        try {
            const response = await axios.post(apiUrl+'/research/createanswer/'+researchid, params, {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            if(res.success){
                alert("설문조사 제출하였습니다.");
                navigate('/attendanceStatus');
            }

        } catch (error) {
            console.error('Error:', error);
        }
    };

    // HTML 디코딩
    const createMarkup = () => {
        return { __html: formData.subcomment };
    };


    return (
        <div className="SurveyBox" aria-label="수업 만족도 조사">
            <div className="card">
                {/* 
                <div className="titBox">
                    <h1>{formData.subtitle}</h1>
                    <p dangerouslySetInnerHTML={createMarkup()}></p>
                </div>
                */}

                <div className="m-0">
                    <form onSubmit={(e)=> e.preventDefault()}>
                        <div className="p-fluid">
                            {/* 데이터 바인딩 작업할 시 아래 내용 참고해서 작업 해주세용!
                            
                            1.각 필드들의 Ref를 연결하고, onKeyDown 이벤트를 통해 Enter 키를 감지하여 포커스 이동 (현재 작동됨!)
                            2. 각 페이지마다 field는 5개씩 뿌려져야하고, '이전', '다음', '제출하기' 버튼도 각 맞게 보여함 (현재 작동됨!)
                            3. 다음버튼 클릭시 '필수입력' 검사 (validateFields 함수)
                            4. 각 페이지 마다 마지막 필드 입력후 enter 하면 '다음' or '제출하기' 버튼에 focus 되어야함 (현재 각 페이지마다 마지막 필드 입력후 enter 하면 다음버튼에 focus는 되어있음, 다만 제일 마지막 필드에 '제출하기' 버튼에 focus는 되어있습니다. 필수입력값 검사때문인가....? )
                            
                            */}
                            {sections.length > 0 && (
                                <fieldset>
                                    <>
                                        <div className="sec_titBox">
                                            <div className="sec_tit">{sections[currentFieldset].sectionname}</div>
                                            <div className="sec_subtit">{sections[currentFieldset].sectiondescription}</div>
                                        </div>

                                        {sections[currentFieldset].quest.map((item, index) => (
                                            
                                            <div key={index} className="field">
                                                <div className="tit">{item.num}. {item.question}
                                                    {item.required && (
                                                        <span className="essential">*</span>
                                                    )}
                                                </div>
                                                { (item.optionobj === '단답형') && ( 
                                                    <div>
                                                        <label htmlFor={'field'+item.realIndex} className="hidden">단답형 텍스트 입력</label>
                                                        <InputText
                                                            type="text"
                                                            placeholder="단답형 텍스트"
                                                            aria-required="true"
                                                            id={'field'+item.realIndex}
                                                            ref={fieldRefs.current[item.realIndex]}
                                                            value={item.answer}
                                                            onChange={(e) => onInputChange(e, item.realIndex)}
                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter') {
                                                                    e.preventDefault();
                                                                    focusNextField(item.realIndex);
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                )}

                                                { (item.optionobj === '장문형') && ( 
                                                    <div>
                                                        <label htmlFor={'field'+item.realIndex} className="hidden">장문형 텍스트 입력</label>
                                                        <InputText
                                                            type="text"
                                                            placeholder="장문형 텍스트"
                                                            aria-required="true"
                                                            id={'field'+item.realIndex}
                                                            ref={fieldRefs.current[item.realIndex]}
                                                            value={item.answer}
                                                            onChange={(e) => onInputChange(e, item.realIndex)}
                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter') {
                                                                    e.preventDefault();
                                                                    focusNextField(item.realIndex);
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                )}

                                                { (item.optionobj === '객관식') && ( 
                                                    <div className="flex flex-wrap gap-3">
                                                        {item.optionjson.option.map((option, idx) => (
                                                            <div key={idx} className="flex align-items-center">
                                                                <RadioButton
                                                                    inputId={'field-'+item.realIndex+'-'+idx}
                                                                    name={'question-'+item.realIndex}
                                                                    aria-required="true"
                                                                    value={option.value}
                                                                    ref={fieldRefs.current[item.realIndex]}
                                                                    checked={item.answer === option.value} 
                                                                    onChange={(e) => handleOptionChange(e, item.realIndex, option.value)} 
                                                                    onKeyDown={(e) => handleKeyDown(e, item.realIndex)}
                                                                />
                                                                <label htmlFor={'field-'+item.realIndex+'-'+idx}
                                                                    className="ml-2 cursor-pointer">{option.text}</label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}

                                                { (item.optionobj === '복수') && ( 
                                                    <fieldset className="flex flex-wrap gap-3">
                                                        {item.optionjson.option.map((option, idx) => (
                                                            <div key={idx} className="flex align-items-center">
                                                                <Checkbox
                                                                    inputId={'field-'+item.realIndex+'-'+idx}
                                                                    name={'question-'+item.realIndex}
                                                                    value={option.value}
                                                                    onChange={(e) => handleCheckboxChange(e, item.realIndex, e.target.value)}
                                                                    checked={item.optionjson.optionValues.includes(option.value)} aria-required="true"
                                                                    ref={fieldRefs.current[item.realIndex]}
                                                                    onKeyDown={(e) => handleKeyDown(e, item.realIndex)}
                                                                />
                                                                <label htmlFor={'field-'+item.realIndex+'-'+idx} className="ml-2 cursor-pointer">{option.text}</label>
                                                            </div>
                                                        ))}
                                                    </fieldset>
                                                )}

                                                { (item.optionobj === '드롭다운') && ( 
                                                    <div>
                                                        <select 
                                                            name={'question-'+item.realIndex}
                                                            id={'field-'+item.realIndex}
                                                            className="customSel"
                                                            ref={fieldRefs.current[index]}
                                                            value={item.answer}
                                                            onChange={(e) => handleSelectOption(e, item.realIndex, e.target.value)}
                                                            onKeyDown={(e) => handleKeyDown(e, item.realIndex, true)}
                                                        >
                                                            <option value="" disabled>선택</option>
                                                            {item.optionjson.option.map((option, idx) => (
                                                                <option key={idx} value={option.value}>{option.text}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                )}
                                                
                                                { (item.optionobj === '선형배율') && ( 
                                                    <div className="linear-scale-radio">
                                                        <label className="radio-label">{item.optionjson.mintext}</label>

                                                        {scaleValue.map((scale, idx) => (
                                                            <div key={idx} className="flex flex-column gap-3">
                                                                <label htmlFor={'field-'+item.realIndex+'-'+idx} className="radio-label">{scale}</label>
                                                                <RadioButton
                                                                    inputId={'field-'+item.realIndex+'-'+idx}
                                                                    name={'question-'+item.realIndex}
                                                                    value={scale}
                                                                    checked={item.answer === scale} 
                                                                    aria-required="true"
                                                                    ref={fieldRefs.current[item.realIndex]}
                                                                    onChange={(e)=> handleLinearScaleChange(e, item.realIndex, scale)}
                                                                    onKeyDown={(e) => handleKeyDown(e, item.realIndex)}
                                                                />
                                                            </div>
                                                        ))}

                                                        <label className="radio-label">{item.optionjson.maxtext}</label>
                                                    </div>
                                                )}
                                                
                                                { (item.optionobj === '날짜') && ( 
                                                    <div>
                                                        <label htmlFor={'field'+item.realIndex} className="hidden">날짜형 질문 선택</label>
                                                        <input
                                                            type="date"
                                                            id={'field'+item.realIndex}
                                                            value={item.answer}
                                                            className="w-full md:w-14rem"
                                                            aria-required="true"
                                                            ref={fieldRefs.current[item.realIndex]}
                                                            onChange={(e) => handleDateChange(e, item.realIndex, e.target.value)}
                                                            onKeyDown={(e) => handleKeyDown(e, item.realIndex)}
                                                        />
                                                    </div>
                                                )}

                                                { (item.optionobj === '시간') && ( 
                                                    <div>
                                                        <label htmlFor={'field'+item.realIndex} className="hidden">시간 선택</label>
                                                        <input
                                                            type="time"
                                                            id={'field'+item.realIndex}
                                                            value={item.answer}
                                                            min="13:00:00"
                                                            max="15:00:00"
                                                            aria-required="true"
                                                            ref={fieldRefs.current[item.realIndex]}
                                                            onChange={(e) => handleTimeChange(e, item.realIndex, e.target.value)} 
                                                            onKeyDown={(e) => handleKeyDown(e, item.realIndex)}
                                                        />
                                                    </div>
                                                )}
                                                
                                            </div>
                                        ))}
                                    </>                                    
                                </fieldset>
                            )}
                               
                            {/* 모든 필드 입력값이 성공했을 때 보이는 메시지 */}
                            {currentFieldset === totalFieldset && validateFields() && (    
                                <div className="endtit">감사합니다. 모든 설문에 답변하였습니다.</div>
                            )}

                        </div>
                    </form>
                </div>
                <div className="btns">
                    {/* 이전 버튼 */}
                    {currentFieldset > 0 && (
                        <Button label="이전" icon="pi pi-arrow-left"
                            onClick={handlePrevClick}
                            style={{ marginRight: '0.5em' }}
                            aria-label="이전 페이지로 이동 버튼"
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                handlePrevClick();
                                }
                            }}
                            ref={prevButtonRef}
                        />
                    )}

                    {/* 다음 버튼 */}
                    {currentFieldset < totalFieldset && (
                        <Button label="다음" severity="secondary" icon="pi pi-arrow-right" iconPos="right"
                            onClick={handleNextClick}
                            aria-label="다음 페이지로 이동 버튼"
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                handleNextClick();
                                }
                            }}
                            ref={nextButtonRef}
                        />
                    )}

                    {/* 마지막 fieldset일 때 제출하기 버튼 */}
                    {currentFieldset === totalFieldset && (
                        <Button label="제출하기"
                            severity="success"
                            icon="pi pi-check-circle"
                            iconPos="right"
                            aria-label="제줄하기 버튼"
                            onClick={handleSubmitClick}
                            onKeyDown={(e)=> {
                                if (e.key === 'Enter') { 
                                    handleSubmitClick();
                                }
                            }}
                            ref={submitButtonRef}
                        />
                    )}
                </div>
               
            </div>
        </div>
    );
}

export default Survey;
