import React, { useState, useEffect, useRef    } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import logo from '../../../assets/img/lms_logo.png'; // 상단로고 이미지
import { Link, NavLink, useLocation } from 'react-router-dom';
import { Button } from 'primereact/button';

import { logout } from '../../../store/actions/authActions';

import axios from 'axios';

import config from '../../../config';
const apiUrl = config.apiUrl;

const Header = ({ setHasSubMenu  }) => {
    
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation(); // 현재 경로

    const accessToken = useSelector(state => state.auth.accessToken);

    const [loggedIn, setLoggedIn] = useState(true);
    const [userName, setUserName] = useState('');
    //const [sidebarVisible, setSidebarVisible] = useState(false); // 모바일 sidebar
    const [visibleMenu, setVisibleMenu] = useState('');

    const subMenuRef = useRef(null); // 서브메뉴 참조


     const handleKeyDown = (event, index) => {
        const tabs = document.querySelectorAll('[role="tab"]');
        if (event.key === 'ArrowRight') {
            // Move to the next tab
            const nextIndex = (index + 1) % activeMenu.length;
            tabs[nextIndex].focus();
        } else if (event.key === 'ArrowLeft') {
            // Move to the previous tab
            const prevIndex = (index - 1 + activeMenu.length) % activeMenu.length;
            tabs[prevIndex].focus();
        } else if (event.key === 'Enter' || event.key === ' ') {
            // Activate the tab
            event.preventDefault();
            tabs[index].querySelector('a').click();
        } else if (event.key === 'ArrowDown' && activeMenu[index].items) {
            // Move focus to the first sub menu item
            event.preventDefault();
            const subMenuItems = document.querySelectorAll('.submenulist li a');
            if (subMenuItems.length > 0) {
                subMenuItems[0].focus();
            }
        }
    };

    
    const handleKeyDownMob = (event, index) => {
    const tabs = document.querySelectorAll('.sub-menu-item');
    if (event.key === 'ArrowDown') {
        // Move focus to the next sub menu item
        const nextIndex = (index + 1) % tabs.length;
        tabs[nextIndex].focus();
    } else if (event.key === 'ArrowUp') {
        // Move focus to the previous sub menu item
        const prevIndex = (index - 1 + tabs.length) % tabs.length;
        tabs[prevIndex].focus();
    } else if (event.key === 'Enter' || event.key === ' ') {
        // Activate the sub menu item
        event.preventDefault();
        tabs[index].click();
    }
};




    // 회원 정보 가져오기
    const getUserInfo = async () => {

        try {
            const response = await axios.get(apiUrl+'/users/signuser/info', {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            if(res.success){
                
               var resData = res.data;
               setUserName(resData.user.name);
               getLectureInfo(resData.user.lectureid);
            }

        } catch (error) {
            console.error('Error:', error);
        }
    };

    // 강의유형 정보 가져오기
    const getLectureInfo = async (lectureid) => {
        try {
            const response = await axios.get(apiUrl+'/lecturetype/' + lectureid, {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            if(res.success){
                var resData = res.data;
                //console.log(resData);
                setVisibleMenu(resData.disable);
            }

        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        
        getUserInfo();

    }, []);


    // 로그아웃
    const handleLogout = () => {
        dispatch(logout());
        setLoggedIn(false);
    };

    // 일정 정보 가져오기
    useEffect(() => {

        if(!loggedIn){
            //navigate("/login");
            window.location.href = "/login";
        }

    }, [loggedIn]);

    
    const [activeMenu, setActiveMenu] = useState([]);
    //const [activeMobMenu, setActiveMobMenu] = useState([]);

    const usertype = useSelector(state => state.auth.usertype);

    // 요건 강사용 메뉴
    const items = [     
        {
            label: '메인',
            icon: 'pi pi-home',
            to: "/teacher/dashboard",
            name: 'dashboard',
        },
        {
            label: '수업관리',
            icon: 'pi pi-calendar',
            to: '/ClassScheduleChk',
            name: 'ClassScheduleChk',
            items: [
                {
                    label: '수업 일정 등록',
                    to: '/ClassScheduleRegi/0',
                    name: 'ClassScheduleRegi'
                },
                {
                    label: '수업 일정 확인',
                    to: '/ClassScheduleChk',
                    name: 'ClassScheduleChk'
                },
                {
                    label: '수업 내역 확인',
                    to: '/ClassScheduleDetail',
                    name: 'ClassScheduleDetail'
                }
            ]
        },
        {
            label: '교재관리',
            icon: 'pi pi-book',
            to: "/book",
            name: 'book',
        }
    ];

    // 요건 학습자용 메뉴
    const items2 = [     
        {
            label: '메인',
            icon: 'pi pi-home',
            to: "/learner/dashboard",
            name: 'dashboard',
        },
        {
            label: '수업예약',
            icon: 'pi pi-calendar',
            to: '/classResiSchedule',
            name: 'classResiSchedule',
            items: [
                {
                    label: '수업 예약하기',
                    to: '/classResiSchedule',
                    name: 'classResiSchedule',
                },
                {
                    label: '수업 예약 내역',
                    to: '/classResiList',
                    name: 'classResiList',
                }
            ]
        },
        {
            label: '교재',
            icon: 'pi pi-book',
            to: "/bookList",
            name: 'bookList',
         },
        {
            label: '출석현황',
            icon: 'pi pi-check-circle ',
            to: "/attendanceStatus",
            name: 'attendanceStatus',
        }
    ];


    // 모바일 사이드바 강사용 메뉴
    /* const Mobitems1 = [     
        {
            label: '메인',
            icon: 'pi pi-home',
            command: () => {
                navigate('/teacher/dashboard');
                handleHideSidebar(); // 사이드바 닫기
            },
        },
        {
            label: '수업관리',
            icon: 'pi pi-calendar',
            items: [
                {
                    label: '수업 일정 등록',
                    command: () => {
                        navigate('/ClassScheduleRegi/0');
                        handleHideSidebar(); // 사이드바 닫기
                    }
                },
                {
                    label: '수업 일정 확인',
                    command: () => {
                        navigate('/ClassScheduleChk');
                        handleHideSidebar(); // 사이드바 닫기
                    }
                },
                {
                    label: '수업 내역 확인',
                    command: () => {
                        navigate('/ClassScheduleDetail');
                        handleHideSidebar(); // 사이드바 닫기
                    }
                }
            ]
        },
        {
            label: '교재관리',
            icon: 'pi pi-book',
            command: () => {
                navigate('/book');
                handleHideSidebar(); // 사이드바 닫기
            }
        }
    ];
 */
    // 모바일 사이드바 학습자용 메뉴
    /* const Mobitems2 = [     
        {
            label: '메인',
            icon: 'pi pi-home',
            command: () => {
                navigate('/learner/dashboard');
                handleHideSidebar(); // 사이드바 닫기
            }
        },
        {
            label: '수업예약',
            icon: 'pi pi-calendar',
            items: [
                {
                    label: '수업 예약하기',
                    command: () => {
                        navigate('/classResi');
                        handleHideSidebar(); // 사이드바 닫기
                    }
                },
                {
                    label: '수업 예약 내역',
                    command: () => {
                        navigate('/classResiList');
                        handleHideSidebar(); // 사이드바 닫기
                    }
                }
            ]
        },
        {
            label: '교재',
            icon: 'pi pi-book',
            command: () => {
                navigate('/bookList');
                handleHideSidebar(); // 사이드바 닫기
            }
         },
        {
            label: '출석현황',
            icon: 'pi pi-check-circle ',
            command: () => {
                navigate('/attendanceStatus');
                handleHideSidebar(); // 사이드바 닫기
            }
        }
    ]; */


    // usertype & lecturetype에 따른 메뉴 설정
    useEffect(() => {
        if(usertype){
            if(usertype == "tutor"){
                setActiveMenu(items);
                //setActiveMobMenu(Mobitems1);
            }else{
                var tuteeMenu = [];
                //var tuteeMobMenu = [];

                if(visibleMenu.includes("메인")){
                    tuteeMenu.push(items2[0]);
                    //tuteeMobMenu.push(Mobitems2[0]);
                }
                if(visibleMenu.includes("수업예약")){
                    tuteeMenu.push(items2[1]);
                    //tuteeMobMenu.push(Mobitems2[1]);
                }

                tuteeMenu.push(items2[2]);
                //tuteeMobMenu.push(Mobitems2[2]);

                if(visibleMenu.includes("출석현황")){
                    tuteeMenu.push(items2[3]);
                    //tuteeMobMenu.push(Mobitems2[3]);
                }

                setActiveMenu(tuteeMenu);
                //setActiveMobMenu(tuteeMobMenu);
            }
        }

    }, [usertype, visibleMenu]);

    const isActiveMenuItem = (menuItem) => {
        //return menuItem.to === location.pathname || (menuItem.items && menuItem.items.find(subItem => subItem.to === location.pathname));
        //return location.pathname.includes(menuItem.name) || (menuItem.items && menuItem.items.find(subItem => location.pathname.includes(subItem.name)));
        
        if(menuItem.items){
            if(location.pathname.includes('ClassSchedulePreview')){
                return true;
            }else{
                return location.pathname.includes(menuItem.name) || (menuItem.items && menuItem.items.find(subItem => location.pathname.includes(subItem.name)));
            }
        }else{
            return location.pathname.includes(menuItem.name);
        }
    };

     useEffect(() => {
        const hasSubMenu = document.querySelector('.no-items') !== null;
        setHasSubMenu(hasSubMenu);
     }, [activeMenu]);
    
    
    return (
        <div className='headerBox'>
            <header>
                {/* pc */}
                <div className="top">
                    <div className='contain'>
                        <Link to="/"><img src={logo} alt="하티웍스로고" className='logo' /></Link>
                        <ul className="tab-menu" role="tablist">
                            {/* 
                                1. role="tab"을 사용하여 탭메뉴의 탭요소 역할 부여
                                2. aria 사용하여 해당 탭의 본문과 연결
                                3. aria-seleceted="{boolen}" 탭이 선택유무 명시
                                4. 초점을 받지 못하는 li요소에 tabindex="0" 받게함 
                            */}
                            {activeMenu.map((item, index) => (
                                <li
                                    key={index}
                                    tabIndex={isActiveMenuItem(item) ? "0" : "-1"} // 활성화된 메뉴일 경우 tabIndex를 0으로 설정하여 포커스를 받을 수 있도록 함
                                    role="tab" // 탭 역할을 부여하여 접근성을 개선함
                                    aria-selected={isActiveMenuItem(item)} // 현재 선택된 메뉴임을 스크린 리더에 알림
                                >
                                    <NavLink
                                        to={item.to}
                                        className={`menu-item ${isActiveMenuItem(item) ? 'active' : ''}`}
                                        aria-selected={isActiveMenuItem(item)}
                                        aria-current={isActiveMenuItem(item) ? 'page' : null}
                                    >
                                        <i className={item.icon}></i>
                                        {item.label}
                                    </NavLink>
                             
                                       {/* {isActiveMenuItem(item) && (
                                        <ul className={`submenulist ${item.items ? 'has-items' : 'no-items'}`} >
                                            {item.items && item.items.map((subItem, subIndex) => (
                                                <li key={subIndex}>
                                                    <NavLink
                                                        to={subItem.to}
                                                        className={`sub-menu-item ${isActiveMenuItem(subItem) ? 'active' : ''}`}
                                                    >
                                                        {subItem.label}
                                                    </NavLink>
                                                </li>
                                            ))}
                                        </ul>
                                    )} */}
                                 
                                </li>
                            ))}
                        </ul>
                    
                        <div className='mylogin'>
                            <span className='myname'><i className='pi pi-user'></i>{userName}</span>
                            <Button className='logout' onClick={handleLogout} label="로그아웃"/>                           
                        </div>
                    </div>
                </div>

                {/* 모바일 */}
                <div className='bottom'>
                    <ul className="tab-menu" role="tablist">
                        {activeMenu.map((item, index) => (
                            <li
                                key={index}
                                tabIndex={isActiveMenuItem(item) ? "0" : "-1"}
                                role="tab"
                                aria-selected={isActiveMenuItem(item)}
                                onKeyDown={(e) => handleKeyDown(e, index)}
                            >
                                <NavLink
                                    to={item.to}
                                    className={`menu-item ${isActiveMenuItem(item) ? 'active' : ''}`}
                                    aria-selected={isActiveMenuItem(item)}
                                    aria-current={isActiveMenuItem(item) ? 'page' : null}
                                >
                                    <i className={item.icon}></i>
                                    {item.label}
                                </NavLink>
                            </li>
                        ))}
                    </ul>
                </div>

                {/* 서브메뉴박스 */}
                 <div className='sub-menu-container' ref={subMenuRef}>
                    {activeMenu.map((item, index) => (
                        isActiveMenuItem(item) && item.items && (
                            <ul
                                key={index} 
                                className={`submenulist ${item.items ? 'has-items' : 'no-items'}`}
                                role="group" // 메뉴 그룹 역할을 부여합니다.
                                aria-labelledby={`menu-item-${index}`} // 메뉴 항목과 그룹을 연결합니다.
                            >
                                {item.items.map((subItem, subIndex) => (
                                    <li key={subIndex}>
                                        <NavLink
                                            role="menuitem"
                                            to={subItem.to}
                                            className={`sub-menu-item ${isActiveMenuItem(subItem) ? 'active' : ''}`}
                                            aria-current={isActiveMenuItem(subItem) ? "page" : undefined}
                                            onKeyDown={(e) => handleKeyDownMob(e, subIndex)}
                                        >
                                            {subItem.label}
                                        </NavLink>
                                    </li>
                                ))}
                            </ul>
                        )
                    ))}
                </div>

            </header>

        </div>
    );
};

export default Header;
