/* join.jsx */
import React, { useState, useRef, useEffect } from "react";
import '../../assets/css/account.css';
import { Link, useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Message } from 'primereact/message';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
//import Dropdown from '../../../components/dropdownValue'; // 커스텀 접근성 드롭다운 (value값 포함)

import axios from 'axios';

import config from '../../config';
const apiUrl = config.apiUrl;

// 타이틀
export function useTitle(initialTitle) {
    const [title, setTitle] = useState(initialTitle);
    const updateTitle = () => {
        const htmlTitle = document.querySelector("title");
        htmlTitle.innerText = title;
    };

    useEffect(updateTitle, [title]);
    return setTitle;
};

var affiliations = [];

function Join() {
    
    useTitle("회원가입");

    const [username, setUsername] = useState(''); // 이름 입력값
    const [userid, setUserid] = useState(''); // 아이디 입력값
    const [useremail, setUserEmail] = useState(''); // 이메일 입력값
    const [password, setPassword] = useState(''); // 비밀번호 입력값
    const [passwordcheck, setpasswordCheck] = useState(''); // 비밀번호 입력값
    const [affiliationid, setAffiliationid] = useState(null); // 소속그룹 기업 select 
    const [errorMessage, setErrorMessage] = useState(''); // 에러 메시지
    const [confirmMessage, setConfirmMessage] = useState(''); // 본인인증 메일 발송 메세지

    const [affiliationsData, setAffiliations] = useState([...affiliations])

    const usernameInputRef = useRef(null); 
    const useridInputRef = useRef(null); 
    const useremailInputRef = useRef(null); 
    const passwordInputRef = useRef(null); 
    const passwordcheckInputRef = useRef(null); 
    const affiliationSelectRef = useRef(null);

    const navigate = useNavigate();
    const toast = useRef(null); //결과 메세지 창

    useEffect(() => {

        // 소속 리스트 가져오기
        const getAffiliations = async () => {
            try {
                const response = await axios.get(apiUrl+'/affiliation');
                //console.log(response.data.data);
                var resData = response.data.data;
                affiliations = [];
                if(resData){
                    for(var i = 0; i < resData.length; i++){
                        var info = resData[i];
                        info.label = info.name;
                        info.value = info.affiliationid;
                        affiliations.push(info);
                    }

                    setAffiliations(affiliations);
                }
            
            } catch (error) {
               console.error('Error:', error);
            }
        };

        getAffiliations();
        
    }, []);

    // 본인인증 메일 발송
    const sendVerificationEmail = async () => {
        if(useremail == ""){
            alert("이메일을 입력해주세요.");
            setErrorMessage('이메일을 입력해주세요.');
            useremailInputRef.current.focus(); // 아이디 입력 필드에 focus
            return false;
        }else{
          setErrorMessage(''); // 기존 에러 메시지 초기화
        }
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if(!regex.test(useremail)){
            alert("이메일을 올바르게 입력해주세요.");
            useremailInputRef.current.focus();
            return false;
        }

        if (!userid.trim()) { // 아이디 필드가 비었을 때
            setErrorMessage('아이디를 입력해주세요.');
            useridInputRef.current.focus(); // 아이디 입력 필드에 focus
            return false;
        }

        var params = {
            loginid: userid
        };

        try {
            const response = await axios.post(apiUrl+`/auth/sendVerificationEmail/${useremail}`, params);
            var res = response.data;
            //console.log(res);
            if(res.success){
                alert("본인인증을 위해 메일함을 확인해주세요.");
                setConfirmMessage('본인인증을 위해 메일함을 확인해주세요.');
            }else{
                alert(res.msg);
            }

        } catch (error) {
            console.error('Error:', error);
        }
    }

    const handleSubmit = async (e) => { // 회원가입 유효성
        e.preventDefault();

        if (!username.trim()) { // 이름 필드가 비었을 때
            setErrorMessage('이름을 입력해주세요.');
            usernameInputRef.current.focus(); // 아이디 입력 필드에 focus
            return false;
        }
        var isNameValid = validateName();

        if(!isNameValid.isValid){
            alert("이름은 " + isNameValid.message);
            return false;
        }

        if (!userid.trim()) { // 아이디 필드가 비었을 때
            setErrorMessage('아이디를 입력해주세요.');
            useridInputRef.current.focus(); // 아이디 입력 필드에 focus
            return false;
        }
        if (!useremail.trim()) { // 이메일 필드가 비었을 때
            setErrorMessage('이메일을 입력해주세요.');
            useremailInputRef.current.focus(); // 아이디 입력 필드에 focus
            return false;
        }

        var isEmailValid = validateEmail();

        if(!isEmailValid.isValid){
            alert(isEmailValid.message);
            return false;
        }

        if (!password.trim()) { // 비밀번호 필드가 비었을 때
            setErrorMessage('비밀번호를 입력해주세요.');
            passwordInputRef.current.focus(); // 비밀번호 입력 필드에 focus
            return false;
        }

        var isPasswordValid = validatePassword();

        if(!isPasswordValid.isValid){
            alert(isPasswordValid.message);
            return false;
        }

        if (!passwordcheck.trim()) { // 비밀번호 확인 필드가 비었을 때
            setErrorMessage('비밀번호를 한번 더 입력해주세요.');
            passwordcheckInputRef.current.focus(); // 비밀번호 확인 입력 필드에 focus
            return false;
        }
        if (password !== passwordcheck) { // 비밀번호와 비밀번호 확인이 일치하지 않을 때
            setErrorMessage('비밀번호가 일치하지 않습니다.');
            passwordcheckInputRef.current.focus(); // 비밀번호 확인 입력 필드에 focus
            return false;
        }
        if (!affiliationid) { // 소속그룹이 선택되지 않았을 때
            setErrorMessage('소속그룹 유형을 선택해주세요.');
            return false;
        }else{
            setErrorMessage('');
        }

        var params = {
            name: username,
            loginid: userid,
            email: useremail,
            password: password,
            affiliationid: affiliationid
        }

        //console.log(params);

        try {
            const response = await axios.post(apiUrl+`/auth/signup/`, params);
            var res = response.data;
            //console.log(res);
            if(res.success){
                alert("회원가입 완료되었습니다.");
                navigate("/login");
            }else{
                alert(res.msg);
            }

        } catch (error) {
            console.error('Error:', error);
        }
        
    }

    /*
    const VerificationEmail = () => {
        window.open('/verify/'+useremail, '_blank'); // verify 페이지로 새창
    };
    */
          
    // 이름 유효성 검사
    const validateName = () => {
        const koreanRegex = /^[가-힣\s]+$/;
        const englishRegex = /^[A-Za-z\s]+$/;
      
        if (koreanRegex.test(username)) {
            if (username.length <= 10) {
                return { isValid: true, message: "유효한 한글 입력입니다." };
            } else {
                return { isValid: false, message: "한글은 최대 10글자까지 입력 가능합니다." };
            }
        } else if (englishRegex.test(username)) {
            if (username.length <= 30) {
                return { isValid: true, message: "유효한 영어 입력입니다." };
            } else {
                return { isValid: false, message: "영어는 최대 30글자까지 입력 가능합니다." };
            }
        } else {
            return { isValid: false, message: "한글 또는 영어만 입력 가능합니다." };
        }
    }

    // 이메일 유효성 검사
    const validateEmail = () => {
        // 정규 표현식 패턴: 이메일 형식 확인
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      
        if (regex.test(useremail)) {
            return { isValid: true, message: "유효한 이메일 주소입니다." };
        } else {
            return { isValid: false, message: "유효하지 않은 이메일 주소입니다." };
        }
    }

    // 비밀번호 유효성 검사
    const validatePassword  = () => {
        // 정규 표현식 패턴: 대문자, 소문자, 숫자의 조합, 10~16자리 - 특수문자는 필수 아님
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d!@#$%^&*]{10,16}$/;
      
        if (regex.test(password)) {
            return { isValid: true, message: "유효한 비밀번호입니다." };
        } else {
            return { isValid: false, message: "비밀번호는 알파벳 대문자, 소문자, 숫자를 포함하여 10~16자리여야 합니다." };
        }
    }

    return (
        <div className="accountBox join">
            <div className="card mb-0 h-auto">
                <h4 className="text-center mb-4">회원가입</h4>
                <form className="joinBox" onSubmit={(e)=> e.preventDefault()}>
                    <div className="mb-3">
                        <label htmlFor="username">이름</label>
                        <InputText
                            ref={usernameInputRef}
                            id="username"
                            value={username}
                            placeholder="이름을 입력해주세요"
                            aria-label="아이디 입력 필드"
                            onChange={(e) => setUsername(e.target.value)}
                        />
                        <small>*최대 한글 10자 영문30자까지 입력가능합니다. </small>
                        {/* 에러메세지 */}
                        {errorMessage === '이름을 입력해주세요.' &&(
                            <Message severity="error" text={errorMessage} aria-live="polite" />
                        )}
                    </div>
                    <div className="mb-3">
                        <label htmlFor="userid">아이디</label>
                        <InputText
                            ref={useridInputRef}
                            id="userid"
                            placeholder="아이디를 입력해주세요."
                            aria-label="아이디 필드"
                            onChange={(e) => setUserid(e.target.value)}
                        />                    
                        {/* 에러메세지 */}
                        {errorMessage === '아이디를 입력해주세요.' &&(
                            <Message severity="error" text={errorMessage} aria-live="polite" />
                        )}
                    </div>

                    <div className="mb-3">
                        <label htmlFor="useremail">이메일</label>
                        <div className="flex">
                            <InputText ref={useremailInputRef} id="useremail" placeholder="이메일을 입력해주세요."
                                aria-label="이메일 필드" onChange={(e)=> setUserEmail(e.target.value)} />
                            <Button type="button" label="본인인증 메일 발송" onClick={sendVerificationEmail} title="이 버튼을 클릭하여 본인인증 메일을 발송하세요." />
                        </div>
                        {/* 인증메일 발송 후 */}
                        {confirmMessage === '본인인증을 위해 메일함을 확인해주세요.' &&(
                            <Message severity="info" text={confirmMessage} aria-live="polite" />
                        )}

                        {/* 에러메세지 */}
                        {errorMessage === '이메일을 입력해주세요.' &&(
                            <Message severity="error" text={errorMessage} aria-live="polite" />
                        )}
                    </div>

                    <div className="mb-3">
                        <label htmlFor="password">비밀번호</label>
                        <InputText
                            ref={passwordInputRef}
                            id="password"
                            type="password"
                            value={password}
                            placeholder="비밀번호 입력"
                            aria-label="비밀번호 입력 필드"
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <small>*알파벳 대문자, 소문자, 숫자의 조합 10~16자리로 입력이 가능합니다.</small>
                        
                        {/* 에러메세지 */}
                        {errorMessage === '비밀번호를 입력해주세요.' && (
                            <Message severity="error" text={errorMessage} aria-live="polite" />
                        )}
                    </div>
                    <div className="mb-3">
                        <label htmlFor="passwordcheck">비밀번호 확인</label>
                        <InputText
                            ref={passwordcheckInputRef}
                            id="passwordcheck"
                            type="password"
                            value={passwordcheck}
                            placeholder="비밀번호를 한번 더 입력해주세요."
                            aria-label="비밀번호 확인 필드"
                            onChange={(e) => setpasswordCheck(e.target.value)}
                        />
                        
                        {/* 에러메세지 */}
                        {errorMessage === '비밀번호를 한번 더 입력해주세요.' &&(
                            <Message severity="error" text={errorMessage} aria-live="polite" />
                        )}
                    
                        {/* 비밀번호 확인 에러메세지 */}
                        {errorMessage === '비밀번호가 일치하지 않습니다.' &&(
                            <Message severity="error" text={errorMessage} aria-live="polite" />
                        )}
                    </div>
                    <div className="mb-3 ">
                        <label htmlFor="affiliationid">소속그룹</label>
                        <div className="groupBox">
                            <select name="affiliationid" id="affiliationid" ref={affiliationSelectRef} className="customSel" onChange={(e) => setAffiliationid(e.target.value)} >
                                <option value="">소속 선택</option>
                                {affiliationsData.map((option, index) => (
                                    <option key={index} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                        </div>

                        {/* 에러메세지 */}
                        {errorMessage === '소속그룹 유형을 선택해주세요.' &&(
                            <Message severity="error" text={errorMessage} aria-live="polite" />
                        )}
                    </div>
                   
                    <div className="text-center btns mt-5">
                        <button type="submit" onClick={handleSubmit} className="logbtn" aria-label={'회원가입 버튼'}>회원가입</button>
                        <Link to="/" className="joinbtn" aria-label={'가입취소 버튼'}>가입취소</Link>
                    </div>
                </form>
            </div>

            <Toast ref={toast} role="alert"/>
        </div>
    );
}

export default Join;
