/* login.jsx */
import React, { useState, useRef, useEffect } from "react";
import '../../assets/css/account.css';
import { InputText } from "primereact/inputtext";
import { Checkbox } from 'primereact/checkbox';
import { Message } from 'primereact/message';
import { Password } from "primereact/password";
import { Link, Navigate } from "react-router-dom";

import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { loginSuccess } from '../../store/actions/authActions';

import config from '../../config';
const apiUrl = config.apiUrl;

// 타이틀
export function useTitle(initialTitle) {
    const [title, setTitle] = useState(initialTitle);
    const updateTitle = () => {
        const htmlTitle = document.querySelector("title");
        htmlTitle.innerText = title;
    };

    useEffect(updateTitle, [title]);
    return setTitle;
};


function Login() {

    useTitle("로그인");

    const dispatch = useDispatch();

    const [usertype, setUsertype] = useState('tutor');
    const [showPasswordRecovery, setShowPasswordRecovery] = useState(false);
    const [loggedIn, setLoggedIn] = useState(false);

    // 강사/학습자 체크박스 변경 시
    const handleUsertypeChange = (e) => {
        const { name, value, checked } = e.target;
        setUsertype(value);
        if(value === "tutor"){
            setShowPasswordRecovery(false); // 강사 선택 시 비밀번호 찾기 hide
        }else{
            setShowPasswordRecovery(true); // 학습자 선택 시 비밀번호 찾기 show
        }
    }

    const [userid, setUserid] = useState(''); // 아이디 입력값
    const [password, setPassword] = useState(''); // 비밀번호 입력값
    const [errorMessage, setErrorMessage] = useState(''); // 에러 메시지

    const useridInputRef = useRef(null); 
    const passwordInputRef = useRef(null); 

    useEffect(() => {

        var type = "";
        var query = window.location.search;
        if(query != "" && query != null){
            var params = new URLSearchParams(query);
            type = params.get("type");
        }

        if(type != ""){
            if(type == "tutor"){
                setUsertype("tutor");
                setShowPasswordRecovery(false);
            }else{
                setUsertype("tutee");
                setShowPasswordRecovery(true);
            }
        }
    }, []);

    // 로그인
    const handleSubmit = (e) => { // 로그인 유효성
        e.preventDefault();
        
        if (!userid.trim()) { // 아이디 필드가 비었을 때
            setErrorMessage('아이디를 입력해주세요.'); 
            useridInputRef.current.focus(); // 아이디 입력 필드에 focus
            return; 
        }
        if (!password.trim()) { // 비밀번호 필드가 비었을 때
            setErrorMessage('비밀번호를 입력해주세요.'); 
            passwordInputRef.current.focus(); // 비밀번호 입력 필드에 focus
            return; 
        }
       
        // 로그인 로직 처리
        tryLogin();
        
    }

    const tryLogin = async () => {
        try {
            if(usertype == "tutor"){
                var params = {
                    loginid: userid,
                    password: password,
                }
            }else{
                var params = {
                    loginid: userid,
                    password: password,
                    usertype: "tutee",
                }
            }
            //console.log(params)
            const response = await axios.post(apiUrl+'/auth/sign_in_with_email_password', params);
            //console.log(response.data);
            var res = response.data;
            if(res.success){
                var resData = res.data;
                var accessToken = resData.accessToken;
                dispatch(loginSuccess(accessToken, resData.usertype));
                setLoggedIn(true);
            }

        } catch (error) {
            alert("계정정보가 올바르지 않습니다.");
            console.error('Error:', error);
        }
    }; 

    if(loggedIn){
        if(usertype == "tutor"){
            return <Navigate to="/teacher/dashboard" />;
        }else{
            return <Navigate to="/learner/dashboard" />;
        }
    }

    return (
        <div className="accountBox">
            <div className="card mb-0 h-auto">
                <h4 className="text-center mb-7">로그인</h4>
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <span className="p-input-icon-left">
                            <i className="pi pi-user" />
                            <InputText
                                ref={useridInputRef}
                                id="userid"
                                value={userid}
                                placeholder="아이디 입력"
                                aria-label="아이디 입력 필드"
                                onChange={(e) => setUserid(e.target.value)} />
                        </span>

                        {/* 에러메세지 */}
                        {errorMessage === '아이디를 입력해주세요.' &&(
                            <Message severity="error" text={errorMessage} aria-live="polite" />
                        )}

                    </div>
                    <div className="mb-3">
                        <span className="p-input-icon-left">
                            <i className="pi pi-lock" />
                             <Password
                                ref={passwordInputRef}
                                id="password"
                                value={password}
                                placeholder="비밀번호 입력"
                                aria-label="비밀번호 입력 필드"
                                toggleMask // 눈 모양 아이콘 사용
                                feedback={false}
                                onChange={(e) => setPassword(e.target.value)} />
                        </span>

                        {/* 에러메세지 */}
                        {errorMessage === '비밀번호를 입력해주세요.' && (
                            <Message severity="error" text={errorMessage} aria-live="polite" />
                        )}

                    </div>
                    <div className="flex mb-7 memtype">
                        <div className="flex align-items-center mr-3">
                            <Checkbox
                                inputId="tutor"
                                name="usertype"
                                value="tutor"
                                onChange={handleUsertypeChange}
                                checked={usertype == "tutor"}
                                aria-label="강사 체크박스" />
                            <label htmlFor="tutor" className="ml-2">강사</label>
                        </div>
                        <div className="flex align-items-center">
                            <Checkbox
                                inputId="tutee"
                                name="usertype"
                                value="tutee"
                                onChange={handleUsertypeChange}
                                checked={usertype == "tutee"}
                                aria-label="학습자 체크박스" />
                            <label htmlFor="tutee" className="ml-2">학습자</label>
                        </div>
                    </div>
                    <div className="text-center btns">
                        <button type="submit" className="logbtn" aria-label={'로그인 버튼'}>로그인</button>
                        {showPasswordRecovery && (
                            <Link to="/join" className="joinbtn" aria-label={'회원가입 버튼'}>회원가입</Link>
                            )}
                    </div>
                </form>
                {showPasswordRecovery && (
                    <div className="new-account mt-3">
                        <p><Link to="/Findpw"  aria-label={'비밀번호 찾기 버튼'}><span>비밀번호 찾기</span> <i className="pi pi-angle-right"></i></Link></p>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Login;
