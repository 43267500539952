import React, { Component } from 'react';
//import { Link } from 'react-router-dom';

class leftsidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            notices: props.notices,
            showMore: false // 공지사항 더보기
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.notices !== prevState.notices) {
            return { notices: nextProps.notices };
        }
        return null;
    }

    handleMoreNoticesClick = () => {
        // 공지사항 4개 이상일 경우 스크롤 추가
        const noticeList = document.querySelector('.noticeList');
        if (noticeList) {
            noticeList.classList.toggle('scrollable'); // 공지사항 토글
        }
        this.setState(prevState => ({
            showMore: !prevState.showMore // 공지사항 더보기 토글
        }));
    };
    render(props) {
        const { notices, showMore  } = this.state;

        return (
            <div className='leftSideBox'>
                <div>
                    <h3>공지사항</h3>
                    <ul className='noticeList'>
                        {notices.map(notice => (
                            <li key={notice.num}>
                                <span onClick={() => this.props.onNoticeClick(notice)} tabIndex="0" role="button" className='notilistItem'>
                                     <span className='num'>{notice.num}</span> <span className='txt'>{notice.title}</span> <i className='pi pi-angle-right'></i>
                                </span>
                            </li>
                        ))}
                    </ul>
                    {/* 공지사항이 4개 이상인 경우에만 버튼을 보여줌 */}
                    {notices.length >= 4 && (
                        <button type="button" className='moreNotibtn' aria-label={'공지사항 더보기 버튼'} onClick={this.handleMoreNoticesClick}>{showMore ? '공지사항 접기' : '공지사항 더보기'}</button>
                    )}
                </div>
            </div>
        );
    }
}

export default leftsidebar;