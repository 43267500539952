
import React, { useState, useEffect } from 'react';
import { Chart } from 'primereact/chart';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const BarChart = ({ data }) => {
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});

    useEffect(() => {
        if (data) {
            const { xValues, yValues, percents } = data;

            const chartData = {
                labels: xValues,
                datasets: [
                    {
                        data: yValues,
                        backgroundColor: getBackgroundColor(xValues.length),
                        borderColor: getBorderColor(xValues.length),
                        borderWidth: 1
                    }
                ]
            };

            const options = {
                scales: {
                    y: {
                        beginAtZero: true,
                        ticks: {
                            stepSize: 1
                        }
                    }
                },
                plugins: {
                    legend: {
                        display: false
                    },
                    datalabels: {
                        color: '#555',
                        anchor: 'center',
                        align: 'center',
                        font: {
                            size: 16,
                            weight: 'bold'
                        },
                        formatter: (value, context) => {
                            return value + ' ('+percents[context.dataIndex]+'%)';
                        }
                    },
                    tooltip: { enabled: false }
                }
            };

            setChartData(chartData);
            setChartOptions(options);
        }
    }, [data]);

    const getBackgroundColor = (length) => {
        // Define your color logic based on the number of options
        switch (length) {
            case 2:
                return ['rgba(255, 159, 64, 0.2)', 'rgba(75, 192, 192, 0.2)'];
            case 3:
                return ['rgba(255, 159, 64, 0.2)', 'rgba(75, 192, 192, 0.2)', 'rgba(54, 162, 235, 0.2)'];
            case 4:
                return ['rgba(255, 159, 64, 0.2)', 'rgba(75, 192, 192, 0.2)', 'rgba(54, 162, 235, 0.2)', 'rgba(153, 102, 255, 0.2)'];
            case 5:
                return ['rgba(255, 159, 64, 0.2)', 'rgba(75, 192, 192, 0.2)', 'rgba(54, 162, 235, 0.2)', 'rgba(153, 102, 255, 0.2)', 'rgba(255, 102, 102, 0.2)'];
            case 6:
                return ['rgba(255, 159, 64, 0.2)', 'rgba(75, 192, 192, 0.2)', 'rgba(54, 162, 235, 0.2)', 'rgba(153, 102, 255, 0.2)', 'rgba(255, 102, 102, 0.2)', 'rgba(255, 205, 86, 0.2)'];
            default:
                return ['rgba(255, 159, 64, 0.2)', 'rgba(75, 192, 192, 0.2)', 'rgba(54, 162, 235, 0.2)', 'rgba(153, 102, 255, 0.2)', 'rgba(255, 102, 102, 0.2)', 'rgba(255, 205, 86, 0.2)'];
        }
    };

    const getBorderColor = (length) => {
        // Define your border color logic based on the number of options
        switch (length) {
            case 2:
                return ['rgb(255, 159, 64)', 'rgb(75, 192, 192)'];
            case 3:
                return ['rgb(255, 159, 64)', 'rgb(75, 192, 192)', 'rgb(54, 162, 235)'];
            case 4:
                return ['rgb(255, 159, 64)', 'rgb(75, 192, 192)', 'rgb(54, 162, 235)', 'rgb(153, 102, 255)'];
            case 5:
                return ['rgb(255, 159, 64)', 'rgb(75, 192, 192)', 'rgb(54, 162, 235)', 'rgb(153, 102, 255)', 'rgb(255, 102, 102)'];
            case 6:
                return ['rgb(255, 159, 64)', 'rgb(75, 192, 192)', 'rgb(54, 162, 235)', 'rgb(153, 102, 255)', 'rgb(255, 102, 102)', 'rgb(255, 205, 86)'];
            default:
                return ['rgb(255, 159, 64)', 'rgb(75, 192, 192)', 'rgb(54, 162, 235)', 'rgb(153, 102, 255)', 'rgb(255, 102, 102)', 'rgb(255, 205, 86)'];
        }
    };

    return (
        <Chart type="bar" data={chartData} options={chartOptions} plugins={[ChartDataLabels]} className="w-full" />
    )
}

export default BarChart;