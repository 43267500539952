import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Paginator } from 'primereact/paginator';
import { classNames } from 'primereact/utils';
import { Link } from 'react-router-dom';

//import Dropdown from '../../../../components/dropdown'; // 커스텀 접근성 드롭다운 
import Layout from '../../../../components/lms/layout/layout';
import PDFViewer from '../../../../components/lms/modal/PDFViewer';

import axios from 'axios';

import config from '../../../../config';
const apiUrl = config.apiUrl;

var lecturetypes = [];
var curriculums = [];
var dataList = [];

// 타이틀
export function useTitle(initialTitle) {
    const [title, setTitle] = useState(initialTitle);
    const updateTitle = () => {
        const htmlTitle = document.querySelector("title");
        htmlTitle.innerText = title;
    };

    useEffect(updateTitle, [title]);
    return setTitle;
};

const Book = () => {

    useTitle("교재관리");

    //브러드크럼
    const items = [ { label: '교재관리', className: 'nowpage' }, ];
    const home = { icon: 'pi pi-home', url: '/teacher/dashboard' }

    const accessToken = useSelector(state => state.auth.accessToken);

    const [totalCnt, setTotalCnt] = useState(0);
    const activePage = useRef(1);
    const [row, setRowData] = useState(5);
    const [tableData, setTableData] = useState([...dataList]);
    const [formData, setFormData] = useState({
        lectureid: '',
        curriculumid: '',
        exectype: 'pdf',
        sorttype: 'desc',
        searchtext: ''
    });

    const displayText = `총 ${totalCnt}건 ${activePage.current}페이지 ${formData.sorttype === 'desc' ? '내림차순' : '오름차순'}으로 표시중`;

    // 정렬
    const orderbylist = [
        { label: '교재명 내림차순', value: 'desc' },
        { label: '교재명 오름차순', value: 'asc' }
    ];

    // 파일형태
    const filetypelist = [
        { label: 'PDF', value: 'pdf' },
        { label: 'HWP', value: 'hwp' }
    ];

    const [lecturetypeData, setLecturetypeData] = useState([...lecturetypes])
    const [curriculumData, setCurriculumData] = useState([...curriculums])

    useEffect(() => {

        // 강의유형 리스트 가져오기
        const getLectureTypes = async () => {
            try {
                const response = await axios.get(apiUrl+'/lecturetype');
                //console.log(response.data.data);
                var resData = response.data.data;
                if(resData){
                    lecturetypes.push({label: "전체", value: "", seq: 0});
                    for(var i = 0; i < resData.length; i++){
                        var info = resData[i];
                        info.label = info.lecturetype;
                        info.value = info.lectureid;
                        lecturetypes.push(info);
                    }

                    setLecturetypeData(lecturetypes);
                }
            
            } catch (error) {
               console.error('Error:', error);
            }
        };

        // 커리큘럼 리스트 가져오기
        const getCurriculums = async () => {
            try {
                const response = await axios.get(apiUrl+'/curriculum', {
                    headers: {
                        token: accessToken
                    }
                });
                //console.log(response.data.data);
                var resData = response.data.data;
                curriculums = [];
                if(resData){
                    curriculums.push({label: "전체", value: "", seq: 0});
                    for(var i = 0; i < resData.length; i++){
                        var info = resData[i];
                        info.label = info.title;
                        info.value = info.curriculumid;
                        curriculums.push(info);
                    }

                    setCurriculumData(curriculums);
                }
            
            } catch (error) {
               console.error('Error:', error);
            }
        }; 

        getLectureTypes();

        getCurriculums();
        
    }, []);

    // 교재 리스트 가져오기
    const getBooks = async () => {
        var lectureid = formData.lectureid;
        var curriculumid = formData.curriculumid;
        var exectype = formData.exectype;
        var sorttype = formData.sorttype;
        var searchtext = formData.searchtext;
        var sortStr = "originfile," + sorttype;

        try {
            const response = await axios.get(apiUrl+'/book/tutorbooklist?searchtext='+searchtext+'&lectureid='+lectureid+'&curriculumid='+curriculumid+'&page='+activePage.current+'&rows='+row+'&sort='+sortStr+'&exectype='+exectype, {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            var dataArr = [];
            if(res.success){
                var resData = response.data.data;
                //console.log(resData);
                if(resData){
                    for(var i = 0; i < resData.length; i++){
                        var info = resData[i];
                        
                        dataArr.push(info);
                    }
                }

                setTotalCnt(res.total);
            }else{
                setTotalCnt(0);
            }
            dataList = dataArr;
            
            setTableData(dataList);
        
        } catch (error) {
           console.error('Error:', error);
        }
    };

    // rows 변경 시
    useEffect(() => {
        if (activePage.current === 1) {
            getBooks();
        }else{
            activePage.current = 1;
            getBooks();
        }
    }, [row]);

    // 검색어 입력 시
    const handleChange = (e) => {
        const { name, value } = e.target;
        
        setFormData({
            ...formData,
            [name]: value
        });
    };

    // 검색 기능
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            submitSearch();
          }
    };

    // 검색
    function submitSearch(){
        activePage.current = 1;
        getBooks();
    };

    // '교재이름' 컬럼 body 템플릿
    const bookTemplate = (rowData) => {
        return (
            <>
                <button type='button' onClick={()=>bookconfirm(rowData)} className='bookBtn' aria-label={`${rowData.originfile}교재 미리보기 버튼`}>{rowData.originfile}</button>
            </>
        );
    };

    // 교재 클릭시 나오는 안내alert
    const bookconfirm = (data) => {
        confirmDialog({
             message: (
                <div className="flex flex-column align-items-center w-full gap-3  surface-border" role="alert">
                    <i className="pi pi-exclamation-circle text-5xl text-primary-500"></i>
                    <span className='mb-3'>수업에서 제공하는 강의 자료는 수강생만 학습목적으로 이용할 수 있습니다. 저작자의 허락없이 무단 복제 및 배포 시 민/형사상 책임을 질 수 있습니다. 동의하십니까?</span>
                </div>
            ),
            header: '주의사항',
            defaultFocus: 'accept',
            acceptLabel: '확인',
            rejectLabel: '취소' ,
            accept : () => bookAccept(data.fileurl, data.originfile),
            reject
        });
    };

    const [showPDFViewer, setShowPDFViewer] = useState(false); // PDF 뷰어 모달 상태 관리
    const [pdfUrl, setPdfUrl] = useState('');
    const [pdfName, setPdfName] = useState('');

    const bookAccept = (fileurl, originfile) => {
        //확인 버튼을 눌렀을 때 
        setPdfUrl(fileurl); // 테스트pdf
        setPdfName(originfile); // 테스트pdf
        setShowPDFViewer(true); // PDF 뷰어 모달 열기
    }

    const reject = () => {
        //취소 버튼을 눌렀을 때
    }

    const handleClosePDFViewer = () => {
        setShowPDFViewer(false); // PDF 뷰어 모달 닫기
    };

    // 전체 항목 다운로드
    const downloadAll = () => {
        downloadSequentially();
    };

    // 비동기로 파일을 순차적으로 다운로드하는 함수
    const downloadSequentially = async () => {
        for (let i = 0; i < tableData.length; i++) {
            const book = tableData[i];
            await new Promise((resolve) => {
                downloadBook(book.fileurl, book.originfile);
                setTimeout(resolve, 1000); // 각 다운로드 사이에 지연을 추가하여 브라우저가 처리할 시간을 줍니다.
            });
        }
    };
    
    // '다운로드' 컬럼 body 템플릿
    const downloadTemplate = (rowData) => {
        return (
            <>
                <Button label="다운로드" onClick={() => downloadBook(rowData.fileurl, rowData.originfile)} severity="info" outlined aria-label="다운로드 버튼"/>
            </>
        );
    };
    
    // 교재 다운로드
    const downloadBook = async (fileurl, originfile) => {
        try {
            const response = await fetch(fileurl);
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
    
            const a = document.createElement('a');
            a.href = url;
            a.download = originfile; // 원하는 파일 이름을 지정
            document.body.appendChild(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url); // 메모리 해제
        } catch (error) {
            console.error('Download failed:', error);
        }
    };

    // 페이징 접근성 변경 템플릿
    const template1 = {
        layout: 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink',
        FirstPageLink: (options) => {
            return (
                <button
                    type="button"
                    ref={el => pageRefs.current[0] = el}
                    className={classNames(options.className, 'border-round')}
                    onClick={options.onClick}
                    disabled={options.disabled}
                    aria-label="처음으로"
                    tabIndex={options.disabled ? -1 : 0}
                >
                    <span className="p-3">
                        <i className="pi pi-angle-double-left"></i>
                    </span>
                </button>
            );
        },
        PrevPageLink: (options) => {
            return (
                <button
                    type="button"
                    ref={el => pageRefs.current[1] = el}
                    className={classNames(options.className, 'border-round')}
                    onClick={options.onClick}
                    disabled={options.disabled}
                    aria-label="이전"
                    tabIndex={options.disabled ? -1 : 0}
                >
                    <span className="p-3">
                        <i className="pi pi-angle-left"></i>
                    </span>
                </button>
            );
        },
        NextPageLink: (options) => {
            return (
                <button
                    type="button"
                    ref={el => pageRefs.current[pageRefs.current.length - 2] = el}
                    className={classNames(options.className, 'border-round')}
                    onClick={options.onClick}
                    disabled={options.disabled}
                    aria-label="다음"
                    tabIndex={options.disabled ? -1 : 0}
                >
                    <span className="p-3">
                        <i className="pi pi-angle-right"></i>
                    </span>
                </button>
            );
        },
        LastPageLink: (options) => {
            return (
                <button
                    type="button"
                    ref={el => pageRefs.current[pageRefs.current.length - 1] = el}
                    className={classNames(options.className, 'border-round')}
                    onClick={options.onClick}
                    disabled={options.disabled}
                    aria-label="끝으로"
                    tabIndex={options.disabled ? -1 : 0}
                >
                    <span className="p-3">
                        <i className="pi pi-angle-double-right"></i>
                    </span>
                </button>
            );
        },
        PageLinks: (options) => {
            const index = 2 + options.page;
            return (
                <button
                    type="button"
                   ref={el => {
                        pageRefs.current[index] = el;
                    }}
                    className={options.className}
                    onClick={options.onClick}
                    aria-label={options.page + 1}
                >
                    {options.page + 1}
                </button>
            );
        },
    };

    // 페이징 키보드 이벤트
    const pageRefs = useRef([]);
    pageRefs.current = Array(2 + Math.ceil(totalCnt / row)).fill(null);
    

    const handleKeyDown = (event) => {
        const visibleRefs = pageRefs.current.filter(ref => ref !== null);
        const currentFocusIndex = visibleRefs.findIndex(ref => ref === document.activeElement);
        
        if (currentFocusIndex === -1) return; // If no current element, do nothing

        if (event.key === 'ArrowDown') {
            event.preventDefault();
            let nextIndex = (currentFocusIndex + 1) % visibleRefs.length;
            visibleRefs[nextIndex].focus();
        } else if (event.key === 'ArrowUp') {
            event.preventDefault();
            let prevIndex = (currentFocusIndex - 1 + visibleRefs.length) % visibleRefs.length;
            visibleRefs[prevIndex].focus();
        }
    };


    const touchStartX = useRef(0);
    const touchEndX = useRef(0);
    const handleTouchStart = (event) => {
        touchStartX.current = event.touches[0].clientX;
    };

    const handleTouchEnd = (event) => {
        touchEndX.current = event.changedTouches[0].clientX;
        const swipeThreshold = 50; // Minimum swipe distance in pixels
        const visibleRefs = pageRefs.current.filter(ref => ref !== null);
        const currentFocusIndex = visibleRefs.findIndex(ref => ref === document.activeElement);

        if (currentFocusIndex === -1) return; // If no current element, do nothing

        if (touchStartX.current - touchEndX.current > swipeThreshold) {
            // Swipe left (Move focus to the next button)
            const nextIndex = (currentFocusIndex + 1) % visibleRefs.length;
            visibleRefs[nextIndex].focus();
        } else if (touchEndX.current - touchStartX.current > swipeThreshold) {
            // Swipe right (Move focus to the previous button)
            const prevIndex = (currentFocusIndex - 1 + visibleRefs.length) % visibleRefs.length;
            visibleRefs[prevIndex].focus();
        }
    };

    
    // 페이지 변경 시
    const onPageChange = (event) => {
        activePage.current = event.page + 1;
        getBooks();
    };

    return (
        <div>
            <Layout title="교재관리" pagename="교재관리">
                <div className="contain">
                    <BreadCrumb model={items} home={home} role="navigation" aria-label="브레드크럼 내비게이션">
                        {items.map((item, index) => (
                        <span key={index} className={item.className} aria-current={index===items.length - 1 ? 'page' :
                            null}>
                            <Link to={item.url}>
                            {item.label}
                            </Link>
                        </span>
                        ))}
                    </BreadCrumb>

                    <div className='fullBox'>
                        <div className='filterBox'>
                            <div>
                                <label htmlFor="lecType" className="block mb-2">강의 유형</label>
                                <select name="lectureid" id="lecType" className="customSel" onChange={handleChange} aria-label="강의 유형">
                                    {lecturetypeData.map((option, index) => (
                                        <option key={index} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div>
                                <label htmlFor="curriculum" className="block mb-2">커리큘럼</label>
                                <select name="curriculumid" id="curriculum" className="customSel" onChange={handleChange} aria-label="커리큘럼">
                                    {curriculumData.map((option, index) => (
                                        <option key={index} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            
                            <div>
                                <label htmlFor="sort" className=" block mb-2">정렬</label>
                                <select name="sorttype" id="sort" className="customSel" onChange={handleChange} aria-label="정렬">
                                    {orderbylist.map((option, index) => (
                                        <option key={index} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            
                            <div>
                                <label htmlFor="fileType" className="block mb-2">파일형태</label>
                                <select name="exectype" id="fileType" className="customSel" onChange={handleChange} aria-label="파일형태 선택">
                                    {filetypelist.map((option, index) => (
                                        <option key={index} value={option.value} >
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div>
                                <label htmlFor="search" className="block mb-2">검색</label>
                                <InputText id="search" 
                                    placeholder="검색어를 입력해주세요" 
                                    aria-label='검색어 입력' 
                                    name="searchtext" 
                                    value={formData.searchtext} 
                                    onChange={handleChange} 
                                    onKeyPress={handleKeyPress} 
                                    className="border"/>
                                <Button type="button" onClick={submitSearch} label="검색" className="searchbtn ml-2" aria-label={`${formData.searchtext}검색하기`}/>
                            </div>
                        </div>

                        <Button label="전체 다운로드" onClick={downloadAll} severity="downloadBtn" className=' mt-3' aria-label="전체 다운로드 버튼" />
                        
                        <div className="showtxt">
                            <p className="total">{displayText}</p>
                        </div>

                        <div className="tableBox">

                            

                            {/* pc */}
                            <DataTable
                                className="listpc"
                                value={tableData}
                                emptyMessage="데이터가 없습니다" // No results found 메시지 커스터마이즈
                                rows={5}
                                aria-label="교재 목록"
                                tableStyle={{ minWidth: '50rem' }}
                            >
                                <Column field="lecturetype" header="강의유형" bodyStyle={{ textAlign: 'center' }}></Column>
                                <Column field="curriculum_title" header="커리큘럼" bodyStyle={{ textAlign: 'center' }}></Column>
                                <Column field="originfile" header="교재이름" body={bookTemplate}
                                    bodyStyle={{ textAlign: 'center' }} />
                                <Column field="originfile" header="다운로드" body={downloadTemplate}
                                    bodyStyle={{ textAlign: 'center' }}></Column>
                            </DataTable>

                            {/* mob */}
                            <div className="listmob" >
                                {/* <ul className="table" >
                                    {tableData.map((rowData, index) => (
                                        <li className="flex relative" key={index}>
                                            <div>
                                                <div className="top flex-column align-items-baseline">
                                                    <b className='order'>{rowData.lecturetype}</b>
                                                    <b>{rowData.curriculum_title}</b>
                                                    <button type='button' onClick={() => bookconfirm(rowData)} className='bookBtn book' aria-label={`${rowData.originfile}교재 미리보기 버튼`}>{rowData.originfile}</button>
                                                </div>
                                            </div>
                                            <div className="absolute btns">
                                                <Button label="다운로드" severity="info" onClick={() => downloadBook(rowData.fileurl, rowData.originfile)} outlined aria-label="다운로드 버튼"/>
                                            </div>
                                        </li>
                                    ))}
                                </ul> */}

                                <table className="table" role="table">
                                     <thead className="screen-reader-only">
                                            <tr>
                                                <th scope="col">강의유형</th>
                                                <th scope="col">커리큘럼</th>
                                                <th scope="col">교재이름</th>
                                                <th scope="col">다운로드</th>
                                            </tr>
                                    </thead>
                                    <tbody>
                                        {tableData.map((rowData, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <div className='flex justify-content-between align-items-start'>
                                                        <b className="order">{rowData.lecturetype}</b>
                                                        <Button label="다운로드" severity="info" onClick={() => downloadBook(rowData.fileurl, rowData.originfile)} outlined aria-label="다운로드 버튼"/>
                                                    </div>
                                                  
                                                </td>
                                                <td><b>{rowData.curriculum_title}</b></td>
                                                <td className='mt-2'><button type='button' onClick={() => bookconfirm(rowData)} className='bookBtn' aria-label={`${rowData.originfile}교재 미리보기 버튼`}>{rowData.originfile}</button></td>
                                                
                                            </tr>
                                        ))}
                                    </tbody>
                                 </table>


                            </div>

                            <Paginator
                                role="navigation"
                                aria-label="페이지 탐색"
                                first={(activePage.current - 1) * row}
                                rows={row}
                                totalRecords={totalCnt}
                                onPageChange={onPageChange}
                                template={template1}
                                tabIndex="0"
                                onKeyDown={handleKeyDown}
                                onTouchStart={handleTouchStart}
                                onTouchEnd={handleTouchEnd}
                            />

                        </div>
                    </div>
                </div>
            </Layout>

            {/* 교재 alert */}
            <ConfirmDialog draggable={false} style={{ width: '30vw' }} breakpoints={{ '1100px': '75vw', '960px': '80vw' }}
                className='bookDia' />
            
            {/* PDF 뷰어 모달 */}
            <PDFViewer showModal={showPDFViewer} onCloseModal={handleClosePDFViewer} pdfUrl={pdfUrl} pdfName={pdfName} />
        </div>
    );
}

export default Book;