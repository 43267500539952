/* App.js */
import React, { useEffect } from 'react';
import { Routes, Route, Navigate } from "react-router-dom";
import { Provider, useDispatch } from 'react-redux';
import { Cookies } from 'react-cookie';
import { loginSuccess, logout } from './store/actions/authActions';

import store from './store';
import base64 from 'base-64';

//홈페이지
import Site from './pages/site/index.jsx';
import SearchResults from './pages/site/search_results.jsx';
import SearchResultsDetail from './pages/site/search_resultsDetail.jsx';

// 이용약관, 개인정보처리방침
import TermsOfUse from './components/TermsofUse.jsx';
import PrivacyPolicy from './components/PrivacyPolicy.jsx';

//로그인, 비밀번호 변경
import Login from './pages/account/login.jsx';
import Join from './pages/account/join.jsx';
import Verify from './pages/account/verify.jsx';
import Findpw from './pages/account/findpw.jsx';
import Resetpw from './pages/account/resetpw.jsx';

//LMS 사이트 /////////

// 강사용 
import Dashboard from './pages/lms/teacher/dashboard.jsx';
import ClassScheduleRegi from './pages/lms/teacher/class/ClassScheduleRegi.jsx'; //수업일정등록
import ClassSchedulePreview from './pages/lms/teacher/class/ClassSchedulePreview.jsx'; //수업일정등록확인
import ClassScheduleChk from './pages/lms/teacher/class/ClassScheduleChk.jsx'; //수업일정확인
import ClassScheduleDetail from './pages/lms/teacher/class/ClassScheduleDetail.jsx'; //수업내역확인
import Book from './pages/lms/teacher/book/book.jsx'; //교재관리

//학습자용
import DashboardLearner from './pages/lms/learner/dashboard.jsx';
import ClassResiSchedule from './pages/lms/learner/classResiSchedule.jsx'; // 수업 예약하기
import ClassResiList from './pages/lms/learner/classResiList.jsx'; // 수업 예약리스트
import BookList from './pages/lms/learner/bookList.jsx'; // 교재리스트
import BookListDetail from './pages/lms/learner/bookListDetail.jsx'; // 교재리스트 상세
import AttendanceStatus from './pages/lms/learner/attendanceStatus.jsx'; // 출석현황
import Survey from './pages/lms/learner/survey.jsx'; // 설문조사
import SurveyView from './pages/lms/learner/surveyView.jsx'; // 설문조사


//설문조사 결과(통계)
import SurveyResults from './pages/surveyCMS/SurveyResults.jsx'; // 설문조사 결과

const PrivateRoute = ({ token, usertype, component:Component}) => {
    const dispatch = useDispatch();
    var isAuthenticated = false;
    var matchUsertype = false;
    var loginUsertype = "";
    var accessToken = token;

    if(!token){
        const cookies = new Cookies();
        accessToken = cookies.get('lmsweb_accessToken');
    }

    //console.log(accessToken);

    if(accessToken){
        let payload = accessToken.substring(accessToken.indexOf('.')+1,accessToken.lastIndexOf('.'));
        let dec = base64.decode(payload);
        var decoded = JSON.parse(dec);

        var expTime = decoded.exp;
        var expireDate = new Date(expTime * 1000);
        var today = new Date();
        loginUsertype = decoded.usertype;

        //console.log(loginUsertype);
        //console.log(expireDate);
        // accessToken 유효한지 검사
        if(expireDate > today){
            /*
            dispatch(logout());
            return false;
            */
            if(usertype == loginUsertype){
                isAuthenticated = true;
                matchUsertype = true;
                dispatch(loginSuccess(accessToken, loginUsertype));
            }else{
                matchUsertype = false;
            }
        }else{
            dispatch(logout());
            window.location.href = "/login";
        }

        if(matchUsertype){
            return(
                isAuthenticated ? Component : <Navigate to="/login" {...alert("로그인이 필요합니다.")}></Navigate>
            )
        }else{
            if(loginUsertype == "tutor"){
                return (
                    <Navigate to="/teacher/dashboard" {...alert("해당 계정으로 접근할 수 없습니다.")}></Navigate>
                )
            }else{
                return (
                    <Navigate to="/learner/dashboard" {...alert("해당 계정으로 접근할 수 없습니다.")}></Navigate>
                )
            }
        }
    }else{
        return(
            isAuthenticated ? Component : <Navigate to={"/login?type="+usertype} {...alert("로그인이 필요합니다.")}></Navigate>
        )
    }
}

const CommonRoute = ({ token, component:Component}) => {
    const dispatch = useDispatch();
    var isAuthenticated = false;
    var loginUsertype = "";
    var accessToken = token;

    if(!token){
        const cookies = new Cookies();
        accessToken = cookies.get('lmsweb_accessToken');
    }

    //console.log(accessToken);

    if(accessToken){
        let payload = accessToken.substring(accessToken.indexOf('.')+1,accessToken.lastIndexOf('.'));
        let dec = base64.decode(payload);
        var decoded = JSON.parse(dec);

        var expTime = decoded.exp;
        var expireDate = new Date(expTime * 1000);
        var today = new Date();
        loginUsertype = decoded.usertype;

        // accessToken 유효한지 검사
        if(expireDate > today){
            isAuthenticated = true;
            dispatch(loginSuccess(accessToken, loginUsertype));
        }else{
            dispatch(logout());
            document.location.href = "/";
        }
    }

    return ( Component );
}

const App = () => {
    // 페이지 로드 시 쿠키에서 accessToken을 가져와서 Redux store의 인증 상태를 업데이트합니다.
    
    const cookies = new Cookies();
    const accessToken = cookies.get('lmsweb_accessToken');
    //console.log(accessToken);

    return (
        <Provider store={store}>
            <div className='App'>
                <Routes>
                    {/* 홈페이지 */}
                    <Route path="/" exact element={<CommonRoute component={<Site/>} token={accessToken} /> } />
                    <Route path="/search_results" element={<CommonRoute component={<SearchResults/>} token={accessToken} /> } />
                    <Route path="/search_resultsDetail/:id" element={<CommonRoute component={<SearchResultsDetail/>} token={accessToken} /> } />
                    <Route path="/login" element={<CommonRoute component={<Login/>} token={accessToken} /> } />
                    <Route path="/join" element={<CommonRoute component={<Join/>} token={accessToken} /> } />
                    <Route path="/verify/:email" element={<CommonRoute component={<Verify/>} token={accessToken} /> } />
                    <Route path="/findpw" element={<CommonRoute component={<Findpw/>} token={accessToken} /> } />
                    <Route path="/resetpw/:resetPasswordtoken" element={<CommonRoute component={<Resetpw />} token={accessToken} />} />
                    
                    {/* 이용약관, 개인정보처리방침 */}
                    <Route path="/terms" element={<CommonRoute component={<TermsOfUse />} token={accessToken} />} />
                    <Route path="/PrivacyPolicy" element={<CommonRoute component={<PrivacyPolicy />} token={accessToken} />} />
                    

                    {/* LMS */}
                    <Route path="/teacher/dashboard" element={<PrivateRoute component={<Dashboard/>} token={accessToken} usertype="tutor" /> } />
                    <Route path="/ClassScheduleRegi/:id" element={<PrivateRoute component={<ClassScheduleRegi/>} token={accessToken} usertype="tutor" /> } />
                    <Route path="/ClassSchedulePreview/:id" element={<PrivateRoute component={<ClassSchedulePreview/>} token={accessToken} usertype="tutor" /> } />
                    <Route path="/ClassScheduleChk" element={<PrivateRoute component={<ClassScheduleChk/>} token={accessToken} usertype="tutor" /> } />
                    <Route path="/ClassScheduleDetail" element={<PrivateRoute component={<ClassScheduleDetail/>} token={accessToken} usertype="tutor" /> } />
                    <Route path="/Book" element={<PrivateRoute component={<Book/>} token={accessToken} usertype="tutor" /> } />

                    <Route path="/learner/dashboard" element={<PrivateRoute component={<DashboardLearner/>} token={accessToken} usertype="tutee" /> } />
                    <Route path="/classResiSchedule" element={<PrivateRoute component={<ClassResiSchedule/>} token={accessToken} usertype="tutee" /> } />
                    <Route path="/classResiList" element={<PrivateRoute component={<ClassResiList/>} token={accessToken} usertype="tutee" /> } />
                    <Route path="/bookList" element={<PrivateRoute component={<BookList/>} token={accessToken} usertype="tutee" /> } />
                    <Route path="/bookListDetail/:id" element={<PrivateRoute component={<BookListDetail/>} token={accessToken} usertype="tutee" /> } />
                    <Route path="/attendanceStatus" element={<PrivateRoute component={<AttendanceStatus/>} token={accessToken} usertype="tutee" /> } />
                    <Route path="/survey/:id" element={<PrivateRoute component={<Survey/>} token={accessToken} usertype="tutee" /> } />

                    {/* CMS에서 접근 가능한 설문조사폼 */}
                    <Route path="/surveyView/:id/:accessToken" element={<CommonRoute component={<SurveyView />} token={accessToken} />} />
                    
                    {/* 설문조사 결과(통계) CMS */}
                    <Route path="/SurveyResults/:id/:accessToken" element={<CommonRoute component={<SurveyResults />} token={accessToken} />} />
                </Routes>
            </div>
        </Provider>
    );
}

export default App;