
import React, { useState, useEffect, useRef  } from "react";
import { useParams } from 'react-router-dom';
import PieChart from './piechart.jsx'; //pie차트
import BarChart from './barchart.jsx'; //bar차트
import { saveAs } from 'file-saver';

import '../../assets/css/survey.css';

import axios from 'axios';

import config from '../../config';
const apiUrl = config.apiUrl;

export function useTitle(initialTitle) {
    const [title, setTitle] = useState(initialTitle);
    const updateTitle = () => {
        const htmlTitle = document.querySelector("title");
        htmlTitle.innerText = title;
    };

    useEffect(updateTitle, [title]);
    return setTitle;
};

function SurveyResults() {

    useTitle("설문조사 결과");

    const { id, accessToken } = useParams(); // URL 매개변수에서 ID 가져오기
    const [sections, setSections] = useState([]); 

    useEffect(() => {
        getSummary();
    }, []);

    // 설문 결과 가져오기
    const getSummary = async () => {
        try {
            const response = await axios.get(apiUrl+'/research/summary/'+id, {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            if(res.success){
                var resData = res.data;
                if(resData){
                    
                    //console.log(resData);
                    var sectionList = resData.sections;
                    for(var i = 0; i < sectionList.length; i++){
                        var questList = sectionList[i].quest;
                        for(var j = 0; j < questList.length; j++){
                            if(questList[j].optionobj === '객관식' || questList[j].optionobj === '드롭다운'){
                                var yValues = [];
                                for(var k = 0; k < questList[j].yValues.length; k++){
                                    yValues.push((questList[j].yValues[k] / Number(questList[j].answercount) * 100));
                                }
                                questList[j].yValues = yValues;
                            }
                            if(questList[j].optionobj === '복수' || questList[j].optionobj === '선형배율'){
                                var percents = [];
                                var yValueTotal = 0;
                                for(var k = 0; k < questList[j].yValues.length; k++){
                                    yValueTotal += questList[j].yValues[k];
                                }
                                for(var k = 0; k < questList[j].yValues.length; k++){
                                    if(questList[j].yValues[k] == 0){
                                        percents.push(0);
                                    }else{
                                        percents.push((questList[j].yValues[k] / yValueTotal * 100).toFixed(2));
                                    }
                                }
                                questList[j].percents = percents;
                            }
                        }
                        
                    }
                    
                    setSections(sectionList);

                }
            }

        } catch (error) {
            console.error('Error:', error);
        }
    };

    // 엑셀 다운로드
    const excelDownload = async () => {
        try {
            const response = await axios.get(apiUrl+`/research/excel/${id}`, {
                headers: {
                    token: accessToken
                },
                responseType: 'blob', // 응답 타입을 Blob으로 설정
            });
            var res = response.data;
            //console.log(res);
            
            // Blob 데이터를 그대로 저장
            const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            saveAs(blob, '만족도조사 결과.xlsx');

        } catch (error) {
            console.error('Error:', error);
        }
    };

    // 출력하기
    const printPage = () => {
        window.print();
    };

    return (
        <div className="SurveyBox resultBox" aria-label="설문 결과">
            <div className="card">
                <div className="titBox">
                    <h1>설문 결과</h1>
                    <div className="flex justify-content-end">
                        <button type="button" className="p-button p-component p-button-downloadBtn" onClick={excelDownload}>Excel 다운로드</button>
                        <button type="button" className="ml-2 p-button p-component  p-button-downloadBtn2" onClick={printPage}>출력하기</button>
                    </div>
                </div>

                <div className="m-0 result" >
                    <form onSubmit={(e)=> e.preventDefault()}>
                        <div className="p-fluid">
                            {sections.length > 0 && (
                                <fieldset>
                                    {sections.map((section, sectionindex) => (
                                        <div key={sectionindex} className="sec_titBox">
                                            <div className="sec_tit">{section.sectionname}</div>
                                            
                                            {section.quest.map((item, index) => (
                                                <div key={index} className="field">
                                                    <div className="tit">
                                                        <div className="tit_box">{index + 1}. {item.question}</div>
                                                    </div>
                                                    <div className="body">
                                                        {/* 
                                                        <div className="subtit_box">설명부분</div>
                                                        */}
                                                        <small className="total">응답수 : {item.answercount}</small>
                                                        {(item.answercount === '0') ? (
                                                            <div className="no_info">
                                                                질문에 대한 응답이 없습니다.
                                                            </div>
                                                        ) : (
                                                            <>
                                                                {(item.optionobj === '단답형' || item.optionobj === '장문형' || item.optionobj === '날짜' || item.optionobj === '시간') && (
                                                                    <div>
                                                                        <ul className="surveylist">
                                                                            {item.answers.map((answer, ind) => (
                                                                                <li key={ind}>
                                                                                    {answer}
                                                                                </li>
                                                                            ))}
                                                                        </ul>
                                                                    </div>
                                                                )}
                                                                {(item.optionobj === '객관식') && (
                                                                    <div>
                                                                        <PieChart data={item} />
                                                                    </div>
                                                                )}
                                                                {(item.optionobj === '복수') && (
                                                                    <div>
                                                                        <BarChart data={item} />
                                                                    </div>
                                                                )}
                                                                {(item.optionobj === '드롭다운') && (
                                                                    <div>
                                                                        <PieChart data={item} />
                                                                    </div>
                                                                )}
                                                                {(item.optionobj === '선형배율') && (
                                                                    <div>
                                                                        <BarChart data={item}/>
                                                                    </div>
                                                                )}
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    ))}
                                </fieldset>
                            )}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default SurveyResults;
