/* /components/calendar.jsx */
import React, { useEffect, useRef  } from 'react';
import dayGridPlugin from "@fullcalendar/daygrid";
import FullCalendar from "@fullcalendar/react";
import interactionPlugin from "@fullcalendar/interaction";

/* https://fullcalendar.io/docs#toc */
const Calendar = ({ events, handleDateSelect, eventClick, selectable, calendarRef, onDateChange }) => {

   /* useEffect(() => {
        if (calendarRef.current) {
            const calendarApi = calendarRef.current.getApi();
            const startDate = calendarApi.view.currentStart; // 현재 뷰의 시작 날짜를 가져옵니다.
            const year = startDate.getFullYear(); // 연도를 가져옵니다.
            const month = startDate.getMonth() + 1; // 월을 가져오는데
            console.log("Current Year:", year);
            console.log("Current Month:", month);
        }
    }, [calendarRef]); */


    const handleEventClick = (clickInfo) => {
        //console.log(clickInfo)
        if (eventClick) {
            eventClick(clickInfo);
        }
    };

    
    const plugin = [
        dayGridPlugin, // 월간 달력 // day 그리드
        interactionPlugin
        /* 이벤트를 위한 플러그인
        일정 추가/수정 : 캘린더에 새 이벤트를 추가하거나 기존 이벤트를 수정 
        : 이벤트를 클릭하면 이벤트 정보를 수정하는 팝업이나 모달 띄움
        드래그 앤 드롭 : 마우스로 드래그하여 다른 날짜나 시간으로 이동
        리사이징 : 기간을 변경하여 이벤트의 기간을 늘이거나 줄임
        일정 클릭 이벤트
        */
    ];  

    return (
        <FullCalendar
            ref={calendarRef}
            // height={} // 높이 지정
            plugins={plugin}
            initialView="dayGridMonth" // 초기뷰 dayGridMonth or timeGridWeek
            headerToolbar={{ // 띄어쓰면 갭이 생기고, 콤마가 있으면 그룹으로 묶는 형태
                left: "title",
                right: "today prev next"
            }}
            eventBackgroundColor="#FF6067" // 이벤트 배경색 미지정시 디폴트값으로 활용 가능
            eventBorderColor="#FF6067" // 이벤트 보더색 미지정시 디폴트값으로 활용 가능
            weekends={true} // 주말 표시 여부
            dayMaxEvents={true} // 하루에 표시 될 최대 이벤트 수 true = 셀의 높이
            events={events} // 페이지별로 전달된 이벤트 데이터 사용
            select={handleDateSelect} // 페이지별로 전달된 날짜 선택 핸들러 사용
            eventClick={handleEventClick}// 이벤트 클릭시
            selectable={selectable} // 사용자의 날짜 선택 여부
            //dateClick={handleTimeClick} // 시간 클릭 이벤트에 대한 핸들러 연결
            //eventChange={eventChange} // 이벤트 drop 혹은 resize 될 때
            //editable={handleEditable} // 사용자의 수정 가능 여부 (이벤트 추가/수정, 드래그 앤 드롭 활성화)
            //selectMirror={true} // 사용자의 시간 선택시 time 표시 여부
            //navLinks={true} // 달력의 날짜 클릭시 일간 스케쥴로 이동
            //navLinkHint={"클릭시 해당 날짜로 이동합니다."} // 날짜에 호버시 힌트 문구
            //eventContent={handleEventRender} // 이벤트가 렌더링될 때 실행되는 콜백 함수
            eventsSet={function (e) { // 이벤트가 설정 되었을때 https://fullcalendar.io/docs/v4/react (headerToolbar 버튼클릭해도 동일함)
                //console.log("eventsSet");
                if (calendarRef.current) {
                    const calendarApi = calendarRef.current.getApi();
                    const startDate = calendarApi.view.currentStart; // 현재 뷰의 시작 날짜
                    const year = startDate.getFullYear(); // 연도
                    const month = startDate.getMonth() + 1; // 월
                    //console.log("Year:", year);
                    //console.log("Month:", month);
                    
                    if (onDateChange) {
                        onDateChange(year, month);
                    }
                }
            }}
            eventAdd={function () {// 추가시 로직
                //console.log("eventAdd");
            }} 
            eventDrop={function () {// 드롭시 로직
                //console.log("eventDrop");
            }} 
            eventRemove={function () {// 제거시 로직
                //console.log("eventRemove");
            }} 
        />
    );
};

export default Calendar;
