import React, { useState, useRef, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Document, Page, pdfjs } from 'react-pdf'; // https://www.npmjs.com/package/react-pdf/v/7.0.0
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

// PDF.js worker 설정
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PDFViewer = ({ showModal, onCloseModal, pdfUrl, pdfName }) => {
    const [numPages, setNumPages] = useState(null); // 총 페이지수
    const [pageDimensions, setPageDimensions] = useState({ width: 0, height: 0 });
    const modalRef = useRef(null);

    useEffect(() => {
        function handleResize() {
            const modalWidth = modalRef.current.offsetWidth;
            const modalHeight = modalRef.current.offsetHeight;
            setPageDimensions({ width: modalWidth * 0.8, height: modalHeight * 0.8 });
        }

        if (showModal) {
            handleResize();
            window.addEventListener('resize', handleResize);
        }

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [showModal]);

    function onDocumentLoadSuccess({ numPages }) {
        //console.log(pdfUrl);
        //console.log(`numPages ${numPages}`);
        setNumPages(numPages);
    }

    const downloadBook = async() => {
        try {
            const response = await fetch(pdfUrl);
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
    
            const a = document.createElement('a');
            a.href = url;
            a.download = pdfName; // 원하는 파일 이름을 지정
            document.body.appendChild(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url); // 메모리 해제
        } catch (error) {
            console.error('Download failed:', error);
        }
    };

    return (
        <Dialog
            ref={modalRef}
            role="dialog"
            draggable={false}
            header="교재 미리보기"
            visible={showModal}
            onHide={onCloseModal}
            breakpoints={{ '1100px': '75vw', '960px': '80vw' }}
            className='pdfmodal'
            focusOnShow={true} // 다이얼로그가 열렸을 때 포커스가 이동하도록 함
            modal={true} // 모달 다이얼로그로 설정하여 다이얼로그 외부를 클릭했을 때 닫히지 않도록 함
            aria-modal="true" // 스크린 리더가 모달 상태를 인식할 수 있도록 함
        >
            <div className='mt-3 mb-3 text-right pr-3'>
                <Button label="다운로드" onClick={downloadBook} severity="downloadbtn" outlined aria-label="교재 다운로드 버튼" />
            </div>
            <div className="p-grid pt-3">
                <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
                    {Array.from(new Array(numPages), (el, index) => (
                        <Page key={`page_${index + 1}`} pageNumber={index + 1} width={pageDimensions.width} height={pageDimensions.height} />
                    ))}
                </Document>
            </div>
        </Dialog>
    );
};

export default PDFViewer;
