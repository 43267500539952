/* /lms/ClassScheduleRegi.jsx */
import React, { useState, useRef, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import Layout from '../../../../components/lms/layout/layout';
import { Link } from 'react-router-dom';
import { Toast } from 'primereact/toast';
//import Dropdown from '../../../../components/dropdown'; // 커스텀 접근성 드롭다운 

import axios from 'axios';

import config from '../../../../config';
const apiUrl = config.apiUrl;

// 타이틀
export function useTitle(initialTitle) {
    const [title, setTitle] = useState(initialTitle);
    const updateTitle = () => {
        const htmlTitle = document.querySelector("title");
        htmlTitle.innerText = title;
    };

    useEffect(updateTitle, [title]);
    return setTitle;
};

const ClassScheduleRegi = () => {
    
    useTitle("수업 일정 등록");
    
    //브러드크럼
    const items = [{ label: '수업관리' }, { label: '수업 일정 등록', className: 'nowpage' }, ];
    const home = { icon: 'pi pi-home', url: '/teacher/dashboard' }
    
    const accessToken = useSelector(state => state.auth.accessToken);
    const navigate = useNavigate();
    const [id, setId] = useState(useParams()); // URL 매개변수에서 ID 가져오기

    const [formData, setFormData] = useState({
        startYear: '',
        startMonth: '',
        startDay: '',
        endYear: '',
        endMonth: '',
        endDay: '',
        duration: '30',
        holidayclass: 'open',
        selecttimes: [],
    });


    const toast = useRef(null); //결과 메세지 창

    // 시작일 - 연도 / 월 / 일
    const [startdatelist, setStartdatelist] = useState([]);
    const [startdatemonthlist, setStartdatemonthlist] = useState(['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']);
    const [startdatedaylist, setStartdatedaylist] = useState([]);
    // 종료일 - 연도 / 월 / 일
    const [enddatelist, setEnddatelist] = useState([]);
    const [enddatemonthlist, setEnddatemonthlist] = useState(['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']);
    const [enddatedaylist, setEnddatedaylist] = useState([]);

    // 요일
    const [selectedDays, setSelectedDays] = useState([false, false, false, false, false, false, false]); 
    
    // 오전/오후 선택
    const ampmlist = ['오전', '오후'];
    // 시 선택
    const hourlist = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
    // 분 선택
    const minutelist = ['00', '30'];

    // 시작 시간
    const [starttime, setStarttime] = useState({
        ampm: '',
        hour: '',
        minute: '',
    });

    // 종료 시간
    const [endtime, setEndtime] = useState({
        ampm: '',
        hour: '',
        minute: '',
    });

    // 일정 정보 가져오기
    useEffect(() => {

        if(id.id != 0 && id.id != "0"){
            getTutoring();
        }

    }, [id]);

    // 수업 정보 가져오기
    const getTutoring = async () => {

        var tutoringid = id.id;

        try {
            const response = await axios.get(apiUrl+`/tutoring/info/${tutoringid}`, {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            if(res.success){
                var resData = response.data.data;
                //console.log(resData);
                if(resData){
                    var sdate = resData.startdate_txt;
                    var startarr = sdate.split("-");
                    var edate = resData.enddate_txt;
                    var endarr = edate.split("-");

                   // 업데이트할 날짜 리스트 생성
                    const generateDayList = (month) => {
                        if (month === 2) {
                            return ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28'];
                        } else if ([1, 3, 5, 7, 8, 10, 12].includes(month)) {
                            return ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'];
                        } else {
                            return ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30'];
                        }
                    };

                    // 시작일 리스트 업데이트
                    const startMonth = Number(startarr[1]);
                    setStartdatedaylist(generateDayList(startMonth));

                    // 종료일 리스트 업데이트
                    const endMonth = Number(endarr[1]);
                    setEnddatedaylist(generateDayList(endMonth));

                    setFormData(prevData =>({
                        ...prevData ,
                        startYear: startarr[0],
                        startMonth: parseInt(startarr[1], 10).toString(),
                        startDay: parseInt(startarr[2], 10).toString(),
                        endYear: endarr[0],
                        endMonth: parseInt(endarr[1], 10).toString(),
                        endDay: parseInt(endarr[2], 10).toString(),
                        duration: resData.duration,
                        holidayclass: resData.holidayclass,
                        selecttimes: resData.selecttimes,
                    }));
                }
            }

        } catch (error) {
            console.error('Error:', error);
        }
    };

    // 날짜 select 값 변경시
    const handleDropdownChange = (event) => {

        const { name, value } = event.target;

        const updateDayList = (month) => {
            if (month == 2) {
                return ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28'];
            } else if ([1, 3, 5, 7, 8, 10, 12].includes(month)) {
                return ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'];
            } else {
                return ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30'];
            }
        };

        if (name === "startMonth" || name === "endMonth") {
            if (name === "startMonth") {
                setStartdatedaylist(updateDayList(parseInt(value, 10)));
            } else {
                setEnddatedaylist(updateDayList(parseInt(value, 10)));
            }
        }

        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    // 시간 select 값 변경 시
    const handleTimeChange = (event) => {
        const {name,value} = event.target;
        const nameArr = name.split("_");

        if (nameArr[0] === "starttime") {
            //console.log(nameArr)
            setStarttime({
                ...starttime,
                [nameArr[1]]: value
            });
        } else {
            setEndtime({
                ...endtime,
                [nameArr[1]]: value
            });
        }
    };

    // 값 변경시
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));

          let label = '';

        if (name === 'duration') {
            label = value === '30' ? '30분' : '60분';

        } else if (name === 'holidayclass') {
            label = value === 'open' ? '수업함' : '수업안함';
  
        }

         // 엔터키 처리
        if (e.key === 'Enter') {
            e.target.click(); // 라디오 버튼 클릭 시키기
        }
    };

       const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // 폼 제출 방지
            e.target.click(); // 라디오 버튼 클릭 시키기
        }
    };


    const radioRefOpen = useRef(null);
    const radioRefClose = useRef(null);
    const classopenRef = useRef(null);
    const classcloseRef = useRef(null);


    const weekdayRef = useRef(null);

    // 요일 선택
    const toggleDay = (index) => {
        const updatedDays = [...selectedDays];
        updatedDays[index] = !updatedDays[index];
        setSelectedDays(updatedDays);
    };

    const renderButtons = () => {
        const days = ['일', '월', '화', '수', '목', '금', '토'];
        return days.map((day, index) => (
            <Button
                type="button"
                key={index} 
                className={selectedDays[index] ? 'selected' : ''}
                onClick={() => toggleDay(index)}
                aria-pressed={selectedDays[index]} // 각 버튼의 토글 상태에 따라 aria-pressed 속성 설정
                aria-label={`${day} 선택`}
                ref={index === 0 ? weekdayRef : null} // 첫 번째 요일 버튼에 Ref 설정
            >
                <span className='p-button-label p-c'>{day}</span>
            </Button>
        ));
    };


    // 페이지 로딩 시
    useEffect(() => {

        // 시작일/종료일 연도 셋팅
        var today = new Date();
        var thisYear = today.getFullYear();
        var nextYear = thisYear + 1;
        var yearArr = [thisYear, nextYear];
        setStartdatelist(yearArr);
        setEnddatelist(yearArr);

    }, []);

    // 일정 선택 클릭시 나오는 안내 alert
    const AddTimelineconfirm = (timeline) => {
        // timeline 객체의 속성이 정의되어 있는지 확인
        if (timeline && timeline.weekStr && timeline.starttime && timeline.endtime) {

            const { weekStr, starttime, endtime } = timeline;

           const dialog = confirmDialog({
                message: (
                    <div role="alert" tabIndex="0">
                        선택하신 일정은 <b>{weekStr} {starttime} ~ {endtime}</b> 입니다.<br/> 선택하시겠습니까?
                    </div>
                ),
                header: '일정 선택 안내',
                defaultFocus: 'accept',
                acceptLabel: '네',
                rejectLabel: '아니오' ,
                accept: () => {
                    setTimeout(() => {
                        confirmTimeline(timeline, dialog);
                    }, 100);
                },
                reject: () => { // 아니오
                    // 모달 닫기
                    dialog.hide();
                    // 요일 선택 칸으로 포커스 이동
                    setTimeout(() => {
                        if (weekdayRef.current) {
                            //console.log('요일 선택 칸으로 이동해랏');
                            weekdayRef.current.focus();
                        }
                    }, 200); 
                }
           });
            
        } else {
            console.error('Invalid timeline object:', timeline);
        }

    };

    const radioRef30 = useRef(null);
    const radioRef60 = useRef(null);
    
    // 일정 추가 안내 alert
    const confirmTimeline = (timeline, prevDialog) => {

        prevDialog.hide(); // 이전 모달을 닫음.

        const dialog = confirmDialog({
            message: (
                <div role="alert" tabIndex="0">
                    수업 요일 및 시간을 추가로 선택하시겠습니까?
                </div>
            ),
            header: '일정 추가 안내',
            defaultFocus: 'accept',
            acceptLabel: '네',
            rejectLabel: '아니오',
            accept: () =>{ // 네
                updateTimeline(timeline)

                // 요일 선택 칸으로 포커스 이동
                setTimeout(() => {
                    if (weekdayRef.current) {
                        //console.log('요일 선택 칸으로 이동해랏');
                        weekdayRef.current.focus();
                    }
                }, 200);  
            },      
            reject: () => { // 아니오
                // 모달 닫기
                dialog.hide();
                
                updateTimeline(timeline)

                // 시간 간격 라디오 칸으로 포커스 이동
                setTimeout(() => {
                     if (radioRef30.current) {
                        radioRef30.current.focus();
                    }
                   
                }, 200);
            }
        });
    };

    // 타임라인 등록
    const addTimeline = () => {

        var result = checkTimelineData();

        if(result){
            
            const daysOfWeek = ["일", "월", "화", "수", "목", "금", "토"];

            // true인 요소의 인덱스를 찾아 해당하는 요일 이름을 가져옵니다.
            const selectedDayArr = selectedDays.reduce((acc, curr, index) => {
                if (curr) {
                    acc.push(daysOfWeek[index]);
                }
                return acc;
            }, []);

            // 가져온 요일 이름들을 하나의 문자열로 조합합니다.
            const week = selectedDayArr.join(",");
            const weekStr = selectedDayArr.join(", ");
            
            var startampm = starttime.ampm;
            var starthour = starttime.hour;
            var startminute = starttime.minute;

            if(startampm == "오후"){
                if(starthour != '12'){
                    starthour = Number(starthour) + 12;
                }
            }else{
                if(starthour == '12'){
                    starthour = Number(starthour) + 12;
                }
                starthour = starthour.toString().padStart(2, '0');
            }

            var starttimeStr = starthour + ":" + startminute;

            var endampm = endtime.ampm;
            var endhour = endtime.hour;
            var endminute = endtime.minute;

            if(endampm == "오후"){
                if(endhour != '12'){
                    endhour = Number(endhour) + 12;
                }
            }else{
                if(endhour == '12'){
                    endhour = Number(endhour) + 12;
                }
                endhour = endhour.toString().padStart(2, '0');
            }

            var endtimeStr = endhour + ":" + endminute;

            var timeline = {
                week: week,
                weekStr: weekStr,
                starttime: starttimeStr,
                endtime: endtimeStr
            };

            // AddTimelineconfirm 함수 호출 시 timeline 객체 전달
            AddTimelineconfirm(timeline);

        }
    };

    // 타임라인 행 추가 및 input/selectbox 초기화
    const updateTimeline = (timeline) => {
        var newSelecttimes = formData.selecttimes;
        newSelecttimes.push(timeline);

        setFormData({
            ...formData,
            newSelecttimes
        });

        resetTimelineData();
    };

    // 타임라인 추가 시 유효성 검사
    const checkTimelineData = () => {
        const hasTrue = selectedDays.some(item => item === true);
       
        if(!hasTrue){
            alert("요일을 선택해주세요.");
            return false;
        }
        if (starttime.ampm == "") {
            alert("시작시간 오전/오후를 선택해주세요.");
            return false;
        }
        if(starttime.hour == ""){
            alert("시작시간 시간를 선택해주세요.");
            return false;
        }
        if(starttime.minute == ""){
            alert("시작시간 분을 선택해주세요.");
            return false;
        }
        if(endtime.ampm == ""){
            alert("종료시간 오전/오후를 선택해주세요.");
            return false;
        }
        if(endtime.hour == ""){
            alert("종료시간 시간을 선택해주세요.");
            return false;
        }
        if(endtime.minute == ""){
            alert("종료시간 분을 선택해주세요.");
            return false;
        }

        // 시작시간과 종료시간 비교해서 같은 경우 혹은 시작시간이 종료시간보다 큰 경우
        var startampm = starttime.ampm;
        var starthour = Number(starttime.hour);
        var startminute = starttime.minute;
        var endampm = endtime.ampm;
        var endhour = Number(endtime.hour);
        var endminute = endtime.minute;
        if (startampm === endampm && starthour === endhour && startminute === endminute) {
            alert("시작시간과 종료시간이 동일합니다.");
            return false;
        }
        if (startampm === "오후" && endampm === "오전") {
            alert("시작시간이 종료시간보다 큽니다.");
            return false;
        }
        if(starthour != 12){
            if (startampm === endampm && starthour > endhour) {
                alert("시작시간이 종료시간보다 큽니다.");
                return false;
            }
        }
        if (startampm === endampm && starthour === endhour && startminute > endminute) {
            alert("시작시간이 종료시간보다 큽니다.");
            return false;
        }

        return true;
    };

    // 타임라인 삭제
    const deleteTimeline = (index) => {
        var newSelecttimes = formData.selecttimes;
        newSelecttimes.splice(index, 1);

        setFormData({
            ...formData,
            newSelecttimes
        });
    };

    const [resetFormSelectedOption, setResetFormSelectedOption] = useState(false); // 드롭다운 리셋 상태
    const [resetSelectedOption, setResetSelectedOption] = useState(false); // 드롭다운 리셋 상태

    // formData 데이터 초기화
    const resetFormData = () => {
        setFormData({
            ...formData,
            startYear: '',
            startMonth: '',
            startDay: '',
            endYear: '',
            endMonth: '',
            endDay: '',
            duration: '30',
            holidayclass: 'open',
            selecttimes: [],
        });

        setResetFormSelectedOption(true); // 상태를 true로 설정하여 드롭다운 초기화
        // 다시 false로 설정하여 초기화 상태를 재설정
        setTimeout(() => {
            setResetFormSelectedOption(false);
        }, 0);
    };

    // 타임라인 데이터 초기화
    const resetTimelineData = () => {
        setSelectedDays([false, false, false, false, false, false, false]);
        setStarttime({
            ...starttime,
            ampm: '',
            hour: '',
            minute: '',
        });
        setEndtime({
            ...endtime,
            ampm: '',
            hour: '',
            minute: '',
        }); 
        
        setResetSelectedOption(true); // 상태를 true로 설정하여 드롭다운 초기화
        // 다시 false로 설정하여 초기화 상태를 재설정
        setTimeout(() => {
            setResetSelectedOption(false);
        }, 0);
    };

    // 수업 등록 및 미리보기 페이지 이동
    const previewClass = async () => {

        var result = checkClassData();

        if(result){

            var sdate = formData.startYear + "-" + formData.startMonth.toString().padStart(2, '0') + "-" + formData.startDay.toString().padStart(2, '0');
            var edate = formData.endYear + "-" + formData.endMonth.toString().padStart(2, '0') + "-" + formData.endDay.toString().padStart(2, '0');

            var params = {
                startdate: sdate,
                enddate: edate,
                duration: formData.duration,
                holidayclass: formData.holidayclass,
                selecttimes: formData.selecttimes
            };

            //console.log(params);

            //return false;
            try {
                const response = await axios.post(apiUrl+'/tutoring/insert', params, {
                    headers: {
                        token: accessToken
                    }
                });
                var res = response.data;
                //console.log(res);
                if(res.success){
                    if(res.data.schedule_count == 0){
                        alert("중복 스케쥴로 생성되지 않았습니다.");
                    }else{
                        navigate('/ClassSchedulePreview/'+res.data.tutoringid);
                    }
                }
    
            } catch (error) {
                console.error('Error:', error);
            }
        }
    };

    // 수업 등록 유효성 검사
    const checkClassData = () => {
        if(formData.startYear == ""){
            alert("시작일 연도를 선택해주세요.");
            return false;
        }
        if(formData.startMonth == ""){
            alert("시작일 월을 선택해주세요.");
            return false;
        }
        if(formData.startDay == ""){
            alert("시작일 일을 선택해주세요.");
            return false;
        }
        var sdate = formData.startYear + "-" + formData.startMonth + "-" + formData.startDay;
        var startdateValid = isValidDate(sdate);
        if(!startdateValid){
            alert("시작일을 올바르게 선택해주세요.");
            return false;
        }
        var today = new Date();
        sdate = new Date(sdate);
        if(sdate < today){
            alert("시작일을 오늘 이후 날짜로 선택해주세요.");
            return false;
        }

        if(formData.endYear == ""){
            alert("종료일 연도를 선택해주세요.");
            return false;
        }
        if(formData.endMonth == ""){
            alert("종료일 월을 선택해주세요.");
            return false;
        }
        if(formData.endDay == ""){
            alert("종료일 일을 선택해주세요.");
            return false;
        }
        var edate = formData.endYear + "-" + formData.endMonth + "-" + formData.endDay;
        var enddateValid = isValidDate(edate);
        if(!enddateValid){
            alert("종료일을 올바르게 선택해주세요.");
            return false;
        }
        edate = new Date(edate);
        if(edate < sdate){
            alert("종료일을 시작일 이후 날짜로 선택해주세요.");
            return false;
        }
        if(formData.selecttimes.length == 0){
            alert("시간을 추가해주세요.")
            return false;
        }

        return true;
    };

    // 수업 등록 취소하기
    const cancelClass = async () => {
        var tutoringid = id.id;
        
        if(tutoringid != 0 && tutoringid != "0"){
            try {
                const response = await axios.delete(apiUrl+`/tutoring/deleteSchedule?scheduleid=&tutoringid=${tutoringid}`, {
                    headers: {
                        token: accessToken
                    }
                });
                var res = response.data;
                //console.log(res);
                if(res.success){
                    toast.current.show({ severity: 'success',  detail: '취소 처리 되었습니다.', life: 3000 }); //완료메세지
                    navigate("/ClassScheduleRegi/0");
                    setId({
                        ...id,
                        id: '0',
                    });
                }

            } catch (error) {
                console.error('Error:', error);
            }
        }else{
            resetFormData();
            resetTimelineData();
        }
    };

    // 올바른 날짜인지 확인하기
    function isValidDate(dateString) {
        // yyyy-mm-dd 형식의 문자열을 날짜 객체로 변환
        var dateParts = dateString.split("-");
        var year = parseInt(dateParts[0], 10);
        var month = parseInt(dateParts[1], 10) - 1; // 월은 0부터 시작하므로 1을 빼줌
        var day = parseInt(dateParts[2], 10);
        
        // Date 객체 생성
        var date = new Date(year, month, day);
        
        // 날짜 유효성 확인
        return date.getFullYear() == year && date.getMonth() == month && date.getDate() == day;
    }

    // 선택 가능한 연도 (시작일)
    const isStartYearDisabled = (year) => {
        const today = new Date();
        const currentYear = today.getFullYear();
        const isDisabled = year === '' || year < currentYear;

        // console.log(`Start Year ${year} is disabled: ${isDisabled}`);

        return isDisabled;
    };

    // 선택 가능한 월 (시작일)
    const isStartMonthDisabled = (year, month) => {
        const today = new Date();
        const currentYear = today.getFullYear();
        const currentMonth = today.getMonth() + 1; 

        if (year === '' || year < currentYear) { // 연도가 비어있거나 현재 연도보다 이전이면 모든 월 비활성화
            return true; 
        } else if (year > currentYear) { // 선택된 연도가 현재 연도보다 크면 모든 월 활성화
            return false; 
        } else { // 선택된 연도가 현재 연도인 경우, 현재 월보다 이전 월 비활성화
            return parseInt(month, 10) < currentMonth;
        }
    };

    // 선택 가능한 일자 (시작일)
    const isStartDayDisabled = (year, month, day) => {
        const today = new Date();
        const selectedDate = new Date(year, month - 1, day); 

        return (
            year === '' || month === '' || day === '' ||
            selectedDate < today
        );
    };

    // 선택 가능한 연도 (종료일)
    const isEndYearDisabled = (year, month, day, startYear, startMonth, startDay) => {
        const selectedStartDate = new Date(startYear, startMonth - 1, startDay); 

        if (year === '' || month === '' || day === '') {
            return true; // 연도, 월, 일 중 하나라도 비어 있으면 비활성화
        }

        const selectedEndDate = new Date(year, month - 1, day);

        // 종료일이 시작일보다 이전이거나, 같은 연도와 월이지만 시작일보다 이전인 경우 비활성화
        if (selectedEndDate < selectedStartDate ||
            (year === startYear && month === startMonth && day < startDay)) {
            return true;
        } else {
            return false;
        }
    };

    // 선택 가능한 월 (종료일)
    const isEndMonthDisabled = (month,startYear, startMonth, startDay, endYear) => {
        const selectedStartDate = new Date(startYear, startMonth - 1, startDay);
        const selectedEndDate = new Date(endYear, month, 1); 

        if (selectedEndDate < selectedStartDate) {
            return true;
        }

        return false;   
    };

    // 선택 가능한 일자를 결정하는 함수 (종료일)
    const isEndDayDisabled = (day, startYear, startMonth, startDay, endYear, endMonth) => {
        const selectedStartDate = new Date(startYear, startMonth - 1, startDay);
        const selectedEndDate = new Date(endYear, endMonth - 1, day - 1);

        if (selectedEndDate < selectedStartDate) {
            return true;
        }

        return false;
   
    };


    return (
        <div>
            <Layout title="수업 일정 등록" pagename="수업 일정 등록">
                <div className="contain" tabIndex="-1">
                    
                    <BreadCrumb model={items} home={home} role="navigation" aria-label="브레드크럼 내비게이션">
                        {items.map((item, index) => (
                        <span key={index} className={item.className} aria-current={index===items.length - 1 ? 'page' :
                            null}>
                            <Link to={item.url}>
                            {item.label}
                            </Link>
                        </span>
                        ))}
                    </BreadCrumb>

                    <div className='fullBox'>
                        <h3 className='visually-hidden'>수업 일정 등록</h3>
                        <form onSubmit={(e)=> e.preventDefault()}>
                            <div className='flex form-group'>
                                <label htmlFor="startdate">시작일</label>
                                <div className='flex gap-2 flex-wrap'>
                                    <label htmlFor="startdate startYear" className='hidden'>연도</label>
                                    <select name="startYear" id="startYear" className="customSel"
                                        onChange={handleDropdownChange} onKeyDown={handleDropdownChange} aria-label="시작일 연도 선택" value={formData.startYear}>
                                        <option value="" disabled>연도 선택</option>
                                         {startdatelist.map(year => (
                                            <option key={year} value={year} disabled={isStartYearDisabled(year)}>
                                                {year}
                                            </option>
                                        ))}
                                    </select>

                                    {/* <Dropdown options={startdatelist} label="연도" name="startYear" value={formData.startYear} onChange={handleDropdownChange} resetSelectedOption={resetFormSelectedOption} aria-labelledby="startdate startYear" /> */}
                                    
                                    <label htmlFor="startdate startMonth" className='hidden'>월</label>
                                    <select name="startMonth" id="startMonth" className="customSel"
                                        onChange={handleDropdownChange} aria-label="시작일 월 선택" value={formData.startMonth}>
                                        <option value="" disabled>월 선택</option>
                                        {startdatemonthlist.map(month => (
                                            <option
                                                key={month}
                                                value={month}
                                                disabled={isStartMonthDisabled(formData.startYear, month)}
                                            >
                                                {month}월
                                            </option>
                                        ))}
                                    </select>
                                    {/* <Dropdown options={startdatemonthlist} label="월" name="startMonth" value={formData.startMonth} onChange={handleDropdownChange} resetSelectedOption={resetFormSelectedOption} aria-labelledby="startdate startMonth" /> */}
                                    
                                    <label htmlFor="startdate startDay" className='hidden'>일</label>
                                    <select name="startDay" id="startDay" className="customSel"
                                        onChange={handleDropdownChange} aria-label="시작일 일 선택" value={formData.startDay}>
                                        <option value="" disabled>일 선택</option>
                                        {startdatedaylist.map(day => (
                                            <option
                                                key={day}
                                                value={day}
                                                disabled={isStartDayDisabled(formData.startYear, formData.startMonth, day)}
                                            >
                                                {day}
                                            </option>
                                        ))}
                                    </select>
                                    {/* <Dropdown options={startdatedaylist} label="일" name="startDay" value={formData.startDay} onChange={handleDropdownChange} resetSelectedOption={resetFormSelectedOption} aria-labelledby="startdate startDay" /> */}
                                </div>
                            </div>

                            <div className='flex form-group '>
                                <label htmlFor="enddate">종료일</label>
                                <div className='flex gap-2 flex-wrap'>
                                    <label htmlFor="enddate endYear" className='hidden'>연도</label>
                                    <select name="endYear" id="endYear" className="customSel"
                                        onChange={handleDropdownChange} aria-label="종료일 연도 선택" value={formData.endYear}>
                                        <option value="" disabled>연도 선택</option>
                                        {enddatelist.map(year => (
                                            <option
                                                key={year}
                                                value={year}
                                                disabled={isEndYearDisabled(formData.startYear, formData.startMonth, formData.startDay)}
                                            >
                                                {year}
                                            </option>
                                        ))}
                                    </select>
                                    {/* <Dropdown options={enddatelist} label="연도" name="endYear" value={formData.endYear} onChange={handleDropdownChange} resetSelectedOption={resetFormSelectedOption} aria-labelledby="enddate endYear"/> */}
                                    
                                    <label htmlFor="enddate endMonth" className='hidden'>월</label>
                                    <select name="endMonth" id="endMonth" className="customSel"
                                        onChange={handleDropdownChange} aria-label="종료일 월 선택" value={formData.endMonth}>
                                        <option value="" disabled>월 선택</option>
                                        {enddatemonthlist.map(month => (
                                            <option
                                                key={month}
                                                value={month}
                                                disabled={isEndMonthDisabled(month, formData.startYear, formData.startMonth, formData.startDay, formData.endYear)}
                                            >
                                                {month}월
                                            </option>
                                        ))}
                                    </select>
                                    {/* <Dropdown options={enddatemonthlist} label="월" name="endMonth" value={formData.endMonth} onChange={handleDropdownChange} resetSelectedOption={resetFormSelectedOption} aria-labelledby="enddate endMonth" /> */}
                                    
                                    <label htmlFor="enddate endDay" className='hidden'>일</label>
                                    <select name="endDay" id="endDay" className="customSel"
                                        onChange={handleDropdownChange} aria-label="종료일 일 선택" value={formData.endDay}>
                                        <option value="" disabled>일 선택</option>
                                       {enddatedaylist.map(day => (
                                            <option
                                                key={day}
                                                value={day}
                                                disabled={isEndDayDisabled(day, formData.startYear, formData.startMonth, formData.startDay, formData.endYear, formData.endMonth)}
                                            >
                                                {day}
                                            </option>
                                        ))}
                                    </select>
                                    {/* <Dropdown options={enddatedaylist} label="일" name="endDay" value={formData.endDay} onChange={handleDropdownChange} resetSelectedOption={resetFormSelectedOption} aria-labelledby="enddate endDay"/> */}
                                </div>
                            </div>

                            <div className='flex form-group'>
                                <label htmlFor="weekday">요일</label>
                                <div className="weekday-buttons" role="group" aria-hidden="false" aria-labelledby="weekday">
                                    {renderButtons()}
                                </div>
                            </div>

                            <div className='flex form-group'>
                                <label htmlFor="time">시간 선택</label>
                                <div className='flex gap-2 flex-wrap align-items-center'>
                                    <label htmlFor="time morningAfternoon" className='hidden'>오전/오후</label>
                                    <select name="starttime_ampm" id="starttime_ampm" className="customSel"
                                        onChange={handleTimeChange} aria-label="오전오후 선택" value={starttime.ampm}>
                                        <option value="" disabled>오전/오후</option>
                                        {ampmlist.map((option, index) => (
                                            <option key={index} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </select>
                                    {/* <Dropdown options={ampmlist} label="오전/오후" name="starttime_ampm" value={starttime.ampm} onChange={handleTimeChange} resetSelectedOption={resetSelectedOption} aria-labelledby="time morningAfternoon"  /> */}
                                    
                                    <label htmlFor="time hour" className='hidden'>시간</label>
                                    <select name="starttime_hour" id="starttime_hour" className="customSel"
                                        onChange={handleTimeChange} aria-label="시 선택" value={starttime.hour}>
                                        <option value="" disabled>시 선택</option>
                                        {hourlist.map((option, index) => (
                                            <option key={index} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </select>
                                    {/* <Dropdown options={hourlist} label="시" name="starttime_hour" value={starttime.hour} onChange={handleTimeChange} resetSelectedOption={resetSelectedOption} aria-labelledby="time hour" /> */}
                                    
                                    <label htmlFor="time minute" className='hidden'>분</label>
                                    <select name="starttime_minute" id="starttime_minute" className="customSel"
                                        onChange={handleTimeChange} aria-label="분 선택" value={starttime.minute}>
                                        <option value="" disabled>분 선택</option>
                                        {minutelist.map((option, index) => (
                                            <option key={index} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </select>
                                    {/* <Dropdown options={minutelist} label="분" name="starttime_minute" value={starttime.minute} onChange={handleTimeChange} resetSelectedOption={resetSelectedOption} aria-labelledby="time minute" /> */}

                                    ~ 

                                    <label htmlFor="time morningAfternoon" className='hidden'>오전/오후</label>
                                    <select name="endtime_ampm" id="endtime_ampm" className="customSel"
                                        onChange={handleTimeChange} aria-label="오전오후 선택" value={endtime.ampm}>
                                        <option value="" disabled>오전/오후</option>
                                        {ampmlist.map((option, index) => (
                                            <option key={index} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </select>
                                    {/* <Dropdown options={ampmlist} label="오전/오후" name="endtime_ampm" value={endtime.ampm} onChange={handleTimeChange} resetSelectedOption={resetSelectedOption} aria-labelledby="time morningAfternoon"/> */}
                                    
                                    <label htmlFor="time hour" className='hidden'>시간</label>
                                    <select name="endtime_hour" id="endtime_hour" className="customSel"
                                        onChange={handleTimeChange} aria-label="시 선택" value={endtime.hour}>
                                        <option value="" disabled>시 선택</option>
                                        {hourlist.map((option, index) => (
                                            <option key={index} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </select>
                                    {/* <Dropdown options={hourlist} label="시" name="endtime_hour" value={endtime.hour} onChange={handleTimeChange} resetSelectedOption={resetSelectedOption} aria-labelledby="time hour" /> */}
                                    
                                    <label htmlFor="time minute" className='hidden'>분</label>
                                    <select name="endtime_minute" id="endtime_minute" className="customSel"
                                        onChange={handleTimeChange} aria-label="분 선택" value={endtime.minute}>
                                        <option value="" disabled>분 선택</option>
                                        {minutelist.map((option, index) => (
                                            <option key={index} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </select>
                                    {/* <Dropdown options={minutelist} label="분" name="endtime_minute" value={endtime.minute} onChange={handleTimeChange} resetSelectedOption={resetSelectedOption} aria-labelledby="time minute" /> */}
                                    
                                    <Button
                                        label='일정선택'
                                        onClick={addTimeline}
                                        severity="timeregibtn"
                                        rounded
                                        aria-label="일정선택 버튼"
                                    />
                                    {/* <Button label="취소" onClick={() => resetTimelineData()} severity="timecancbtn" rounded aria-label="취소하기 버튼"/> */}
                                </div> 
                            </div>
                            {/*
                            <div className='flex form-group '>
                                <label></label>
                                <div className='addtime'>
                                    <Button type="button" onClick={() => resetTimelineData()}className="addtimebtn" aria-label="시간 추가하기 버튼"><span>+</span>시간 추가하기</Button>
                                </div>
                            </div>
                            */}

                            <div className='flex form-group'>
                                <label htmlFor='selectedBox' ><span className='hidden'>선택한 시간대</span></label>
                                <div className='selectedBox'>
                                    {formData.selecttimes.map((item, index) => (
                                        <p key={index}>
                                            {item.weekStr} {item.starttime} ~ {item.endtime} 
                                            <button type='button' onClick={() => deleteTimeline(index)} className='xicon' aria-label={`${item.weekStr} ${item.starttime} ~ ${item.endtime} 삭제하기 버튼`}>X</button>
                                        </p>    
                                    ))}
                                </div>
                            </div>

                            <div className='flex form-group'>
                                <label htmlFor="">시간 간격</label>
                                <div className="flex flex-wrap gap-3">
                                    <div className="flex align-items-center">
                                        <input
                                            ref={radioRef30}
                                            type="radio"
                                            id="30minutes"
                                            name="duration"
                                            value="30"
                                            onChange={handleChange}
                                            onKeyDown={handleKeyDown}
                                            checked={formData.duration === '30'}
                                            aria-labelledby="30분-label"
                                        />
                                        <label htmlFor="30minutes" className="ml-2" id="30분-label">30분</label>
                                    </div>
                                    <div className="flex align-items-center">
                                        <input
                                            ref={radioRef60}
                                            type="radio"
                                            id="60minutes"
                                            name="duration"
                                            value="60"
                                            onChange={handleChange}
                                            onKeyDown={handleKeyDown}
                                            checked={formData.duration === '60'}
                                            aria-labelledby="60분-label"
                                        />
                                        <label htmlFor="60minutes" className="ml-2" id="60분-label">60분</label>
                                    </div>
                                </div>
  
                            </div>

                            <div className='flex form-group'>
                                <label htmlFor="">공휴일</label>
                                <div className="flex flex-wrap gap-3">
                                    <div className="flex align-items-center">
                                        <input
                                            ref={classopenRef}
                                            type="radio"
                                            id="teach"
                                            name="holidayclass"
                                            value="open"
                                            onChange={handleChange}
                                            onKeyDown={handleKeyDown}
                                            checked={formData.holidayclass == 'open'}
                                            aria-labelledby="teach-label"
                                        />
                                        <label htmlFor="teach" className="ml-2" id="teach-label">수업함</label>
                                    </div>
                                    <div className="flex align-items-center">
                                        <input
                                            ref={classcloseRef}
                                            type="radio"
                                            id="noteach"
                                            name="holidayclass"
                                            value="close"
                                            onChange={handleChange}
                                            onKeyDown={handleKeyDown}
                                            checked={formData.holidayclass == 'close'}
                                            aria-labelledby="noteach-label"
                                        />
                                        <label htmlFor="noteach" className="ml-2" id="noteach-label">수업안함</label>
                                    </div>
                                </div>
                            </div>
                        </form>
                            
                        <div className='flex form-group'>
                            <label htmlFor=""></label>
                            <div className="bottombtns">
                                <Button type="button" onClick={previewClass} className='p-button p-component p-button-previewbtn mr-3 justify-content-center'aria-label="미리보기 버튼">미리보기</Button>
                                <Button type="button" onClick={cancelClass} label="취소" severity="cancbtn" aria-label="취소하기 버튼"/>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
            <Toast ref={toast} role="alert"/>

            {/* alert */}
            <ConfirmDialog draggable={false} breakpoints={{ '1100px': '75vw', '960px': '60vw', '430px': '80vw' }} className='bookDia' />
            
        </div>
    );
}

export default ClassScheduleRegi;