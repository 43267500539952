/* site/header.jsx */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'primereact/button';
import { Sidebar } from 'primereact/sidebar';
import logo from '../../assets/img/logo.png'; // 상단로고 이미지
import { Link } from 'react-router-dom';
import { Accordion, AccordionTab } from 'primereact/accordion';

import { logout } from '../../store/actions/authActions';

import axios from 'axios';

import config from '../../config';
const apiUrl = config.apiUrl;

function Header() {

	const isAuthenticated = useSelector(state => state.auth.isAuthenticated);  

	const [visibleTop, setVisibleTop] = useState(false);
	const [isServiceMenuHovered, setServiceMenuHovered] = useState(false);
	const [searchtxt, setSearchtxt] = useState('');
	const [serviceData, setServiceData] = useState([]);
	
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const handleInputChange = (event) => { //검색어 입력
		setSearchtxt(event.target.value);
	};

	const handleKeyPress = (event) => {
		if (event.key === 'Enter') {
		// 엔터키 눌렀을 때
		navigate('/search_results?keyword='+searchtxt);
		}
	};

	// 콘텐츠 리스트 가져오기
	const getContents = async () => {

		try {
			const response = await axios.get(apiUrl+'/service?sort=seq,desc');
			var res = response.data;
			//console.log(res);
			var dataArr = [];
			if(res.success){
				var resData = response.data.data;
				//console.log(resData);
				if(resData){
				dataArr = resData;
				}
			}

			setServiceData(dataArr);
		
		} catch (error) {
		console.error('Error:', error);
		}
	};
	
	useEffect(() => {
		getContents();
	}, []);

	// 로그아웃
	const handleLogout = () => {
		dispatch(logout());
		window.location.href = "/";
	};

	useEffect(() => {
		if (visibleTop) {
			document.body.style.overflowY = 'hidden';
		} else {
			document.body.style.overflowY = 'auto';
		}

		// clean up function
		return () => {
			document.body.style.overflowY = 'auto';
		};
	}, [visibleTop]);

	return (
		<div>
			<header>

				{/* PC */}
				<div className="utillWrap pc">
					<div className="container">
						<div className="leftarea">
							<Link to="/" className="logo">
								<img src={logo} alt="하티웍스로고" />
							</Link>
							<div className="searchBox">
								<input type="text" placeholder="검색어를 입력해주세요" aria-label='검색어 입력' onChange={handleInputChange} onKeyPress={handleKeyPress} />
								<Link to={`/search_results?keyword=${searchtxt}`} aria-label={searchtxt ? `${searchtxt}로 이동` : '검색 결과로 이동'}>
									<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
									<circle cx="8.73228" cy="8.23224" r="5.5" transform="rotate(-45 8.73228 8.23224)" stroke="#111111"
										strokeWidth="1.5" />
									<line x1="13.3285" y1="12.4749" x2="15.8034" y2="14.9498" stroke="#111111" strokeWidth="1.5"
										strokeLinecap="round" />
									</svg>
								</Link>
							</div>
						</div>

						<ul className="utill">
							<li className="movelink" onMouseEnter={() => setServiceMenuHovered(true)} onMouseLeave={() => setServiceMenuHovered(false)}>
								<Link href="#">
									서비스
									<svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M12.6569 1.65685L7 7.31371L1.34315 1.65685" stroke="#555555" strokeWidth="2" />
									</svg>
								</Link>

								{/* 서비스 메뉴 hover시 나오는 서브메뉴들 */}
								<div className={isServiceMenuHovered ? 'service_subBox active' : 'service_subBox'}>
									<div className='container'>
										<div className='subBox'>
											<ul>
												{serviceData.map((item, index) => (
													<li key={index}>
														<Link to={`/search_resultsDetail/${item.serviceid}`} aria-label={item.title}>{item.title}</Link>
													</li>
												))}
											</ul>
										</div>
									</div>
								</div>
							</li>
							<li className="movelink">
								<Link to="/teacher/dashboard">
									튜터
									<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M7.00002 7.00002L12.6569 1.34315" stroke="#555555" strokeWidth="1.5" strokeLinecap="round" />
									<path d="M9 1L13 1L13 5" stroke="#555555" strokeWidth="1.5" strokeLinecap="round"
										strokeLinejoin="round" />
									<path d="M13 9.60714V13H1V1H4.39286" stroke="#555555" strokeWidth="1.5" strokeLinecap="round"
										strokeLinejoin="round" />
									</svg>
								</Link>
							</li>
							<li className="movelink">
								<Link to="/learner/dashboard">
									기업교육
									<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M7.00002 7.00002L12.6569 1.34315" stroke="#555555" strokeWidth="1.5" strokeLinecap="round" />
									<path d="M9 1L13 1L13 5" stroke="#555555" strokeWidth="1.5" strokeLinecap="round"
										strokeLinejoin="round" />
									<path d="M13 9.60714V13H1V1H4.39286" stroke="#555555" strokeWidth="1.5" strokeLinecap="round"
										strokeLinejoin="round" />
									</svg>
								</Link>
							</li>
							{ !isAuthenticated ?
								<li className="login_btn">
									<Link to="/login">로그인</Link>
								</li>
								:
								<li className="logout_btn">
									<Button className='logout' onClick={handleLogout} label="로그아웃"/>  
								</li>
							}
							{ !isAuthenticated ?
								<li>
									<Link to="/join">회원가입</Link>
								</li>
								:
								<li></li>
							}
						
						</ul>
					</div>
				</div>

				{/* Mob */}
				<div className="utillWrap mob">
					<div className="container">
						<a className="logo" href="/">
							<img src={logo} alt="하티웍스로고" />
						</a>
						<Button icon="pi pi-align-justify" onClick={()=> setVisibleTop(true)} className='hambtn' aria-label={'메뉴바 버튼'}/>
					</div>
				</div>
			</header>
			
			{/* mob - 메뉴박스 */}
			<Sidebar visible={visibleTop} position="top" onHide={()=> setVisibleTop(false)} className='mobmenuBox'>
				<div className="searchBox">
					<input type="text" placeholder="검색어를 입력해주세요" aria-label='검색어 입력' onChange={handleInputChange} onKeyPress={handleKeyPress} />
					<Link to={`/search_results?keyword=${searchtxt}`} aria-label={searchtxt ? `${searchtxt}로 이동` : '검색 결과로 이동'} className='searchIcon'>
						<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<circle cx="8.73228" cy="8.23224" r="5.5" transform="rotate(-45 8.73228 8.23224)" stroke="#111111"
							strokeWidth="1.5" />
							<line x1="13.3285" y1="12.4749" x2="15.8034" y2="14.9498" stroke="#111111" strokeWidth="1.5"
							strokeLinecap="round" />
						</svg>
					</Link>
				</div>
				<ul className='menulist'>
					<li className="movelink">
						{/* 서비스 메뉴 클릭시 나오는 서브메뉴들 */}
						<Accordion multiple className='submenu'>
							<AccordionTab header="서비스">
								<ul>
									{serviceData.map((item, index) => (
										<li key={index} onClick={() => setVisibleTop(false)}>
											<Link to={`/search_resultsDetail/${item.serviceid}`} aria-label={item.title}>{item.title}</Link>
										</li>
									))}
								</ul>
							</AccordionTab>
						</Accordion>	
					</li>
					<li className="movelink">
						<Link to="/teacher/dashboard">
							튜터
							<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M7.00002 7.00002L12.6569 1.34315" stroke="#555555" strokeWidth="1.5" strokeLinecap="round" />
								<path d="M9 1L13 1L13 5" stroke="#555555" strokeWidth="1.5" strokeLinecap="round"
									strokeLinejoin="round" />
								<path d="M13 9.60714V13H1V1H4.39286" stroke="#555555" strokeWidth="1.5" strokeLinecap="round"
									strokeLinejoin="round" />
							</svg>
						</Link>
					</li>
					<li className="movelink">
						<Link to="/learner/dashboard">
							기업교육
							<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M7.00002 7.00002L12.6569 1.34315" stroke="#555555" strokeWidth="1.5" strokeLinecap="round" />
								<path d="M9 1L13 1L13 5" stroke="#555555" strokeWidth="1.5" strokeLinecap="round"
									strokeLinejoin="round" />
								<path d="M13 9.60714V13H1V1H4.39286" stroke="#555555" strokeWidth="1.5" strokeLinecap="round"
									strokeLinejoin="round" />
							</svg>
						</Link>
					</li>
					{ !isAuthenticated ?
						<li>
							<Link to="/login">로그인</Link>
						</li>
						:
						<li>
							<Button className='logout' onClick={handleLogout} label="로그아웃"/>  
						</li>
					}
					{ !isAuthenticated ?
						<li>
							<Link to="/join">회원가입</Link>
						</li>
						:
						<li></li>
					}
				</ul>
			</Sidebar>
		</div>

    );
}

export default Header;