/* verify.jsx */
import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import '../../assets/css/account.css';

import axios from 'axios';

import config from '../../config';
const apiUrl = config.apiUrl;

// 타이틀
export function useTitle(initialTitle) {
    const [title, setTitle] = useState(initialTitle);
    const updateTitle = () => {
        const htmlTitle = document.querySelector("title");
        htmlTitle.innerText = title;
    };

    useEffect(updateTitle, [title]);
    return setTitle;
};

function Verify() {
    
    useTitle("본인인증 완료");

    const { email } = useParams(); // URL 매개변수에서 ID 가져오기

    useEffect(() => {

        const verifyEmail = async () => {

            try {
                const response = await axios.post(apiUrl+'/auth/verifyEmail/'+email, {});
                var res = response.data;
                //console.log(res);
                if(res.success){
                    const timer = setTimeout(() => {
                        window.close();
                    }, 3000);
            
                    // 컴포넌트 언마운트 시 타이머 정리
                    return () => clearTimeout(timer);
                }
            
            } catch (error) {
               console.error('Error:', error);
            }
        };

        verifyEmail();
        
    }, []);


    return (
        <div className="accountBox join">
            <div className="card mb-0 h-auto">
                <h4 className="text-center mb-0">이메일 인증 완료되었습니다.<br/> 기존 페이지에서 회원가입을 진행해주세요.</h4>
            </div>
        </div>
    );
}

export default Verify;
