import React from 'react';
import { Dialog } from 'primereact/dialog';
import { Link } from 'react-router-dom';

const SurveyDialog = ({ visible, onHide, researchid }) => {
    return (
        <Dialog
            draggable={false}
            role="dialog"
            showHeader={false}
            visible={visible}
            onHide={onHide}
            style={{ width: '30vw', borderRadius: "20px", overflow: "hidden", textAlign: "center" }}
            breakpoints={{ '960px': '75vw', '641px': '90vw' }}
            focusOnShow={true} // 다이얼로그가 열렸을 때 포커스가 이동하도록 함
            modal={true} // 모달 다이얼로그로 설정하여 다이얼로그 외부를 클릭했을 때 닫히지 않도록 함
            aria-modal="true" // 스크린 리더가 모달 상태를 인식할 수 있도록 함
            aria-labelledby="수업 만족도 설문 안내" // 다이얼로그 제목을 명시함
        >   
            <div role="alert">
                 <div>
                    <h4>수업 만족도 설문 안내</h4>
                    수업이 모두 끝났어요😊<br/>
                    선생님과의 수업을 통해 한 단계 더 성장한 당신을 응원합니다🏅<br/><br/>
                    1분 안에 만족도 설문 제출하고 나의 최종 출석 현황을 확인해보세요! <br/>
                </div>
                <p style={{ color: 'red' }}>※ 만족도 설문을 제출하지 않는 경우, 최종 출석 현황이 처리 되지 않아 수료증 발급 및 재수강이 불가합니다. </p>                
            </div>

            <div className='text-center'>
                <Link to={"/survey/"+researchid}
                    role='button'
                    tabIndex="0"
                    className='p-button p-component p-button-danger'
                    aria-label={'만족도 설문 제출하기 버튼'}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                           //제출하기 enter
                        }
                    }}
                >만족도 설문 제출하기</Link>
            </div>            
        </Dialog>
    );
};

export default SurveyDialog;
