import React from 'react';
import { Dialog } from 'primereact/dialog';

const NoticeDialog = ({ visible, onHide, title, contents }) => {
    return (
        <Dialog
            draggable={false}
            role="dialog"
            header="공지사항"
            visible={visible}
            onHide={onHide}
            style={{ width: '40vw' }}
            breakpoints={{ '960px': '75vw', '641px': '90vw' }}
            focusOnShow={true} // 다이얼로그가 열렸을 때 포커스가 이동하도록 함
            modal={true} // 모달 다이얼로그로 설정하여 다이얼로그 외부를 클릭했을 때 닫히지 않도록 함
            aria-modal="true" // 스크린 리더가 모달 상태를 인식할 수 있도록 함
            aria-labelledby="dialog-title" // 다이얼로그 제목을 명시함
        >
            <div role="alert">
                <h4 id="dialog-title">{title}</h4>
                <p dangerouslySetInnerHTML={{ __html: contents }} className='editoerBox'></p>
            </div>
          
        </Dialog>
    );
};

export default NoticeDialog;
