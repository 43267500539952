import React, { forwardRef } from 'react';
import '../../assets/css/confirmationContent.css';

const ConfirmationContent = forwardRef(({ data }, ref) => {
    return (
        <div ref={ref} tabIndex={-1} aria-hidden="true" className='ConfiBg'>
            <div className='logo'></div>
            <h2>수 강 확 인 서</h2>
            <div className='infotableBox'>
                <table>
                    <tbody>
                        <tr>
                            <th>성명</th>
                            <td>{data.tuteename}</td>
                        </tr>
                        <tr>
                            <th>교육 과정</th>
                            <td>{data.lecturetype}</td>
                        </tr>
                        <tr>
                            <th>교육 기간</th>
                            <td>{data.startdate} ~ {data.enddate}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className='tit text1'>위와 같이 수강을 확인해 드립니다.</div>
            <div className='tit text2'>{data.today}</div>
            <div className='tit text3'>주식회사 하티웍스</div>
        </div>
    );
});

export default ConfirmationContent;
