import React, { useState, useEffect } from 'react';
import { Chart } from 'primereact/chart';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const PieChart = ( { data }) => {
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});

    useEffect(() => {
        if (data) {
            if (data) {
                const { xValues, yValues } = data;
    
                const chartData = {
                    labels: xValues,
                    datasets: [
                        {
                            data: yValues,
                            backgroundColor: getBackgroundColor(xValues.length),
                            hoverBackgroundColor: getHoverBackgroundColor(xValues.length),
                        }
                    ]
                };
    
                const chartOptions = {
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            labels: {
                                usePointStyle: true
                            }
                        },
                        datalabels: {
                            color: '#fff',
                            anchor: 'center',
                            align: 'center',
                            font: {
                                size: 16,
                                weight: 'bold'
                            },
                            formatter: (value, context) => {
                                return value + '%';
                            }
                        }
                    }
                };
    
                setChartData(chartData);
                setChartOptions(chartOptions);
            }
        }
    }, [data]);

    const getBackgroundColor = (length) => {
        // Define your color logic based on the number of options
        switch (length) {
            case 2:
                return ['#007BFF', '#FFC107'];
            case 3:
                return ['#007BFF', '#FFC107', '#28A745'];
            case 4:
                return ['#007BFF', '#FFC107', '#28A745', '#DC3545'];
            case 5:
                return ['#007BFF', '#FFC107', '#28A745', '#DC3545', '#6610f2'];
            case 6:
                return ['#007BFF', '#FFC107', '#28A745', '#DC3545', '#6610f2', '#6f42c1'];
            default:
                return ['#007BFF', '#FFC107', '#28A745', '#DC3545', '#6610f2', '#6f42c1'];
        }
    };

    const getHoverBackgroundColor = (length) => {
        // Define your border color logic based on the number of options
        switch (length) {
            case 2:
                return ['#0056b3', '#FFA000'];
            case 3:
                return ['#0056b3', '#FFA000', '#218838'];
            case 4:
                return ['#0056b3', '#FFA000', '#218838', '#C82333'];
            case 5:
                return ['#0056b3', '#FFA000', '#218838', '#C82333', '#4e0d75'];
            case 6:
                return ['#0056b3', '#FFA000', '#218838', '#C82333', '#4e0d75', '#5a189a'];
            default:
                return ['#0056b3', '#FFA000', '#218838', '#C82333', '#4e0d75', '#5a189a'];
        }
    };        

    return (
        <Chart type="pie" data={chartData} options={chartOptions} plugins={[ChartDataLabels]} className="w-full" />
    );
};

export default PieChart;
