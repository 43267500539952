/* /site/index.jsx */
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Carousel } from 'primereact/carousel';
import { Button } from 'primereact/button';
import sampleimg from '../../assets/img/thumb_sample.png'; // 썸네일샘플 이미지
import { Link } from 'react-router-dom';
import Layout from '../../components/site/layout';

import axios from 'axios';

import config from '../../config';
const apiUrl = config.apiUrl;

function Main() {

    const navigate = useNavigate();

    // 상단 배너 
    const [currentPage, setCurrentPage] = useState(0); // 현재 페이지를 상태로 관리
    const [autoplayEnabled, setAutoplayEnabled] = useState(true);// 자동 재생 활성화 상태

    const [bannerData, setBannerData] = useState([]);
    const [serviceData, setServiceData] = useState([]);
    const [recoPage, setRecoPage] = useState(0); // 추천 서비스 페이지
    const [logoData, setLogoData] = useState([]);

    // 배너 리스트 가져오기
    const getBanners = async () => {

        try {
            const response = await axios.get(apiUrl+'/banner?sort=orderno,desc&disable=show');
            var res = response.data;
            //console.log(res);
            var dataArr = [];
            if(res.success){
                var resData = response.data.data;
                //console.log(resData);
                if(resData){
                    for(var i = 0; i < resData.length; i++){
                        var info = resData[i];
                        info.source = info.fileurl;
                        info.alt = info.title;
                        dataArr.push(info);
                    }
                }
            }

            setBannerData(dataArr);
        
        } catch (error) {
           console.error('Error:', error);
        }
    };

    // 콘텐츠 리스트 가져오기
    const getContents = async () => {

        try {
            const response = await axios.get(apiUrl+'/service?sort=seq,desc&disable=show');
            var res = response.data;
            //console.log(res);
            var dataArr = [];
            if(res.success){
                var resData = response.data.data;
                //console.log(resData);
                if(resData){
                    for(var i = 0; i < resData.length; i++){
                        var info = resData[i];
                        info.source = info.fileurl;
                        info.alt = info.title;
                        dataArr.push(info);
                    }
                }
            }

            setServiceData(dataArr);
        
        } catch (error) {
           console.error('Error:', error);
        }
    };

    // 로고 리스트 가져오기
    const getLogos = async () => {

        try {
            const response = await axios.get(apiUrl+'/logo?sort=orderno,asc&disable=show');
            var res = response.data;
            //console.log(res);
            var dataArr = [];
            if(res.success){
                var resData = response.data.data;
                //console.log(resData);
                if(resData){
                    for(var i = 0; i < resData.length; i++){
                        var info = resData[i];
                        info.source = info.fileurl;
                        info.alt = info.title;
                        dataArr.push(info);
                    }
                }
            }

            setLogoData(dataArr);
        
        } catch (error) {
           console.error('Error:', error);
        }
    };

    useEffect(() => {
        
        getBanners();
        getContents();
        getLogos();
        
    }, []);


    const itemTemplate = (item) => {
        return (
            <div className="carousel-item" onClick={() => moveToUrl(item.linkurl)} role="button" tabIndex="0">
                <img src={item.source} alt=""  aria-label={item.alt}/>
            </div>
        );
    };

    const moveToUrl = (linkurl) => {
        window.open(linkurl, '_blank', 'noopener,noreferrer');
    };

    const goToNextPage = () => {//다음 슬라이드 이동
        setCurrentPage(currentPage + 1);
        setAutoplayEnabled(true); // 자동재생 다시 활성화
    };

    const goToPreviousPage = () => {// 이전 슬라이드 이동
        setCurrentPage(currentPage - 1);
        setAutoplayEnabled(true); // 자동재생 다시 활성화
    };

    const toggleAutoplay = () => {
        setAutoplayEnabled(!autoplayEnabled);
    };

    useEffect(() => {
        // 자동 재생이 활성화되어 있을 때 페이지를 변경합니다.
        const interval = setInterval(() => {
            if (autoplayEnabled) {
                setCurrentPage((prevPage) => (prevPage + 1) % bannerData.length);
            }
        }, 5000);

        return () => clearInterval(interval);
    }, [autoplayEnabled, bannerData.length]);


    const carouselRef = useRef(null);
    useEffect(() => { // 이전, 다음 버튼에 aria-label 변경
            const carouselElement = carouselRef.current;
            if (carouselElement) {
                const prevButton = carouselElement.querySelector('.p-carousel-prev');
                const nextButton = carouselElement.querySelector('.p-carousel-next');

                if (prevButton) {
                    prevButton.setAttribute('aria-label', '이전 슬라이드 보기');
                    
                }
                if (nextButton) {
                    nextButton.setAttribute('aria-label', '다음 슬라이드 보기');
                    
                }
            }
        }, [recoPage]);




    const recomendTemplate = (item) => {
        return (
            <div className="carousel-item">
                <Link to={`/search_resultsDetail/${item.serviceid}`} aria-label={item.title}>{/* 썸네일이름 바인딩 필요 */}
                    <div className="thumnail" >
                        <img src={item.source} alt="" aria-label={item.alt}/>
                    </div>
                    <div className="listconts">
                        <p className="tit">{item.title}</p>
                        <p className="txt">{item.description}</p>
                    </div>
                </Link>
            </div>
        );
    };
    

    const logoTemplate = (item) => {
        return (
            <div className="carousel-item" >
                <img src={item.source} alt=""  />
            </div>
        );
    };
    

    /* 반응형 */
     const logo_responsive = [
        { breakpoint: '1400px', numVisible: 4, numScroll: 1 },
        { breakpoint: '767px', numVisible: 3, numScroll: 1 },
        { breakpoint: '575px', numVisible: 2, numScroll: 1 }
    ];

    return (
        <div>
            <Layout>
            
                {/* 컨텐츠영역 */}
                <div className="content">

                    {/* 배너 */}
                    <div className="banner">
                        <Carousel
                            value={bannerData}
                            itemTemplate={itemTemplate}
                            numVisible={1}
                            numScroll={1}
                            showIndicators={false}
                            showNavigators={false}
                            page={currentPage}
                            circular
                            aria-live="off" 
                        />
                        <div className="custom-pagination container">
                            <div className='paginationBox'>
                                <Button icon="pi pi-chevron-left" onClick={goToPreviousPage} className="pagingbtn"
                                    disabled={currentPage===0} aria-label="이전 슬라이드 보기" />
                                <Button icon="pi pi-chevron-right" onClick={goToNextPage} className="pagingbtn"
                                    disabled={currentPage===bannerData.length - 1} aria-label="다음 슬라이드 보기" />
                                <div className='countBox'>
                                    <span className="page-number" >{`${currentPage + 1} \u00A0/\u00A0
                                    ${bannerData.length}`}</span>
                                    <Button icon={`pi ${autoplayEnabled ? 'pi-pause' : 'pi-play' }`} onClick={toggleAutoplay}
                                    className="moveicon play" aria-label={`${autoplayEnabled ? '일시 정지' : '재생' }`} />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* 리스트 */}
                    <section className="recomend">
                        <h2 className="listtitle" aria-label="임직원 성장을 위한 추천 서비스">임직원 성장을 위한 추천 서비스</h2>

                        <div className="container">
                            <div className="listBox" ref={carouselRef}>
                                <Carousel
                                        aria-live="off" 
                                        value={serviceData}
                                        itemTemplate={recomendTemplate}
                                        numVisible={4}
                                        numScroll={1}
                                        showIndicators={false}
                                        showNavigators={true}
                                        responsiveOptions={[
                                            {
                                                breakpoint: '1024px',
                                                numVisible: 3,
                                                numScroll: 1
                                            },
                                            {
                                                breakpoint: '768px',
                                                numVisible: 2,
                                                numScroll: 1
                                            },
                                            {
                                                breakpoint: '560px',
                                                numVisible: 1,
                                                numScroll: 1
                                            }
                                        ]}
                                        page={recoPage}
                                        circular={false}
                                        autoplayInterval={null}
                                    />
                            
                            </div>
                        </div>
                    </section>

                    {/* 고객사 */}
                    <section className="customerBox">
                        <h2 className="listtitle" aria-label="임직원 성장을 위한 추천 서비스">하티웍스와 함께 성장하는 고객사</h2>
                        {/* <div className="customerListBox container">
                            <Carousel
                                aria-live="off" 
                                value={logoData}
                                itemTemplate={logoTemplate}
                                numVisible={4}
                                numScroll={1}
                                showNavigators={false}
                                responsiveOptions={logo_responsive}
                                showIndicators={false}
                                autoplayInterval={2000} // 자동롤링
                                />
                           
                        </div> */}
                       <div className="logo-scroller">
                            <div className="logo-track">
                                {logoData.map((logo, index) => (
                                    <img key={index} src={logo.source} alt={logo.alt} className="logo" />
                                ))}
                                {logoData.map((logo, index) => (
                                    <img key={index + logoData.length} src={logo.source} alt={logo.alt} className="logo" />
                                ))}
                            </div>
                        </div>
                    </section>
                </div>

            </Layout>

        </div>
    );
}

export default Main;