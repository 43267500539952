import React, { useState, useEffect } from 'react';
import { Chart } from 'primereact/chart'; // chart.js

export default function PieChartDemo({ attendance, rates }) {
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});

    useEffect(() => {
        const data = {
            labels: ['미진행', '출석'],
            datasets: [
                {
                    data: [attendance.wait_count, attendance.join_count],
                    backgroundColor: [
                        '#eee', 
                        '#E8374A' 
                    ],
                    hoverBackgroundColor: [
                        '#eee', 
                        '#E8374A' 
                    ]
                }
            ]
        };
        const options = {
            plugins: {
                tooltip: {
                    callbacks: {
                        label: function(context) {
                            let label = context.label || '';
                            if (label) {
                                label += ': ';
                            }
                            label += context.parsed + '회'; // 데이터 값 뒤에 '회'를 붙여서 표시합니다.
                            return label;
                        }
                    }
                },
                
            }
        };

        setChartData(data);
        setChartOptions(options);
    }, [attendance]);

    return (
        <div className='leftSideBox'>
            <div>
                <h3 className='mb-5'>출석 현황</h3>
                <div className='flex chartBox'>
                    <Chart type="pie" data={chartData} options={chartOptions}  style={{ height: '200px ', }} />
                    <div className='righttxt'>
                        <h3>나의 출석률</h3>
                        <big>{rates.attendance_rate}%</big>
                        <small className='block'>총 {attendance.affiliation_availableno}회</small>
                    </div>
                </div>
                <div className='average'>
                    <div>소속 그룹 평균 출석률 <span>{rates.affiliation_attendance_rate}%</span></div>
                </div>
            </div>
        </div>
    );
}
