/* /lms/learner/bookList.jsx */
import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { BreadCrumb } from 'primereact/breadcrumb';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { SelectButton } from 'primereact/selectbutton';

import sampleimg from '../../../assets/img/thumb_sample.png'; // 썸네일샘플 이미지
import Layout from '../../../components/lms/layout/layout';

import axios from 'axios';

import config from '../../../config';
const apiUrl = config.apiUrl;

var lecturetypes = [];
var dataList = [];

// 타이틀
export function useTitle(initialTitle) {
    const [title, setTitle] = useState(initialTitle);
    const updateTitle = () => {
        const htmlTitle = document.querySelector("title");
        htmlTitle.innerText = title;
    };

    useEffect(updateTitle, [title]);
    return setTitle;
};


const BookList = () => {

    useTitle("교재");

    //브러드크럼
    const items = [ { label: '교재', className: 'nowpage' }, ];
    const home = { icon: 'pi pi-home', url: '/learner/dashboard' }

    const accessToken = useSelector(state => state.auth.accessToken);
    const [tableData, setTableData] = useState([...dataList]);

    const [formData, setFormData] = useState({
        lectureid: '',
        searchtext: ''
    });

    const [lecturetypeData, setLecturetypeData] = useState([...lecturetypes])

    useEffect(() => {

        // 강의유형 리스트 가져오기
        const getLectureTypes = async () => {
            try {
                const response = await axios.get(apiUrl+'/lecturetype', {
                    headers: {
                        token: accessToken
                    }
                });
                //console.log(response.data.data);
                var resData = response.data.data;
                if(resData){
                    lecturetypes.push({label: "전체", value: "", seq: 0});
                    for(var i = 0; i < resData.length; i++){
                        var info = resData[i];
                        info.label = info.lecturetype;
                        info.value = info.lectureid;
                        lecturetypes.push(info);
                    }

                    setLecturetypeData(lecturetypes);
                }
            
            } catch (error) {
               console.error('Error:', error);
            }
        };

        getLectureTypes();

        getCurriculums();
        
    }, []);

    // 검색어 입력 시
    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormData({
            ...formData,
            [name]: value
        });
    };

    // 검색 기능
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            submitSearch();
          }
    };

    // 검색
    function submitSearch(){
        getCurriculums();
    };

    // 교재 리스트 가져오기
    const getCurriculums = async () => {
        var searchtext = formData.searchtext;

        try {
            const response = await axios.get(apiUrl+'/curriculum?searchtext='+searchtext, {
                headers: {
                    token: accessToken
                }
            });
            var res = response.data;
            //console.log(res);
            var dataArr = [];
            if(res.success){
                var resData = response.data.data;
                //console.log(resData);
                if(resData){
                    for(var i = 0; i < resData.length; i++){
                        var info = resData[i];
                        
                        dataArr.push(info);
                    }
                }

                //setTotalCnt(res.total);
            }else{
                //setTotalCnt(0);
            }
            dataList = dataArr;
            
            setTableData(dataList);
        
        } catch (error) {
           console.error('Error:', error);
        }
    };

    // 총 교재 항목 수
    const totalItemCount = 20; // 예시로
    // 더 보기 클릭시 추가되는 항목 수
    const itemsToAdd = 5;
    // 현재까지 표시된 교재 항목 수와 더보기 버튼 클릭 시 추가될 항목 수
    const [itemCount, setItemCount] = useState(itemsToAdd);

    // 교재리스트 
    const handleShowMore = () => {// 더보기 클릭시
       setItemCount(prevCount => prevCount + itemsToAdd);
    };

    // 더 보기 버튼의 텍스트
    const showMoreButtonText = "더보기";
  

    return (
        <div>
            <Layout title="교재" pagename="교재">
                <div className="contain">

                    <BreadCrumb model={items} home={home} />

                    <div className='fullBox'>
                        <h3 className="hidden">교재 리스트</h3>
                        <div className='filterBox'>
                            {/* 
                            <div>
                                <label htmlFor="dating" className=" block mb-2">교재 타입</label>
                                <SelectButton value={formData.lectureid} name="lectureid" onChange={handleChange} options={lecturetypeData} aria-label="교재 타입 선택"/>
                            </div>
                            */}
                            <div>
                                <label htmlFor="search" className="block mb-2">검색</label>
                                <InputText id="search" 
                                    placeholder="검색어를 입력해주세요" 
                                    aria-label='검색어 입력' 
                                    name="searchtext" 
                                    value={formData.searchtext} 
                                    onChange={handleChange} 
                                    onKeyPress={handleKeyPress} 
                                    className="border"/>
                                <Button label="검색" onClick={submitSearch} className="searchbtn ml-2" aria-label={`${formData.searchtext}검색하기`}/>
                            </div>
                        </div>
                        <div className="booklistWrap" aria-label="교재 목록 리스트">

                            {tableData.map((item, index) => (
                                <div key={index} className="booklist-item">
                                    <Link to={`/bookListDetail/${item.curriculumid}`} aria-label="교재 상세 정보">
                                        <div className="thumnail">
                                          <p className="tit">{item.title}</p>
                                        </div>
                                    </Link>
                                </div>
                            ))}
                        
                        </div>

                        {/* 더보기 버튼 */}
                        {/* {itemCount < totalItemCount && ( <div className="show-more-btn">
                            <Button label={showMoreButtonText} className="nobtn" icon="pi pi-chevron-down"
                                iconPos="right" onClick={handleShowMore} aria-label='교재 더보기 버튼' />
                            </div>
                        )} */}
                        
                    </div>
                </div>
            </Layout>


        </div>
    );
    
};
export default BookList;